import React, { FC, ReactNode } from 'react';
import { alpha, Box, Avatar, Stack } from '@mui/material';
import { styled, SxProps } from '@mui/system';

const Container = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  gap: '20px',
  '@media (max-width: 900px)': { flexWrap: 'wrap' }
})

const Letter = styled(Avatar)({
  borderRadius: '8px', 
  color: '#586554', 
  fontFamily: 'Lato', 
  fontWeight: '700'
})

type IOrderedListItemProps = | {
  order: ReactNode;
  children: ReactNode;
  styles?: SxProps;
  orderContainerStyles?: SxProps;
  orderStyles?: SxProps;
  orderSize?: 'medium' | 'large';
  withBg: true;
  bgColor: string;
  isNum?: boolean;
} | {
  order: ReactNode;
  children: ReactNode;
  styles?: SxProps;
  orderContainerStyles?: SxProps;
  orderStyles?: SxProps;
  orderSize?: 'medium' | 'large';
  withBg?: false;
  isNum?: boolean;
}

export const OrderedListItem: FC<IOrderedListItemProps > = (props) => (
  <Container sx={[
    { fontSize: { xs: '16px', md: '22px' }, fontWeight: '400', fontFamily: 'Lato', color: '#343C32', '& p': { m: 0 } },
    props.withBg && { borderRadius: 1, p: 2.5, bgcolor: alpha(props.bgColor, 0.2) }, 
    props.isNum && { borderRadius: 2, p: '12px 20px', bgcolor: '#FFF' }, 
    ...(Array.isArray(props.styles) ? props.styles : [props.styles]),
  ]}>
    <Stack 
      direction="row" 
      sx={[
        { flexBasis: { xs: '100%', md: 0 } },
        ...(Array.isArray(props.orderContainerStyles) ? props.orderContainerStyles : [props.orderContainerStyles]),
      ]}
    >
      <Letter sx={[
        { 
          bgcolor: props.withBg ? alpha(props.bgColor, 0.4) : (!props.isNum ? '#D6DCD5' : '#F2F4F1'), 
          width: !props.isNum ? { xs: '42px', md: '62px' } : '40px', 
          height: !props.isNum ? { xs: '42px', md: '62px' } : '40px', 
          fontSize: { xs: '20px', md: !props.isNum ? '36px' : '26px' } 
        },
        !!props.orderSize && props.orderSize === 'large' && {
          width: { xs: '52px' }, 
          height: { xs: '52px' }, 
          fontSize: { xs: '20px', md: '22px' } 
        },
        ...(Array.isArray(props.orderStyles) ? props.orderStyles : [props.orderStyles]), 
      ]}>{props.order}</Letter>
    </Stack>
    {props.children}
  </Container>
);