import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import parse from "html-react-parser";

import OverviewSection from "./OverviewSection.web";
import ItemWithImage from "./ItemWithImage.web";
import { CustomSectionContainer, SectionListLayout } from "./CustomContainer.web";

import { IContentData, ISectionData, ITermDetail } from "../../blocks/contentmanagement/src/ContentManagementController";

const NecessarySupplies = ({ data }: { data: IContentData }) => {
  const { attributes } = data;
  const { title, terms } = attributes;

  const [sectionList, setSectionList] = useState<ISectionData[]>([]);

  useEffect(() => {
    if (terms) {
      terms.sort((a, b) => a.id - b.id); 
      const groupedData: { [key: string]: ITermDetail[] } = {};
      terms.forEach(term => {
        const key = term.term;
        if (!groupedData[key]) {
          groupedData[key] = [];
        }
        groupedData[key].push(term);
      });
      const sections = Object.entries(groupedData).map(group => {
        if (group[1].length > 1) {
          return {
            name: group[0],
            definition: '',
            images: [],
            children: group[1].map(each => {
              return { definition: each.definition, images: each.images }
            })
          }
        }
        return {
          id: group[1][0].id,
          name: group[0],
          definition: group[1][0].definition,
          images: group[1][0].images,
        }
      })
      setSectionList(sections)
    }
  }, [terms]);

  return (
    <SectionListLayout styles={{ gap: '10px' }}>
      <OverviewSection 
        images={title?.images.map(image => image.url) || []}
        name={parse(title?.title)} 
        shortDesc={title?.desc && title?.desc !== '' && parse(title?.desc)}
      />
      {sectionList.map((section, index) => (
        <CustomSectionContainer 
          key={index} 
          withHeading={section.name !== ''}
          heading={parse(section.name)}
          contentStyle={{ width: '100%' }}
        >
          <Grid container spacing={2.5}>
            {section.children && section.children.map((child, idx) => (
              <Grid key={idx} item xs={12} md={index % 2 === 0 && 6}>
                <ItemWithImage image={child.images[0].url || ''} text={parse(child.definition)} />
              </Grid>
            ))}
          </Grid>
        </CustomSectionContainer>
      ))}
    </SectionListLayout>
  );
}

export default NecessarySupplies;
