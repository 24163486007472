import React, { useEffect, useState } from "react";
import { Box, Grid, Link } from "@mui/material";
import { styled, SxProps } from "@mui/system";
import parse, { domToReact, Element, HTMLReactParserOptions } from "html-react-parser";

import OverviewSection from "./OverviewSection.web";
import ItemWithImage from "./ItemWithImage.web";
import PlainTextItem from "./PlanTextItem.web";
import { CustomSectionContainer, SectionListLayout } from "./CustomContainer.web";
import { ISectionData } from "./SymptomARD.web";

import { IContentData, ITermDetail } from "../../blocks/contentmanagement/src/ContentManagementController";

const FlexCol = styled(Box)({
  minWidth: '303px',
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  gap: 20
});
const HeadingText = styled(Box)({
  fontWeight: '700',
  fontFamily: 'Lato',
  color: '#586554',
  '& p': { margin: 0 }
});
const CustomLink = styled(Link)({
  color: '#343C32',
  textDecorationColor: '#343C32'
});
const Image = styled('img')({
  width: 80,
  height: 80,
  objectFit: 'contain'
});

const webStyles = {
  alertContainer: { backgroundColor: '#FEF2F2', border: '2px dashed #F87171' },
  wrapContiner: {
    width: 'fit-content', 
    px: 2.5, 
    flexDirection: "row", 
    flexWrap: 'wrap-reverse', 
    lignItems: 'center',  
  }
}

const NauseaAndVomiting = ({ data }: { data: IContentData }) => {
  const { attributes } = data;
  const { title, terms } = attributes;

  const [sectionList, setSectionList] = useState<ISectionData[]>([]);

  useEffect(() => {
    if (terms) {
      terms.sort((a, b) => a.id - b.id); 
      const groupedData: { [key: string]: ITermDetail[] } = {};
      terms.forEach(term => {
        const key = term.term;
        if (!groupedData[key]) {
          groupedData[key] = [];
        }
        groupedData[key].push(term);
      });
      const sections = Object.entries(groupedData).map(group => {
        if (group[1].length > 1) {
          return {
            name: group[0],
            definition: '',
            images: [],
            children: group[1].map(each => {
              return { definition: each.definition, images: each.images }
            })
          }
        }
        return {
          id: group[1][0].id,
          name: group[0],
          definition: group[1][0].definition,
          images: group[1][0].images,
        }
      })
      setSectionList(sections)
    }
  }, [terms]);

  const getParsingOption = (listItemStyles?: SxProps) => {
    const parsingOptions: HTMLReactParserOptions = {
      trim: true,
      replace(domNode) {
          if (!(domNode instanceof Element)) return;
          const replacementByName: { [key: string]: JSX.Element } = {
            "ul": <>{domToReact(domNode.children, parsingOptions)}</>,
            "li": <PlainTextItem textStyle={{ ...listItemStyles }}>{domToReact(domNode.children)}</PlainTextItem>,
            "a": <CustomLink href={domNode.attribs['href']} target="_blank">{domToReact(domNode.children)}</CustomLink>
          };
          if (!replacementByName[domNode.name]) return;
          return replacementByName[domNode.name];
      },
    }
    return parsingOptions
  }

  return (
    <SectionListLayout>
      <OverviewSection 
        images={title?.images.map(image => image.url) || []} 
        name={parse(title?.title)} 
        shortDesc={title?.desc && title?.desc !== '' && parse(title?.desc)}
        containerStyle={{ p: { xs: '20px 16px', md: '40px 130px' } }}
      />
      {sectionList.map((section, index) => {
        if (section.children && section.children.length > 0) {
          if (sectionList[index + 1] && sectionList[index + 1]?.id && sectionList[index + 1]?.images.length > 0) {
            return (
              <CustomSectionContainer
                key={index}
                withHeading
                heading={parse(section.name)}
                headingStyle={{ textAlign: 'center' }}
                containerStyle={{ ...webStyles.alertContainer, py: { xs: 2.5, md: 5 } }}
              >
                <Grid container spacing={{ xs: 2, md: 2.5 }}>
                  {section?.children.map((child, index) => (
                    <Grid key={index} item xs={12} md={6}>
                      <ItemWithImage image={child.images[0]?.url || ''} text={parse(child.definition)} />
                    </Grid>
                  ))}
                </Grid>
              </CustomSectionContainer>
            )
          }
          return (
            <CustomSectionContainer
              key={index}
              withHeading
              heading={parse(section.name)}
              headingStyle={{ textAlign: 'center' }}
              containerStyle={{ py: { xs: 2.5, md: 5 } }}
            >
              <Grid container spacing={{ xs: 2, md: 2.5 }}>
                {section?.children.map((child, index) => (
                  <Grid key={index} item xs={12} md={6}>
                    <ItemWithImage image={child.images[0]?.url || ''} text={parse(child.definition)} />
                  </Grid>
                ))}
              </Grid>
            </CustomSectionContainer>
          )
        }
        if (section.images.length > 0) {
          return (
            <CustomSectionContainer key={index} containerStyle={{ py: { xs: 2.5, md: 5 } }} contentStyle={{ ...webStyles.wrapContiner }}>
              <FlexCol>
                <HeadingText sx={{ fontSize: { xs: '20px', md: '26px' } }}>{parse(section.name)}</HeadingText>
                {parse(section.definition, getParsingOption())}
              </FlexCol>
              <Image src={section.images[0].url || ''} alt="Desc Image" />
            </CustomSectionContainer>
          )
        }
        return (
          <CustomSectionContainer
            key={index}
            withHeading
            heading={parse(section.name)}
            containerStyle={{ py: { xs: 2.5, md: 5 } }}              
            contentStyle={{ px: 2.5, gap: 1.5 }}
          >
            {parse(section.definition, getParsingOption())}
          </CustomSectionContainer>
        )
      })}
    </SectionListLayout>
  );
}

export default NauseaAndVomiting;
