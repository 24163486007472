import React, {useEffect} from 'react'
import {
  useStripe,
} from "@stripe/react-stripe-js";

function StripeCheckout({stripeClientSecret, handleSubmit, handleUpdate}: {stripeClientSecret: string, handleSubmit: any, handleUpdate: any}) {
    const stripe = useStripe();
    useEffect(() => {
        if (!stripe) {
          return;
        }
          if (!stripeClientSecret) {
            return;
          }
    
        stripe.retrievePaymentIntent(stripeClientSecret).then(({ paymentIntent }: any) => {
            let body = {
                plan_id: "",
                stripe_price_id: "",
                payment_intent_id: paymentIntent.id,
                payment_method_id: paymentIntent.payment_method
            }
            const stripeDataString = localStorage.getItem("stripe_data")
            const createPaymentFlow = localStorage.getItem("create_payment_flow") || ""
            if (stripeDataString) {
                const stripeData = JSON.parse(stripeDataString)
                body = {
                    ...body,
                    plan_id: stripeData.id,
                    stripe_price_id: stripeData.stripe_price_id
                }
                localStorage.removeItem("stripe_data")
            }
          switch (paymentIntent.status) {
            case "succeeded":
              if(createPaymentFlow === "retry"){
                localStorage.removeItem("create_payment_flow")
                handleUpdate(paymentIntent.payment_method)
                return
              }
              if(stripeDataString){
                handleSubmit(body)
              }
              break;
            case "processing":
            //   setMessage("Your payment is processing.");
              break;
            case "requires_payment_method":
              // setMessage("Your payment was not successful, please try again.");
              break;
            default:
            //   setMessage("Something went wrong.");
              break;
          }
        });
      }, [stripe, stripeClientSecret]);
  return (
    <></>
  )
}

export default StripeCheckout