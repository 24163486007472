import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles'
import { Box, Divider } from '@mui/material';
import parse, { domToReact, Element, HTMLReactParserOptions } from 'html-react-parser';

import OverviewSection from './OverviewSection.web';
import PlainTextItem from './PlanTextItem.web';
import { CustomSectionContainer, SectionListLayout, SubcatContainer } from './CustomContainer.web';
import { OverviewAnnotation } from './MethodOverviewSection.web';

import { IContentData, ISectionData, ITermDetail } from "../../blocks/contentmanagement/src/ContentManagementController";

const webStyles = {
  customDivider: {
    marginTop: 5, 
    marginBottom: 2.5, 
    backgroundColor: '#586554'
  },
  customListContainer: {
    marginTop: 0,
    marginBottom: 0,
    flexBasis: { xs: '100%', md: 0 },
    borderRadius: '8px', 
    padding: { xs: '20px', md: '40px 20px' }, 
    gap: { xs: 2, md: 2.5 },
    '& ul': { p: 0, margin: 0 }
  }
}

const HeadingText = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  fontFamily: 'Lato',
  fontSize: '26px',
  fontWeight: '700',
  color: '#586554',
  '& p': { color: '#343C32' }
});

export default function MedicationLaxatives({ data }: { data: IContentData }) {
  const { attributes } = data;
  const { title, terms } = attributes;

  const parsingOptions: HTMLReactParserOptions = {
    trim: true,
    replace(domNode) {
      if (!(domNode instanceof Element)) return;
      const replacementByName: { [key: string]: JSX.Element } = {
        "ul": <>{domToReact(domNode.children, parsingOptions)}</>,
        "li": domNode.children.length > 1 && domNode.children.some(node => node instanceof Element && node.name === 'div')
         ? <HeadingText sx={{ fontSize: { xs: 20, md: 26 } }}>{domToReact(domNode.children, parsingOptions)}</HeadingText>
         : <PlainTextItem>{domToReact(domNode.children, parsingOptions)}</PlainTextItem>,
      };

      if (!replacementByName[domNode.name]) return;
  
      return replacementByName[domNode.name];
    }
  }

  const [sectionList, setSectionList] = useState<ISectionData[]>([]);

  useEffect(() => {
    if (terms) {
      terms.sort((a, b) => a.id - b.id); 
      const groupedData: { [key: string]: ITermDetail[] } = {};
      terms.forEach(term => {
        const key = term.term;
        if (!groupedData[key]) {
          groupedData[key] = [];
        }
        groupedData[key].push(term);
      });
      const sections = Object.entries(groupedData).map(group => {
        if (group[1].length > 1) {
          return {
            name: group[0],
            definition: '',
            images: [],
            children: group[1].map(each => {
              return { definition: each.definition, images: each.images }
            })
          }
        }
        return {
          id: group[1][0].id,
          name: group[0],
          definition: group[1][0].definition,
          images: group[1][0].images,
        }
      })
      setSectionList(sections)
    }
  }, [terms]);

  return (
      <SectionListLayout>
        <OverviewSection 
          images={title ? title?.images.map(image => image.url) : []} 
          name={title && parse(title?.title)} 
          shortDesc={title && title?.desc && title?.desc !== '' && parse(title?.desc)}
          annotation={sectionList.length > 0 &&
            <OverviewAnnotation 
              title={parse(sectionList[0].name)} 
              image={sectionList[0].images.length > 0 ? sectionList[0].images[0].url : ''}
              content={parse(sectionList[0].definition, parsingOptions)}
            />}
        />
        {sectionList.length > 1 && sectionList
          .slice(1)
          .map((section, index) => {
            const restList = sectionList.slice(1);
            if (section.id && section.images.length > 0 && restList[index + 1] && restList[index + 1].id && restList[index + 1].images.length > 0) {
              return (
                <CustomSectionContainer key={index} variant="dark">
                  {[...Array(2).keys()].map((num) => (
                    <Box key={num}>
                      <OverviewAnnotation 
                        title={parse(restList[index + num].name)}
                        image={restList[index + num].images.length > 0 ? restList[index + num].images[0]?.url : ''}
                        content={parse(restList[index + num].definition, parsingOptions)}
                        contentStyle={{ gap: 1.5 }}
                      />
                      {num === 0 && <Divider sx={{ ...webStyles.customDivider }} />}
                    </Box>))}
                </CustomSectionContainer>
              )
            }
            if (section.id && section.images.length === 0 && restList[index + 1] && restList[index + 1].id && restList[index + 1].images.length === 0) {
              return (
                <CustomSectionContainer
                  key={index} 
                  variant='light'
                  containerStyle={{ p: { xs: 0 } }} 
                  contentStyle={{ flexDirection: 'row', flexWrap: 'wrap' }}
                >
                  {[...Array(2).keys()].map((num) => (
                    <SubcatContainer
                      key={num}
                      withHeading
                      heading={parse(restList[index + num].name)}
                      containerStyle={[
                        webStyles.customListContainer, 
                        {
                          backgroundColor: num === 0 ? '#F2F4F1' : '#FEF2F2',
                          border: num !== 0 ? '2px dashed #F87171' : 'none',
                        }
                      ]}
                    >
                      {parse(restList[index + num].definition, {
                        replace(domNode) {
                          if (domNode instanceof Element && domNode.name === 'li') {
                            return (
                              <PlainTextItem style={{ p: { xs: '10px', md: '21px 10px' } }}>{domToReact(domNode.children, parsingOptions)}</PlainTextItem>
                            )
                          }
                        },
                      })}
                    </SubcatContainer>))}
                </CustomSectionContainer>
              )
            }
          })}
      </SectionListLayout>
  );
}