import React from 'react'
import { Typography, Box, IconButton, Button } from '@mui/material';
import { styled } from '@mui/system';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
type Props = {
    data: string,
    navigation: any
}
function CategoryBreadcrumb({data, navigation}: Props) {
    const breadcrumbData = JSON.parse(data)
  return (
    <MainContainer>
      <IconButton style={{alignItems: 'center'}} onClick={() => { navigation.goBack() }}>
        <BackIcon />
      </IconButton>
      <BreadCrumbContainer>
      <InlineFlexContainer key={1} sx={{ gap: '2px' }}>
        <BreadcrumbElement onClick={() => { navigation.navigate("Catalogue") }}>
          <PreviousLevelText sx={{ textTransform: "none" }}>Dashboard</PreviousLevelText>
        </BreadcrumbElement>
        <Seperator />
      </InlineFlexContainer>

      {breadcrumbData.Category ? <InlineFlexContainer key={1} sx={{ gap: '2px' }}>
        <BreadcrumbElement onClick={() => { navigation.navigate("Categories", { id: breadcrumbData.Catalogue.id }) }}>
          <PreviousLevelText sx={{ textTransform: "none" }}>{breadcrumbData.Catalogue?.name}</PreviousLevelText>
        </BreadcrumbElement>
        <Seperator />
      </InlineFlexContainer> :
        <InlineFlexContainer key={1} sx={{ gap: '2px' }}>
          <LocationText sx={{ textTransform: "none" }}>
            {breadcrumbData.Catalogue?.name}
          </LocationText>
        </InlineFlexContainer>}


      {breadcrumbData.Category && (breadcrumbData.sub_category_name ? <InlineFlexContainer key={1} sx={{ gap: '2px' }}>
        <BreadcrumbElement onClick={() => { navigation.navigate("Subcategories", { subId: breadcrumbData.Category.id }) }}>
          <PreviousLevelText sx={{ textTransform: "none" }}>{breadcrumbData.Category?.name}</PreviousLevelText>
        </BreadcrumbElement>
        <Seperator />
      </InlineFlexContainer> :
        <InlineFlexContainer key={1} sx={{ gap: '2px' }}>
          <LocationText sx={{ textTransform: "none" }}>
            {breadcrumbData.Category.name}
          </LocationText>
        </InlineFlexContainer>)}

      {breadcrumbData.sub_category_name && <InlineFlexContainer key={1} sx={{ gap: '2px' }}>
        <LocationText sx={{ textTransform: "none" }}>
          {breadcrumbData.sub_category_name.name}
        </LocationText>
      </InlineFlexContainer>}
      </BreadCrumbContainer>
    </MainContainer>
  )
}


const InlineFlexContainer = styled(Box)({
    display: "inline-flex",
    alignItems: "center"
  });
  
  const MainContainer = styled(Box)({
    marginTop: "10px", 
    marginBottom: "10px",
    display: "flex", 
    flexDirection: 'row',
    alignItems: 'center'
   
  });
  const BreadCrumbContainer = styled(Box)({
    display: "flex", 
    flexWrap: "wrap",
    alignItems: 'center',
  })
  
  const BreadcrumbElement = styled(Button)({
    padding: '4px 8px',
    borderRadius: '6px',
    '&:hover': {
      backgroundColor: '#F1F5F9',
    },
    "@media (max-width: 900px)": {
      padding: '0px 0px',
    },
  });
  
  const PreviousLevelText = styled(Typography)({
    fontFamily: "Lato",
    color: "#96A591",
    fontWeight: "400",
    padding: '4px 8px',
    fontSize: 22,
    "@media (max-width: 900px)": {
      fontSize: 14,
    },
  });
  
  const LocationText = styled(PreviousLevelText)({
    color: "#343C32",
    fontWeight: "700",
    "@media (max-width: 900px)": {
      fontSize: 14,
    },
  });
  
  const Seperator = styled(ChevronRightIcon)({
    color: "#96A591",
    width: 24,
    height: 24,
    "@media (max-width: 900px)": {
      width: 14,
      height: 14,
    },
  })
  
  const BackIcon = styled(ArrowBackIcon)({
    color: "#343C32",
    width: 24,
      height: 24,
    "@media (max-width: 900px)": {
      width: 16,
      height: 16,
    },
  })
export default CategoryBreadcrumb
