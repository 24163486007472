//@ts-nocheck
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
const CategoryData = [
  {
    idss: 0,
    title: "User 1",
    value: 'user_1'
  },
  {
    idss: 1,
    title: "User 2",
    value: 'user_2'

  },
];

interface Imagetype {
  uris: string;
  width: number;
  height: number;
}
export interface IImage {
  id: number;
  url: string;
  type: string;
  filename: string;
}
export interface ISectionData  {
  id?: number;
  name: string;
  definition: string;
  images: IImage[];
  children? : { definition: string; images: IImage[] }[];
}
export interface ITermDetail  {
  id: number;
  term: string;
  definition: string;
  images: IImage[];
}
export enum Type {
  Catalogue = "catalogue",
  Category = "category",
  ParentSubcategory = "parent_sub_category",
  Subcategory = "sub_category",
}
export type IBreadcrumb = | {
  id: number;
  name: string;
  type: Type;
  has_content: true;
  content_id: string;
} | {
  id: number;
  name: string;
  type: Type;
  has_content?: false;
}
interface CategoryType {
  idss: number;
  title: string;
  value: string;
}

interface ProductItem  {
  id: string;
  type: string;
  attributes: {
    title: string;
    description: string;
    status: boolean;
    price: number;
    user_type: string;
    quantity: string;
    created_at: string;
    updated_at: string;
    images: [
      {
        id: number;
        url: string;
        type: string;
        filename: string;
      }
    ];
  };
}

export interface IContentData  {
  id: string;
  type: string;
  attributes: {
    title: {
      title: string;
      desc: string;
      images: IImage[];
      layout_id: null | string
    };
    terms: ITermDetail[],
    bread_crumbs: IBreadcrumb[]
  };
}
export interface IGlossary {
  id: string
  type: string
  attributes: {
    term: string
    definition: string
  }
}
// Customizable Area End

export const configJSON = require("./config");


export interface Props {
  // Customizable Area Start
  navigation: any;
  idContent: string | number;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loading: boolean;
  catagorydata: CategoryType[];
  showCategory: boolean;
  category: CategoryType;
  showModel: boolean;
  images: Imagetype[];
  imagesWeb: File[];
  baseImages: string[];
  title: string;
  description: string;
  price: string;
  quantity: string;
  userDataList:ProductItem[];
  contentData: IContentData
  glossariesData: IGlossary[]
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  idManagement: number | string;
  // Customizable Area End
}

export default class ContentManagementController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  userDataListCall:string|Message=''
  getGlossariesApiCallId:string|Message=''
  addDataCall:string|Message=''
  fetchingContentDataApiCallId: string = "";
  fetchSubcatDataApiCallId: string = "";
  fetchSubSubcatDataApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      loading: false,
      catagorydata: CategoryData,
      glossariesData:[],
      showCategory: false,
      category: {
        idss: 0,
        title: "User 1",
      value:'user_1'
      },
      showModel: false,
      images: [],
      imagesWeb:[],
      baseImages: [],
      title: "",
      description: "",
      price: "",
      quantity: "",
      userDataList:[],
      contentData: { 
        id:'',
        type: '',
        attributes: {
          title: {
            title: '',
            desc: '',
            images: [],
          },
          terms:[]
        }
      }
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    const errorResponse = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );
    if(errorResponse){
      this.setState({ loading: false })
      return
    }
    if(responseJson.errors){
      this.setState({ loading: false })
      const errorString = JSON.stringify(responseJson.errors)
      if(errorString.includes('token')){
        this.props.navigation.navigate("EmailAccountLoginBlock")
        return
      }
      this.parseApiCatchErrorResponse(responseJson.errors);
      return
    }
    if (apiRequestCallId === this.getGlossariesApiCallId ) {
        this.setState({ glossariesData: responseJson.data, loading: false })
    }
    if (apiRequestCallId === this.fetchSubSubcatDataApiCallId) {
        this.setState({ contentData: responseJson.data })
    }
    // Customizable Area End
  }

  // Customizable Area Start
  getAccessToken = () => {
    const msg: Message = new Message(getName(MessageEnum.SessionRequestMessage));
    this.send(msg);
  }
  
  getContentData = (contentId: string) => {
      this.getContentAPIData(contentId);
  }
  
  async componentDidMount() {
    super.componentDidMount();
    this.getAccessToken();
    const currRoute = this.props.navigation;
    const contentId = currRoute.getParam('contentId');
    if(currRoute.getLocation().includes("glossaries")){
      this.getGlossariesApi()
      return
    }
    this.getContentData(contentId)
  }

  async componentDidUpdate(prevProps: Readonly<Props>, _prevState: Readonly<S>, _snapshot?: SS | undefined): void {
    const prevRoute = prevProps.navigation;
    const currRoute = this.props.navigation;
    const prevParam = prevRoute.getParam('contentId');
    const currParam = currRoute.getParam('contentId');
    if (prevParam !== currParam ) {
      this.getContentData(currParam)
    }
  }

  getUserDataListApi = async () => {
    this.setState({ loading: true });
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.userDataListCall = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.userListUrl+this.state.category.value
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  getGlossariesApi = async () => {
    this.setState({ loading: true });
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": localStorage.getItem("token") || "",
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getGlossariesApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.glossariesUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getContentAPIData = async (contentId: string) => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": localStorage.getItem("token") || "",
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.fetchRealContentDataApiUrl + contentId
    );

    this.fetchSubSubcatDataApiCallId = requestMessage.messageId;

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  postAddDataApi=async()=>{
    const header = {
    };
    const formData = new FormData();
    formData.append("title", this.state.title)
    formData.append("description", this.state.description)
    formData.append("price", this.state.price);
    formData.append("quantity", this.state.quantity)
    formData.append("user_type", this.state.category.value)

    if(this.isPlatformWeb()){
      this.state.imagesWeb.forEach((item: File) => {
        formData.append("images[]", item)
      })
    }else{
      this.state.images.forEach((item: Imagetype) => {
        formData.append("images[]", {
          uri: item.uris,
          type: "image/png",
          name: "image"
        })
      })
    }

   

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.addDataCall = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
     configJSON.addDataUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;


  }

  showCategory = () =>
    this.setState({
      showCategory: !this.state.showCategory,
    });

  onSelectCategory = (item: CategoryType) => {
    this.setState({ category: item, showCategory: false },()=>{
      this.getUserDataListApi()
    });
  };

  onSelectCategoryWeb = (value: string) => {
    const filteredData = this.state.catagorydata.find((finddd) => finddd.value === value)
    filteredData && this.setState({ category: filteredData },()=>{
      this.getUserDataListApi()
    });
  };


  addNewProduct = () => this.setState({ showModel: true });

  hideModal = () => this.setState({ showModel: false });

  openImagePicker = () => {

  };

  onChangeImage = (event:React.ChangeEvent) => {
    const targetData= event.target as HTMLInputElement;
    const fileValue: File = (targetData.files as FileList)[0];
    const base64Img = URL.createObjectURL(fileValue)
    this.setState({ imagesWeb: [...this.state.imagesWeb, fileValue], baseImages: [...this.state.baseImages, base64Img] });
  };

  onChangeTitle = (text: string) => {
    this.setState({ title: text });
  };

  onChangeDescription = (text: string) => {
    this.setState({ description: text });
  };

  onChangePrice = (text: string) => {
    this.setState({ price: text });
  };

  onChangeQuantity = (text: string) => {
    this.setState({ quantity: text });
  };

  // Customizable Area End
}
