import React from 'react';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Stack from '@mui/material/Stack';
import {  Button } from '@builder/component-library';
import { SxProps } from '@mui/system';

interface Props {
    open: boolean,
    handleCloseModal: () => void,
    headerTitle: string,
    renderFirstDes: () => void,
    secondLineDes?: string,
    textButtonLeft: string,
    textButtonRight: string,
    handleClickButtonRight: () => void
    loading: boolean,
    height?: number,
    containerStyle?: SxProps;
}
export default function ModalConfirm(props: Props) {
  return (
    <Modal
    open={props.open}
    onClose={props.handleCloseModal}
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description"
  >
    <Stack 
      direction="column" 
      sx={[
        webStyles.modalContainer,
        ...(Array.isArray(props.containerStyle) ? props.containerStyle : [props.containerStyle]),
      ]}
    >
      <Typography style={webStyles.headerTitle}>{props.headerTitle}</Typography>
      <Stack direction="column" sx={webStyles.modalContent}>
        {props.renderFirstDes()}
        <Typography style={{...webStyles.modalTextContent, marginTop: 5}}>{props.secondLineDes}</Typography>
      </Stack>
      <Stack direction="row" sx={webStyles.buttonModalContainer}>
        <Button
                        data-test-id={"cancel-send-email-button"}
                        text={props.textButtonLeft}
                        textStyle={{fontFamily: "Lato", color:"#586554",}}
                        onPress={props.handleCloseModal}
                        style={{backgroundColor: '#fff', marginRight: 10,  borderColor: "#D6DCD5", borderWidth: 1, borderStyle: 'solid'}}
                      />
        <Button
                        data-test-id={"send-email-button"}
                        text={props.textButtonRight}
                        onPress={props.handleClickButtonRight}
                        loading={props.loading}
                        textStyle={{fontFamily: "Lato", color:"#fff"}}
                        style={{backgroundColor: '#7C8E76', minWidth: 97}}
                      />
                    
      </Stack>
    </Stack>
  </Modal>
  );
}
const webStyles = {
    modalContainer: {
      background: "#fff",
      position: 'absolute' as 'absolute',
      width: 600,
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      bgcolor: 'background.paper',
      borderRadius: '8px 8px 32px 8px',     
    },
    buttonModalContainer: {
      alignItems: 'center',
      justifyContent: { xs: "center", md: "flex-end" },
      padding: '30px',
      paddingRight: '16px',
      paddingBottom: '30px'
    },
    headerTitle: {
      fontFamily: "Lato",
      fontWeight: '700',
      marginTop: "24px",
      marginBottom: "24px",
      marginLeft: "40px",
      color: "#343c32"
    },
    modalContent: {
      border: '1px solid #D6DCD5',
      padding: { xs: "40px 20px", md: "40px"},
    },
    modalTextContent: {
      fontSize: 16,
      fontFamily: "Lato",
         color: "#000"
    },
    headerContainer: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: 8,
      borderRadius: 4,
      padding: 20,
      background: '#FFFFFF'
    },
}