import React, { useEffect, useState } from "react";
import parse, { domToReact, Element, HTMLReactParserOptions } from "html-react-parser";

import PlainTextItem from "./PlanTextItem.web";
import { CustomSectionContainer, SectionListLayout } from "./CustomContainer.web";

import { IContentData, ISectionData, ITermDetail } from "../../blocks/contentmanagement/src/ContentManagementController";

const DryMouthCare = ({ data }: { data: IContentData }) => {
  const { attributes } = data;
  const { terms } = attributes;

  const parsingOptions: HTMLReactParserOptions = {
    trim: true,
    replace(domNode) {
      if (domNode instanceof Element && domNode.name === 'ul') {
        return (
          <>{renderStyledListItem(domNode)}</>
        );
      }
    }
  }

  const [sectionList, setSectionList] = useState<ISectionData[]>([]);

  useEffect(() => {
    if (terms) {
      terms.sort((a, b) => a.id - b.id); 
      const groupedData: { [key: string]: ITermDetail[] } = {};
      terms.forEach(term => {
        const key = term.term;
        if (!groupedData[key]) {
          groupedData[key] = [];
        }
        groupedData[key].push(term);
      });
      const sections = Object.entries(groupedData).map(group => {
        if (group[1].length > 1) {
          return {
            name: group[0],
            definition: '',
            images: [],
            children: group[1].map(each => {
              return { definition: each.definition, images: each.images }
            })
          }
        }
        return {
          name: group[0],
          definition: group[1][0].definition,
          images: group[1][0].images,
        }
      })
      setSectionList(sections)
    }
  }, [terms]); 

  const renderStyledListItem = (node: Element) => {
    return node.children
      .filter(child => child instanceof Element && child.name === 'li')
      .map((child, index) => child instanceof Element && (
        <PlainTextItem key={index} style={{ paddingY: '12px', bgcolor: index % 2 === 0 ? 'transparent' : '#F2F4F1' }}>
          {domToReact(child.children)}
        </PlainTextItem>
      ))
  }
  
  return (
    <SectionListLayout styles={{ gap: '10px' }}>
      {sectionList.map((section, index) => 
        <CustomSectionContainer 
          key={index} 
          variant="light" 
          containerStyle={{ p: { xs: "20px 16px", md: "40px 130px" } }}
          contentStyle={{ width: '100%' }}
        >
          <CustomSectionContainer 
            withHeading={!!section.name}
            variant="light"
            heading={parse(section.name)}
            headingStyle={{ textAlign: 'center' }}
            containerStyle={{ border: "1px solid #D6DCD5", p: { xs: 2.5 } }}
            contentStyle={{ width: '100%', gap: 0 }}
          >
            {parse(section.definition, parsingOptions)}
          </CustomSectionContainer>
        </CustomSectionContainer>)}
    </SectionListLayout>
  );
}

export default DryMouthCare;
