import React, { useEffect, useState } from "react";
import { Box, Stack, Typography } from "@mui/material";
import { styled } from "@mui/system";
import parse, { domToReact, Element, HTMLReactParserOptions } from 'html-react-parser';

import OverviewSection from "./OverviewSection.web";
import PlainTextItem from "./PlanTextItem.web";
import { OverviewAnnotation } from "./MethodOverviewSection.web";
import { CustomSectionContainer, SectionListLayout } from "./CustomContainer.web";
import { ISectionData } from "./SymptomARD.web";

import { IContentData, IImage, ITermDetail } from "../../blocks/contentmanagement/src/ContentManagementController";

const options: HTMLReactParserOptions = {
  trim: true,
  replace(domNode) {
    if (!(domNode instanceof Element)) return;

    const replacementByName: { [key: string]: JSX.Element } = {
      "ul": (
        <Stack direction="column" sx={{ gap: '12px' }}>
          {domToReact(domNode.children, options)}
        </Stack>
      ),
      "li": <PlainTextItem>{domToReact(domNode.children, options)}</PlainTextItem>,
      "p": <></>,
    };

    if (!replacementByName[domNode.name]) return;

    return replacementByName[domNode.name];
  }
}

const AlertContainer = styled(Box)({
  borderRadius: '8px',
  border: '2px dashed #F87171',
  backgroundColor: '#FEF2F2',
  padding: '20px 16px',
  fontFamily: 'Lato',
  fontSize: '16px',
  lineHeight: '19.2px',
  color: '#343C32',
  '@media (min-width: 900px)': {
    padding: '20px 130px',
    fontSize: '22px',
    lineHeight: '26.4px',
  } 
});
const HeadingText = styled(Typography)({
  fontFamily: 'Lato',
  fontSize: '26px',
  fontWeight: '700',
  color: '#586554',
});

const Item = ({ definition, images }: { definition: string; images: IImage[] }) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(definition, 'text/html');
  const specificNode = doc.querySelector('h5');

  if (!specificNode) return <></>;

  const nextNode = specificNode.nextElementSibling;
  if (!nextNode) {
    return <HeadingText>{parse(specificNode.innerHTML)}</HeadingText>
  }

  return (
    <Box sx={{ p: { xs: 1.25, md: 2.5} }}>
      <OverviewAnnotation
        title={parse(specificNode.innerHTML)} 
        content={parse(nextNode.outerHTML, options)}
        image={images[0]?.url || ''} 
        titleStyle={{ mb: { xs: 2.5 } }}
        layoutStyle={{ alignItems: 'center' }}
      />
    </Box>
   
  )
}

const ChangingLinens = ({ data }: { data: IContentData }) => {
  const { attributes } = data;
  const { title, terms } = attributes;

  const [sectionList, setSectionList] = useState<ISectionData[]>([]);

  useEffect(() => {
    if (terms) {
      terms.sort((a, b) => a.id - b.id); 
      const groupedData: { [key: string]: ITermDetail[] } = {};
      terms.forEach(term => {
        const key = term.term;
        if (!groupedData[key]) {
          groupedData[key] = [];
        }
        groupedData[key].push(term);
      });
      const sections = Object.entries(groupedData).map(group => {
        if (group[1].length > 1) {
          return {
            name: group[0],
            definition: '',
            images: [],
            children: group[1].map(each => {
              return { definition: each.definition, images: each.images }
            })
          }
        }
        return {
          id: group[1][0].id,
          name: group[0],
          definition: group[1][0].definition,
          images: group[1][0].images,
        }
      })
      setSectionList(sections)
    }
  }, [terms]);

  return (
    <SectionListLayout>
      <OverviewSection 
        images={title?.images.map(image => image.url)} 
        name={parse(title?.title)} 
        shortDesc={title?.desc && title?.desc !== '' && parse(title?.desc)}
        containerStyle={{ backgroundColor: 'transparent' }} 
      />
      <CustomSectionContainer variant="light" containerStyle={{ px: { xs: 0, md: '130px' }, py: 0 }}>
        {sectionList.map((section, index) => {
          if (section.children && section.children.length > 0) {
            return (
              <CustomSectionContainer 
                key={index}
                withHeading
                heading={parse(section.name)}
                containerStyle={{ p: { xs: 2.5 } }}
              >
                {section.children.map((child, idx) => <Item key={idx} {...child} />)}
              </CustomSectionContainer>
            )
          }
          return (
            <CustomSectionContainer key={index} containerStyle={{ p: { xs: 2.5 } }}>
              <OverviewAnnotation 
                title={parse(section.name)} 
                content={parse(section.definition, options)}
                image={section.images[0]?.url || ''} 
                titleStyle={{ mb: { xs: 2.5 } }}
                layoutStyle={{ alignItems: 'center' }}
              />
              {parse(section.definition, {
                ...options,
                replace(domNode) {
                  if (!(domNode instanceof Element)) return;

                  const replacementByName: { [key: string]: JSX.Element } = {
                    "p": <AlertContainer>{domToReact(domNode.children)}</AlertContainer>
                  };

                  if (!replacementByName[domNode.name]) return <></>;

                  return replacementByName[domNode.name];
                },
              })}
            </CustomSectionContainer>
          )
        })}
      </CustomSectionContainer>
    </SectionListLayout>
  );
}

export default ChangingLinens;
