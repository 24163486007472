import React, { Children, ReactNode } from 'react';
import { Grid, Box, Stack } from '@mui/material';
import { styled, SxProps } from '@mui/system';
import parse from "html-react-parser";

const Container = styled(Grid)({
  width: '100%',
  maxWidth: '100%',
  padding: '10px',
  display: 'flex',
  gap: '20px',
  alignItems: 'center',
  background: 'white',
  borderRadius: '8px',
  borderWidth: '1px',
  borderStyle: 'solid',
  borderColor: '#D6DCD5',
  textAlign: 'left'
});

const Image = styled('img')({
  maxWidth: '120px', 
  maxHeight: '120px',
  objectFit: 'contain'
});

const BaseTextContainer = styled(Stack)({
  justifyContent: 'safe center',
  fontWeight: '400',
  fontFamily: 'Lato',
  color: '#343C32',
  lineHeight: '26.4px',
  '& p': {
    margin: 0
  }
});

const webStyles = {
  singleContentNode: {
    width: '100%', 
    justifyContent: 'center', 
    alignItems: 'start',
  }
}

type IItemWithImageProps = {
  image: string;
  imageSize?: number;
  text: string[] | ReactNode;
  style?: SxProps;
  textStyle?: SxProps;
}

const ItemWithImage = (props: IItemWithImageProps) => {
  const getResponsiveSize = (size: number) => {
    if (size === 80) {
      return 60;
    }
    if (size === 120) {
      return 80;
    }
    return 24;
  }

  return (
    <Container sx={[
      { p: { xs: 1.25, md: 2.5 } },
      props.imageSize && props.imageSize === 24 && { height: { xs: 'fit-content', md: '120px' } },
      ...(Array.isArray(props.style) ? props.style : [props.style])
    ]}>
      <Image 
        src={props.image} 
        alt="Desc Image" 
        sx={{ 
          width: { xs: !props.imageSize ? '60px' : `${getResponsiveSize(props.imageSize)}px`, md: !props.imageSize ? '80px' : `${props.imageSize}px` }, 
          height: 'auto' 
        }}
      />
      {Children.count(props.text) === 1 ?
        <Stack 
          direction="column" 
          sx={[
            webStyles.singleContentNode, 
            { height: '100%' },
            ...(Array.isArray(props.textStyle) ? props.textStyle : [props.textStyle])
          ]}>
            <BaseTextContainer direction="column" sx={{ fontSize: { xs: '16px', md: '22px' }, height: '100%', overflow: 'auto' }}>
              {props.text}    
            </BaseTextContainer>
        </Stack> :
        <Box sx={[
          { 
            width: '100%', 
            height: !props.imageSize 
              ? { xs: 'fit-content', md: '80px' } 
              : (props.imageSize === 24 
                ? '100%' : { xs: 'fit-content', md: `${props.imageSize}px` }) 
          },
          ...(Array.isArray(props.textStyle) ? props.textStyle : [props.textStyle])
        ]}>
          <BaseTextContainer direction="column" sx={{ fontSize: { xs: '16px', md: '22px' }, height: '100%', overflow: 'auto' }}>
            {typeof props.text === 'string' ? parse(props.text) : props.text}
          </BaseTextContainer>
        </Box>
      }
    </Container>
  );
}

export default ItemWithImage;
