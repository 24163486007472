import React, { FC } from 'react';
import { Typography } from '@mui/material';
import { styled, ThemeProvider, createTheme } from '@mui/material/styles'
import Box from '@mui/material/Box';

import { WithChildrenProps } from '../../blocks/landingpage/src/AboutUsController.web';

export default function UnauthorizedFooter({goToTAC, goToPP, goToCU} : {goToTAC: () => void, goToPP?: () => void, goToCU?: () => void}) {
  return (
    <Container >
      <ContentContainer sx={{ justifyContent: goToCU ? "end" : "start"}}>

      <Text1 data-test-id="btnTAC" onClick={goToTAC}>Terms and Conditions</Text1>

      {goToPP && <Text data-test-id="btnPP" onClick={goToPP}>Privacy Policy</Text>}
      {goToCU && <Text data-test-id="goToCU" onClick={goToCU}>Contact Us</Text>}
      </ContentContainer>
    </Container>
  );
}

const CustomBreakpointWrapper: FC<WithChildrenProps> = ({ children }) => {
  return (
    <ThemeProvider
      theme={createTheme({
        breakpoints: {
          values: {
            xl: 1536,
            lg: 1200,
            md: 1024,
            sm: 600,
            xs: 0,
          },
        },
      })}
    >
      {children}
    </ThemeProvider>
  );
}

export { CustomBreakpointWrapper };

const Container = styled(Box)({
  background: '#7C8E76',
  width: '100%',
});
const ContentContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  flex: 1,
  paddingRight: 130,
  paddingLeft: 130,

  '@media (max-width: 600px)': {
    paddingLeft: 16,
    paddingRight: 16,
  }
});
const Text = styled(Typography)({
  color: '#FFFFFF',
  fontSize: 24,
  fontWeight: 700,
  marginTop: 32,
  marginBottom: 32,
  marginLeft: 16,
  cursor: 'pointer',
  fontFamily: "Lato",
  '@media (max-width: 600px)': {
    fontSize: 16,
  }
})
const Text1 = styled(Typography)({
  color: '#FFFFFF',
  fontSize: 24,
  fontWeight: 700,
  marginTop: 32,
  marginBottom: 32,
  cursor: 'pointer',
  fontFamily: "Lato",
  '@media (max-width: 600px)': {
    fontSize: 16,
  }
})
