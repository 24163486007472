import React, { Children, isValidElement, useEffect, useState } from "react";
import { Box } from "@mui/material";
import { styled } from '@mui/system';
import parse, { domToReact, Element, HTMLReactParserOptions } from 'html-react-parser';

import OverviewSection from "./OverviewSection.web";
import PlainTextItem from "./PlanTextItem.web";
import ItemWithImage from "./ItemWithImage.web";
import { CustomSectionContainer, SectionListLayout } from "./CustomContainer.web";

import { IContentData, ISectionData, ITermDetail } from "../../blocks/contentmanagement/src/ContentManagementController";
import { extractTextNodeContent } from "./ReactHtmlParser";

const webStyles = {
  alertContainer: {
    bgcolor: '#FEF2F2',
    border: '2px dashed #F87171',
    padding: '20px 16px',
    '@media (min-width: 900px)': {
      padding: '20px 130px',
    }
  },
  contentContainer: {
    bgcolor: '#F2F4F1',
    p: { xs: "12px", lg: "20px" },
    '@media (min-width: 900px)': {
      padding: '20px',
    }
  }
} as const;

const FlexCol = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: '12px',
});
const HeadingContainer = styled(Box)({
  fontSize: '26px', 
  fontWeight: '700',
  fontFamily: 'Lato',
  color: '#586554',
  '& p': {
    margin: 0
  }
});

const CareForCaregivers = ({ data }: { data: IContentData }) => {
  const { attributes } = data;
  const { title, terms } = attributes;

  const parsingOptions: HTMLReactParserOptions = {
    replace(domNode) {
      if (!(domNode instanceof Element)) return;
      const replacementByName: { [key: string]: JSX.Element } = {
        "ul": !domNode.previousSibling ? (
          <FlexCol sx={{ gap: '20px' }}>
            {domToReact(domNode.children, parsingOptions)}
          </FlexCol>
         ) : (domNode.previousSibling && domNode.previousSibling instanceof Element && domNode.previousSibling.name === "h5" ? 
          <FlexCol>{domToReact(domNode.children, parsingOptions)}</FlexCol> 
          : <FlexCol sx={{ gap: '10px' }}>{domToReact(domNode.children, parsingOptions)}</FlexCol>
        ),
        "li": <PlainTextItem>{domToReact(domNode.children, parsingOptions)}</PlainTextItem>,
        "h5": <HeadingContainer sx={{ fontSize: { xs: 20, md: 26 } }}>{domToReact(domNode.children)}</HeadingContainer>,
        "p": <PlainTextItem withoutIcon style={{ mt: '-8px', mb: '-10px' }}>{domToReact(domNode.children)}</PlainTextItem>,
      };

      if (!replacementByName[domNode.name]) return;
  
      return replacementByName[domNode.name];
    }
  }

  const [sectionList, setSectionList] = useState<ISectionData[]>([]);

  useEffect(() => {
    if (terms) {
      terms.sort((a, b) => a.id - b.id); 
      const groupedData: { [key: string]: ITermDetail[] } = {};
      terms.forEach(term => {
        const key = term.term;
        if (!groupedData[key]) {
          groupedData[key] = [];
        }
        groupedData[key].push(term);
      });
      const sections = Object.entries(groupedData).map(group => {
        if (group[1].length > 1) {
          return {
            name: group[0],
            definition: '',
            images: [],
            children: group[1].map(each => {
              return { definition: each.definition, images: each.images }
            })
          }
        }
        return {
          id: group[1][0].id,
          name: group[0],
          definition: group[1][0].definition,
          images: group[1][0].images,
        }
      })
      setSectionList(sections)
    }
  }, [terms]);

  const checkTagExisted = (htmlString: string, tagName: string) => {
    const parsedElements = parse(htmlString);
    return Children.toArray(parsedElements).some(child => {
        return isValidElement(child) && child.type === tagName;
    });
  }

  return (
    <SectionListLayout>
      <OverviewSection 
        images={title?.images.map(image => image.url) || []} 
        name={parse(title?.title)} 
        shortDesc={title?.desc && extractTextNodeContent(title?.desc) !== '' && parse(title?.desc)}
        containerStyle={{ backgroundColor: 'transparent' }} 
      />
      <CustomSectionContainer 
        variant="light" 
        containerStyle={{ py: 0 }}
        contentStyle={{ width: '100%' }}
      >
        {sectionList.map((section, index) => {
          if (section.children && section.children.length > 0) {
            return (
              <CustomSectionContainer
                key={index}
                withHeading
                heading={parse(section.name)}
                variant="light"
                containerStyle={{ p: { xs: "12px", lg: "20px" }, background: "#F2F4F1" }} 
              >
                {section.children.map((child, index) => (
                  <ItemWithImage
                    key={index} 
                    image={child.images[0].url || ''} 
                    text={<FlexCol>{parse(child.definition, parsingOptions)}</FlexCol>}
                    imageSize={120}
                    style={{ bgcolor: '#FFFFFF', flexWrap: { xs: 'wrap', md: 'nowrap' }, alignItems: { xs: "flex-start", md: 'center' } }}
                    textStyle={{ maxHeight: 'fit-content' }}
                  />
                ))}
              </CustomSectionContainer>
            )
          }
          return (
            <CustomSectionContainer
              key={index}
              withHeading={extractTextNodeContent(section.name) !== ''}
              heading={section.name !== '' && parse(section.name)}
              variant="light"
              containerStyle={[
                (section.name === '' || checkTagExisted(section.definition, 'h5')) && { p: { xs: 0 } }, 
                !!section.id && section.name !== '' && !checkTagExisted(section.definition, 'h5') && webStyles.alertContainer,
                !!section.id && section.name !== '' && checkTagExisted(section.definition, 'h5') && webStyles.contentContainer,
              ]}
            >
              {parse(section.definition, parsingOptions)}
            </CustomSectionContainer>
          )
        })}
      </CustomSectionContainer>
    </SectionListLayout>
  );
}

export default CareForCaregivers;
