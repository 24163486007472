import React, { useState} from 'react'
import {
  PaymentElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
const config = require("../../framework/src/config");
import {Button } from '@builder/component-library';
type Props = {
  onClose: () => void
  isShowModal: boolean
  plan: any,
  stripeClientSecret: string
}
function CheckoutForm(props: Props) {
  const stripe = useStripe();
  const elements = useElements();
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const currentUserData = localStorage.getItem("user_data") ?? ""
  const getHospiceName = () => {
    if(currentUserData && currentUserData !== ""){
      const currentData = JSON.parse(currentUserData)
      return currentData?.data?.attributes.hospice_name ?? "name"
    }
    return "Not set name"
  }
  const getHospiceLogo = () => {
    if(currentUserData && currentUserData !== ""){
      const currentData = JSON.parse(currentUserData)
      return currentData?.data?.attributes.hospice_logo ? `${config.baseURL}/${currentData?.data?.attributes.hospice_logo}` : require('./hospice_logo.png')
    }
    return require('./hospice_logo.png')
  }

  const handleSubmit = async () => {
    if (!stripe || !elements) {
      return;
    }

    setIsLoading(true);
    localStorage.setItem("user_status", 'paid')
    localStorage.setItem("main_flow", 'hospice')
    localStorage.setItem("stripe_data", JSON.stringify({stripe_price_id: props.plan.stripe_price_id,id: props.plan.id}))
    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: { 
        return_url: `${window.location.origin}/dashboard`,
      },
    });
    if (error.type === "card_error" || error.type === "validation_error") {
      setMessage(error.message as string);
    } else {
      setMessage("An unexpected error occurred.");
    }


    setIsLoading(false);
  };
  const renderHeader = () => {
    return <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', borderBottom: "1px solid #E2E8F0", paddingTop: 24,paddingBottom: 24, paddingLeft: 40, paddingRight: 20}}>
        <Typography style={{fontFamily: "Lato", fontSize:20, fontWeight: "700", color: "#343C32"}}>Payment details</Typography>
      <IconButton onClick={props.onClose}>
      <CloseIcon/>

      </IconButton>
    </div>
  }
  const renderInformation = () => {
   return ( <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', paddingBottom: 24, paddingTop: 24, borderBottom: "1px solid #E2E8F0"}}>
      <img src={getHospiceLogo()} style={{width: 44, height: 44, borderRadius: 50, border: "1px solid #586554"}}/>
      <div style={{marginLeft: 10, display: 'flex', flexDirection: 'column', flex: 1}}>
        <Typography style={{fontFamily: "Lato", fontSize: 18, fontWeight: "700", color: "#586554"}}>{getHospiceName()}</Typography>
        <Typography style={{fontFamily: "Inter", fontSize: 12, fontWeight: "400", color: "#7C8E76"}}>
          {`${props.plan.interval}-Month Plan`}</Typography>
      </div>
      <Typography style={{fontFamily: "Inter", fontSize: 20, fontWeight: "700", color: "#343C32"}}>${props.plan.price}</Typography>
    </div>)
  }
  const renderTotalAmount = () => {
    return <div style={{display: 'flex', alignItems: 'center', flexDirection: 'row', justifyContent: 'space-between',paddingBottom: 24, paddingTop: 24, borderTop: "1px solid #E2E8F0"}}>
        <Typography style={{fontFamily: "Lato", fontSize: 18, fontWeight: "700", color: "#343C32"}}>Total Amount</Typography>
        <Typography style={{fontFamily: "Inter", fontSize: 20, fontWeight: "700", color: "#343C32"}}>${props.plan.price}</Typography>
    </div>
  }

  return (
    <Modal
      open={props.isShowModal}
      onClose={props.onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
    >
      <div style={{  backgroundColor: '#FFFFFF', display: 'flex', flexDirection: 'column', maxHeight: "90%", overflowY: 'scroll',  borderRadius: 8, borderBottomRightRadius: 32, overflowX: 'hidden' }}>
        {renderHeader()}
        <div style={{display: 'flex', flexDirection: 'column', paddingLeft: 40, paddingRight: 40,rowGap: 24}}>
          {renderInformation()}
          <div>
          <PaymentElement id="payment-element" options={{ layout: "tabs" }} />

          </div>
          {renderTotalAmount()}
        </div>
          <Button
            data-test-id={"button-submit"}
            style={{ backgroundColor: !(isLoading || !stripe || !elements) ? "#7C8E76" : "#D6DCD5",  marginLeft: 24, marginRight: 24, marginTop: 12, marginBottom: 12, width: 512 }}
            textStyle={{ fontSize: 16, fontWeight: '700', color: !(isLoading || !stripe || !elements) ? '#fff' : "#7C8E76", fontFamily: "Lato" }}
            text="Pay now"
            onPress={handleSubmit}
            loading={isLoading}
          />
          {/* Show any error or success messages */}
          {message && <div id="payment-message">{message}</div>}
      </div>
    </Modal>
  );
}

export default CheckoutForm