import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
interface IPatient {
  patient_id: number;
  patient_name: string;
  patient_status: boolean;
  caregivers: IPatientCaregiver[];
  no?: number
}
interface IPatientCaregiver {
  caregiver_id: number;
  caregiver_name: string;
  relation_to_patient: string;
  caregiver_email: string;
  caregiver_phone_number: string;
  no_of_devices: number;
  add_patient_id: number;
}
// Customizable Area End

export const webConfigJSON = require("./config.js");

export interface Props {
  navigation: any;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  dashboardData: IPatient[];
  totalCandidates: string;
  type: string;
  token: string;
  errorMsg: string;
  loading: boolean;
  anchorEl: HTMLButtonElement | null;
  isOpenPopoverInfo: boolean;
  isOpenModalResendEmail: boolean
  caregiverSelected: IPatientCaregiver | null
  successMessage: string
  isShowToast: boolean
  currentUserData: string
  originDashboardData: IPatient[];
  currentHoverId: string
  sortConfig: { key: string, direction: 'asc' | 'desc' } | null;
  // Customizable Area End
}
interface SS {}

export default class DashboardController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  apiDashboardItemCallId: string = "";
  dashboardApiCallId: string = "";
  apiGetQueryStrinurl: string = "";
  apiPostResendEmailId: string = ""
  apiPostCreateSubscriptionId: string = ""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
      type: "",
      dashboardData: [],
      totalCandidates: "",
      errorMsg: "",
      token: "",
      loading: true,
      anchorEl: null,
      isOpenPopoverInfo: false,
      isOpenModalResendEmail: false,
      caregiverSelected: null,
      successMessage: "",
      isShowToast: false,
      currentUserData: "",
      originDashboardData: [],
      currentHoverId: "1",
      sortConfig: null
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getDashboardData();
    // Customizable Area Start
    // Customizable Area End
  }

  getDashboardData(): boolean {
    // Customizable Area Start
    this.handleGetData()
    // Customizable Area End
    return true;
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    this.handleResponseMessage(message)
    
    // Customizable Area End
  }
  // Customizable Area Start
  handleGetData = ()   => {
    let token =  localStorage.getItem("token") ?? ""
    let messageShow =  sessionStorage.getItem("pingenerator_message")
    if(messageShow){
      this.showModalSuccess(messageShow)
      sessionStorage.removeItem("pingenerator_message")
    }
    this.setState({ token: token}, () => {
      this.getListPatient()
    });
  }
  handleResendEmail = () => {
    this.setState({
      loading: true
    })
    const webHeader = {
      "token": this.state.token
    };
   
    const resendCaregiver = this.state.caregiverSelected
    if(!resendCaregiver){
      return false
    }
    const httpBody = {
      caregiver: {
        caregiver_name: resendCaregiver.caregiver_name,
        relation_to_patient: resendCaregiver.relation_to_patient,
        caregiver_email: resendCaregiver.caregiver_email,
        caregiver_phone_number: resendCaregiver.caregiver_phone_number
      }
    }
    const webRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiPostResendEmailId = webRequestMessage.messageId;
    webRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      webConfigJSON.patientEndpoint + `/${resendCaregiver.add_patient_id}/caregivers/${resendCaregiver.caregiver_id}/resend_email`
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(webHeader)
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      webConfigJSON.postApiMethodType
    );
    runEngine.sendMessage(webRequestMessage.id, webRequestMessage);
    return true
   
  }
  handleUpdateUserSubscription = (payment_method_id: string) => {
    const webHeader = {
      "token": this.state.token
    };
   
    const httpBody = {
      subscription: {
        payment_method_id
      }
    }
    const webRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    webRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      webConfigJSON.updateSubscribePlanEndpoint
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(webHeader)
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      webConfigJSON.postApiMethodType
    );
    runEngine.sendMessage(webRequestMessage.id, webRequestMessage);
    return true
   
  }
  handleCreateUserSubscription = (data: {plan_id: string,
    stripe_price_id: string,
    payment_intent_id: string,
    payment_method_id: string}) => {
    const webHeader = {
      "token": this.state.token
    };
    const formData = new FormData();
    formData.append("subscription[stripe_price_id]", data.stripe_price_id)
    formData.append("subscription[plan_id]", data.plan_id)
    formData.append("subscription[payment_intent_id]", data.payment_intent_id);
    formData.append("subscription[payment_method_id]", data.payment_method_id)
   
    const webRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiPostCreateSubscriptionId = webRequestMessage.messageId
    webRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      webConfigJSON.subscribePlanEndpoint
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(webHeader)
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      webConfigJSON.postApiMethodType
    );
    runEngine.sendMessage(webRequestMessage.id, webRequestMessage);
    return true
   
  }
  handleResponseMessage = (message:Message) => {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const webApiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let webResponseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (webResponseJson && !webResponseJson.errors) {
        if (webApiRequestCallId === this.apiDashboardItemCallId) {
          let newDashboardData = []
          if(webResponseJson.length > 0){
            newDashboardData = webResponseJson.filter((patient: IPatient) => patient.caregivers.length > 0).map((patient: IPatient, index: number) => ({...patient, no: index + 1}))
          }
          this.setState({
            errorMsg: "",
            dashboardData: this.handleFilterActivePatient(newDashboardData),
            originDashboardData: newDashboardData,
            loading: false
          });
        }
        if(webApiRequestCallId === this.apiPostResendEmailId){
          this.handleCloseModalResendEmail()
          this.showModalSuccess("Email resent to caregiver. Please tell them to check their inbox or spam!")
        }
      }
      this.handleErrorMessage(message, webApiRequestCallId)
    }
  }
  showModalSuccess = (message: string) => {
    this.setState({
      loading: false,
      isShowToast: true,
      successMessage: message
    })
    setTimeout(() => {
      this.setState({
        isShowToast: false
      })
    }, 3000);
  }
  handleErrorMessage = (message: Message, webApiRequestCallId: string) => {
    let webErrorReponse = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );
    let webResponseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (webResponseJson && webResponseJson.errors) {
      const errorString = JSON.stringify(webResponseJson.errors)
      if(errorString.includes('token')){
        this.props.navigation.navigate("EmailAccountLoginBlock")
        return
      }
      if (webApiRequestCallId === this.apiDashboardItemCallId) {
        this.setState({
          errorMsg: webErrorReponse,
          loading: false
        });
      }
    }
  }
  handleOpenModalResendEmail = (caregiver: IPatientCaregiver) => {
    this.setState({
      caregiverSelected: caregiver,
      isOpenModalResendEmail: true
    })
  }
  handleCloseModalResendEmail = () => {
    this.setState({
      caregiverSelected: null,
      isOpenModalResendEmail: false
    })
  }
  getListPatient = () => {
    const webHeader = {
      "token": this.state.token
    };
    const webRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiDashboardItemCallId = webRequestMessage.messageId;
    webRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      webConfigJSON.dashboardGetUrl
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(webHeader)
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      webConfigJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(webRequestMessage.id, webRequestMessage);
    return true
  }
  handleNavigatePinGenerator = () => {
    this.props.navigation.navigate("Cfpingenerator")
  }
  handleEditPatient = (patientId: string) => {
    this.props.navigation.navigate("CfpingeneratorEdit", {patientId})
  }
  handleHoverHeader = (event: React.MouseEvent<HTMLButtonElement>, id: string) => {
      this.setState({
        isOpenPopoverInfo: true,
        anchorEl: event.currentTarget,
        currentHoverId: id
      })
    };
  handleMouseLeaveHeader = () => {
      this.setState({
        isOpenPopoverInfo: false,
        anchorEl: null
      })
    };
    handleFilterActivePatient = (listOriginData: IPatient[]) => {
      let newDashboardData = [...listOriginData]
        if(newDashboardData.length > 0){
          newDashboardData = newDashboardData.filter((patient: IPatient) => patient.patient_status === true )
        }
        return newDashboardData
       
    }
    handleChangeSwitch = (event: React.ChangeEvent<HTMLInputElement>) => {
        const selectedStatus = event.target.checked
        this.setState({
          dashboardData: selectedStatus ? this.handleFilterActivePatient(this.state.originDashboardData) : [...this.state.originDashboardData],
        });
    }
    handleContactNumber = (contact: string) => {
      let value = contact
      value = value.replace(/\D/g, "");
      value = value.substring(0, 10);
    
      if (value.length > 3 && value.length <= 6)
        value = value.slice(0, 3) + "-" + value.slice(3);
      else if (value.length > 6)
        value = value.slice(0, 3) + "-" + value.slice(3, 6) + "-" + value.slice(6);
      return value;
    };
    onChangeTextInput = (searchText: string) => {
      const text = searchText.trim().toLowerCase()
      let newDashboardData = [...this.state.originDashboardData]
      if(text === "" && newDashboardData.length === 0){
        this.setState({
          dashboardData: newDashboardData,
        });
        return
      }
          newDashboardData = newDashboardData.filter((patient: IPatient) => {
            let founded = false
            if(patient.patient_name.toLowerCase().includes(text)){
              return true
            }
            patient.caregivers.forEach(caregiver => {
              if(caregiver.caregiver_name.toLowerCase().includes(text)) {
                founded = true
                return
              }
              if(caregiver.caregiver_email.toLowerCase().includes(text)) {
                founded = true
                return
              }
              if(caregiver.caregiver_phone_number.toLowerCase().includes(text)) {
                founded = true
              }
            });
            return founded
          })
        this.setState({
          dashboardData: newDashboardData,
        });
    }
    requestSort = (key: string) => {
      let direction: 'asc' | 'desc' = 'asc';
      const { sortConfig } = this.state;
  
      if (sortConfig && sortConfig.key === key && sortConfig.direction === 'asc') {
        direction = 'desc';
      }
      this.setState({ sortConfig: { key, direction } });
    };
    handleSort = (aValue: any, bValue: any, direction: "desc" | "asc") => {
      if (aValue !== undefined && bValue !== undefined) {
        if (aValue < bValue) {
          return direction === 'asc' ? -1 : 1;
        }
        if (aValue > bValue) {
          return direction === 'asc' ? 1 : -1;
        }
      }
      return 0;
    }
    getSortedData = () => {
      const { sortConfig, dashboardData } = this.state;
  
      if (sortConfig !== null) {
        const { key, direction } = sortConfig;
  
        return [...dashboardData].map(patient => {
          return {
            ...patient,
            caregivers: [...patient.caregivers].sort((a, b) => {
              const caregiverKey = key.split('.')[1] as keyof IPatientCaregiver;
              const aValue = a[caregiverKey];
              const bValue = b[caregiverKey];
              return this.handleSort(aValue, bValue, direction)
            })
          };
        }).sort((a, b) => {
          const patientKey = key as keyof IPatient;
          const aValue = a[patientKey];
          const bValue = b[patientKey];
         return this.handleSort(aValue, bValue, direction)
        });
      }
      return dashboardData;
    };
  // Customizable Area End
}
