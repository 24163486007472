import React, { useState } from "react";
import { 
  Box, 
  Button,
  Collapse,
  Stack, 
  Table, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableBody, 
  TableRow, 
  Typography 
} from "@mui/material";
import ExpandMore from '@mui/icons-material/ExpandMore';
import { styled } from "@mui/system";
import parse, { domToReact, Element, HTMLReactParserOptions } from "html-react-parser";

import ContentBreadcrumbs from "./ContentBreadcrumb.web";
import OverviewSection from "./OverviewSection.web";
import PlainTextItem from "./PlanTextItem.web";
import { SectionListLayout } from "./CustomContainer.web";

import { IContentData } from "../../blocks/contentmanagement/src/ContentManagementController";

const CustomTableCell = styled(TableCell)({
  width: '50%',
  border: '1px solid rgba(124, 142, 118, 0.2)',
  borderCollapse: 'separate',
  padding: '10px 20px',
  fontFamily: 'Lato',
  fontWeight: '400',
  fontSize: '22px',
  lineHeight: '26.4px',
  color: '#343C32'
})

const AccordionSummary = styled(Button)({
  width: '100%',
  borderRadius: 0,
  display: 'block',
  padding: '10px 20px',
  textAlign: 'left',
  textTransform: 'none',
  color: '#343C32'
})

const Heading = styled(Typography)({
  fontFamily: 'Lato',
  fontWeight: '700',
  lineHeight: '32px',
  color: '#586554'
})

const webStyles = {
  verticalBorderedBox: {
    borderTop: '1px solid rgba(124, 142, 118, 0.2)',
    borderBottom: '1px solid rgba(124, 142, 118, 0.2)',
  },
  dataMobileContainer: { width: 'calc(100% + 32px)', mx: '-16px' }
}

const getParsingOption = (listItemIcon?: JSX.Element | string) => {
  const parsingOptions: HTMLReactParserOptions = {
    trim: true,
    replace(domNode) {
        if (!(domNode instanceof Element)) return;
        const replacementByName: { [key: string]: JSX.Element } = {
          "ul": <Stack direction="column" spacing={1.5}>{domToReact(domNode.children, parsingOptions)}</Stack>,
          "li": <PlainTextItem icon={listItemIcon}>{domToReact(domNode.children)}</PlainTextItem>,
        };
        return replacementByName[domNode.name] || <>{domToReact(domNode.children, parsingOptions)}</>;
    },
  }
  return parsingOptions
}

const CustomTable = ({ heads, rows }: { heads: string[]; rows: string[][] }) => (
  <TableContainer component={Box}>
    <Table sx={{ minWidth: 'min-content' }}>
      <TableHead>
        <TableRow>
          {heads.map((head, index) => (
            <CustomTableCell key={index}>
              <Heading sx={{ fontSize: { xs: '20px', md: '26px' } }}>{parse(head)}</Heading>
            </CustomTableCell>
          ))}
        </TableRow>
      </TableHead>
      {rows.length > 0 && <TableBody>
        {rows.map((row, index) => (
          <TableRow key={index}>
            {row.map((cell, index) => (<CustomTableCell key={index} sx={{ p: 2.5 }}>{parse(cell, getParsingOption())}</CustomTableCell>))}
          </TableRow>
        ))}
      </TableBody>}
    </Table>
  </TableContainer>
)

const Accordion = ({ summary, detailHeading, details }: { summary: string; detailHeading: string; details: string }) => {
  const [isCollapse, setIsCollapse] = useState(true);

  return (
    <Box sx={[!isCollapse && { pb: '10px' }, webStyles.verticalBorderedBox]}>
      <Box onClick={() => setIsCollapse(!isCollapse)} component={AccordionSummary}>
        {parse(summary, getParsingOption(isCollapse ? <ExpandMore /> : undefined))}
      </Box>
      <Collapse in={!isCollapse} timeout="auto" unmountOnExit>
        <Box sx={{ p: '10px 40px' }}>
          <Heading sx={{ fontSize: { xs: '15px', md: '26px' } }}>{parse(detailHeading)}</Heading>
          {parse(details, getParsingOption())}
        </Box>
      </Collapse>
    </Box>
  )
}

const AccordionGroup = ({ heads, rows }: { heads: string[]; rows: string[][] }) => (
  <Stack direction="column">
    <Box sx={{ padding: '10px 20px', ...webStyles.verticalBorderedBox }}>
      <Heading sx={{ fontSize: { xs: '20px', md: '26px' } }}>{parse(heads[0])}</Heading>
    </Box>
    {rows.length > 0 && rows.map((row, index) => (
      <Accordion key={index} summary={row[0]} detailHeading={heads[1]} details={row[1]} />
    ))}
  </Stack>
)

const WhatToExpectWhenDying = ({ data }: { data: IContentData }) => {
  const { attributes } = data;
  const { title, terms } = attributes;

  const extractTableData = (htmlString: string) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, 'text/html');

    // Extract table rows
    const rows = Array.from(doc.querySelectorAll('table tr'));
    const extractedData = rows.map(row => Array.from(row.querySelectorAll('td, th')).map(cell => (cell && cell?.innerHTML && cell?.innerHTML?.trim()) || ''));

    return(extractedData);
  }

  return (
    <>
      {/* <ContentBreadcrumbs stacks={`Dashboard/What to expect when dying/${timeline} Before Death`} onNavigate={onNavigate} /> */}
      <SectionListLayout>
        <OverviewSection 
          name={parse(title?.title)}  
          shortDesc={title?.desc && title?.desc !== '' && parse(title?.desc)} 
          images={[]} 
          annotation={
            terms.length > 0 && 
              <Stack direction="column" sx={{ gap: { xs: 5 } }}>
                {terms.map((term, index) => {
                  const data = extractTableData(term.definition);
                  return (
                    <Box key={index}>
                      <Box sx={{ display: { xs: 'none', md: 'block' } }}>
                        <CustomTable heads={data[0]} rows={data.slice(1)} />
                      </Box>
                      <Box sx={{ display: { xs: 'block', md: 'none' }, ...webStyles.dataMobileContainer }}>
                        <AccordionGroup heads={data[0]} rows={data.slice(1)} />
                      </Box>
                    </Box>
                  )}
                )}
              </Stack>
          }
          containerStyle={{ py: { xs: 2.5, md: 5 } }}
        />
      </SectionListLayout>
    </>
  );
}

export default WhatToExpectWhenDying;
