import React, { useEffect, useState, Children } from "react";
import { Box, Stack } from "@mui/material";
import { styled } from '@mui/system';
import parse, { domToReact, Element, HTMLReactParserOptions } from 'html-react-parser';

import OverviewSection from "./OverviewSection.web";
import PlainTextItem from "./PlanTextItem.web";
import { OrderedListItem } from "./ItemWithLetter.web";
import ItemWithImage from "./ItemWithImage.web";
import { CustomSectionContainer, SectionListLayout } from "./CustomContainer.web";
import { countChildren, extractTextNodeContent } from "./ReactHtmlParser";

import { IContentData, ISectionData, ITermDetail } from "../../blocks/contentmanagement/src/ContentManagementController";

const webStyles = {
  itemWithImageBg: {
    borderRadius: '8px', 
    border: 'none',
    background: 'transparent',
    padding: { xs: 0 },
  },
  normalText: {
    fontSize: { xs: '16px', md: '22px' }, 
    lineHeight: { xs: '19.2px', md: '26.4px' },
    '& strong': {
      fontSize: { xs: '20px', md: '22px' }, 
      lineHeight: { xs: '24px', md: '26.4px' },
    }
  }
}

const NormalText = styled(Box)({
  fontWeight: '400',
  fontFamily: 'Lato',
  color: '#343C32',
  '& p': {
    margin: 0
  },
  '& p:not(:last-child)': { mb: 1.25 }
});
const HeadingContainer = styled(Box)({
  fontWeight: '700',
  fontFamily: 'Lato',
  color: '#586554',
  '& p': {
    margin: 0
  }
});
const Image = styled('img')({
  height: '120px',
  objectFit: 'contain'
});


const Bathing = ({ data }: { data: IContentData }) => {
  const { attributes } = data;
  const { title, terms } = attributes;

  const options: HTMLReactParserOptions = {
    replace(domNode) {
      if (!(domNode instanceof Element)) return;
      const replacementByName: { [key: string]: JSX.Element } = {
        "ul": domNode.next && domNode.next instanceof Element && domNode.name === 'p'
          ? 
            <>
              <Stack direction="column" sx={{ gap: 1.5 }}>
                {domToReact(domNode.children, options)}
              </Stack>
              <NormalText>{domToReact(domNode.next?.children)}</NormalText>
            </>
          : 
            (
              <Stack direction="column" sx={{ mt: 1.25, gap: '12px' }}>
                {domToReact(domNode.children, options)}
              </Stack>
            ),
        "li": <PlainTextItem>{domToReact(domNode.children, options)}</PlainTextItem>,
        "p": <NormalText sx={webStyles.normalText}>{domToReact(domNode.children)}</NormalText>
      };
      if (!replacementByName[domNode.name]) return;
      return replacementByName[domNode.name];
    }
  }

  const [sectionList, setSectionList] = useState<ISectionData[]>([]);

  useEffect(() => {
    if (terms) {
      terms.sort((a, b) => a.id - b.id); 
      const groupedData: { [key: string]: ITermDetail[] } = {};
      terms.forEach(term => {
        const key = term.term;
        if (!groupedData[key]) {
          groupedData[key] = [];
        }
        groupedData[key].push(term);
      });
      const sections = Object.entries(groupedData).map(group => {
        if (group[1].length > 1) {
          return {
            name: group[0],
            definition: '',
            images: [],
            children: group[1].map(each => {
              return { definition: each.definition, images: each.images }
            })
          }
        }
        return {
          id: group[1][0].id,
          name: group[0],
          definition: group[1][0].definition,
          images: group[1][0].images,
        }
      })
      setSectionList(sections)
    }
  }, [terms]);

  return (
    <SectionListLayout>
      <OverviewSection 
        images={title?.images.map(image => image.url) || []} 
        name={parse(title?.title)} 
        shortDesc={title?.desc && title?.desc !== '' && parse(title?.desc)}
        containerStyle={{ backgroundColor: 'transparent' }} 
      />
      <CustomSectionContainer containerStyle={{ py: 2.5 }} contentStyle={{ gap: { xs: 2, md: 2.5 } }}>
        {sectionList
          .filter(section => section.id && (section.name === '' || countChildren(parse(section.definition)) === 1 || section.images.length > 0))
          .map((section, index) => (
            <CustomSectionContainer
              key={index}
              withHeading={section.name !== '' && section.images.length < 1}
              heading={parse(section.name)}
              containerStyle={{ p: { xs: 0 } }}
              contentStyle={{ mt: countChildren(parse(section.definition)) === 1 && section.images.length < 1 ? 1.25 : 0 }}
            >
              {section.images.length > 0 
                ? 
                  <Stack direction="row" sx={{ gap: 1.25, alignItems: 'center', flexWrap: 'wrap' }}>
                    <Image 
                      src={section.images[0].url} 
                      alt={section.name} 
                      sx={{ width: { xs: '80px', md: '120px' }, height: { xs: '80px', md: '120px' } }}
                    />
                    <Stack direction="column" sx={{ gap: '6px', flexBasis: { xs: '100%', md: 'calc(100% - 130px)' } }}>
                      <HeadingContainer sx={{ fontSize: { xs: '20px', md: '26px' } }}>{parse(section.name)}</HeadingContainer>
                      <NormalText sx={{ fontSize: { xs: '16px', md: '22px' }, lineHeight: { xs: '19.2px', md: '26.4px' } }}>{parse(section.definition)}</NormalText>
                    </Stack>
                  </Stack> 
                : parse(section.definition, options)
              }
            </CustomSectionContainer>
          ))
        }
      </CustomSectionContainer>
      {sectionList
        .filter(section => !section.id || (section.id && section.name !== '' && countChildren(parse(section.definition)) > 1 && section.images.length === 0))
        .map((section, index) => {
          if (section.children) {
            return (
              <CustomSectionContainer 
                key={index}
                containerStyle={{ py: 2.5 }}
                withHeading={section.name !== ''}
                heading={parse(section.name)}
                headingStyle={{ px: { xs: 0, md: 2.5 } }}
                contentStyle={{ px: { xs: 0, md: 2.5 }, mt: '6px' }}
              >
                {section.children.map((child, idx) => {
                  if (child.images.length === 0) {
                    return (
                      <NormalText key={idx} sx={{ fontSize: { xs: '16px', md: '22px' }, lineHeight: { xs: '19.2px', md: '26.4px' } }}>
                        {parse(child.definition)}
                      </NormalText>
                  )}
                  return (
                    <>
                      <OrderedListItem 
                        key={idx} 
                        order={(idx).toString()}
                        styles={[countChildren(parse(child.definition)) > 1 && { alignItems: 'start' }, { display: { xs: 'none', md: 'flex' } }]}
                      >
                        <ItemWithImage 
                          image={child.images[0]?.url}
                          imageSize={120} 
                          text={parse(child.definition, options)}
                          style={[webStyles.itemWithImageBg, countChildren(parse(child.definition)) > 1 && { alignItems: 'start' }]}
                          textStyle={{ height: { xs: 'fit-content' }}}
                        />
                      </OrderedListItem>
                      <Stack key={`mobile-${idx}`} direction="column" sx={{ display: { xs: 'flex', md: 'none' }, gap: 2.5 }}>
                        <OrderedListItem 
                          key={idx} 
                          order={(idx).toString()}
                          styles={{ flexWrap: { xs: 'nowrap' } }}
                          orderContainerStyles={{ flexBasis: { xs: 0 } }}
                          >
                            <Image 
                              src={child.images[0]?.url || ''} 
                              alt={`Desciption Image ${idx}`} 
                              sx={{ width: { xs: '80px', md: '120px' }, height: { xs: '80px', md: '120px' } }}
                            />
                        </OrderedListItem>
                        <NormalText sx={{ fontSize: { xs: '16px', md: '22px' }, lineHeight: { xs: '19.2px', md: '26.4px' } }}>
                          {parse(child.definition, options)}
                        </NormalText>
                      </Stack>
                    </>
                   
                )})}
              </CustomSectionContainer>
            );
          }
          return (
            <CustomSectionContainer
              key={index}
              withHeading={extractTextNodeContent(section.name).length > 1}
              heading={parse(section.name)}
              containerStyle={{ p: extractTextNodeContent(section.name).length > 1 ? { xs: '20px 16px', md: '40px 150px' } : { xs: '20px 16px', md: '40px 130px' } }}
            >
              {parse(section.definition, options)}
            </CustomSectionContainer>
          );
        })
      }
    </SectionListLayout>
  );
}

export default Bathing;
