import React from "react";

// Customizable Area Start
import { Box, Typography, Button, styled } from "@mui/material";
import { lighten } from '@mui/system';
// Customizable Area End

import TermsConditionsController, {
  Props,
  configJSON,
  ITermsConds,
} from "./TermsConditionsController";

export default class TermsConditions extends TermsConditionsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <ModalContainer>
        <ModalHeader>
          {this.modalHeader}
        </ModalHeader>
        <ModalContentContainer>
          {this.state.isLoading ? this.loadingText :
            <ConditionsText dangerouslySetInnerHTML={{ __html: this.modalConditions }}/>
          }
        </ModalContentContainer>
        <ModalControlsContainer>
          <CloseButton onClick={this.onCancelClick}>{this.cancelButtonText}</CloseButton>
        </ModalControlsContainer>
      </ModalContainer>
    )
    // Customizable Area End
  }
}

// Customizable Area Start
const ModalContainer = styled(Box)({
  display: "flex",
  flex: 1,
  flexDirection: "column",
  width: "600px",
  borderRadius: "8px",
  borderBottomRightRadius: "32px",
  overflow: "hidden",
  '@media (max-width: 600px)' : {
    width: "343px"
    }
})

const ModalHeader = styled(Typography)({
  padding: "24px 40px 24px 40px",
  borderBottom: "1px solid #D6DCD5",
  fontFamily: "Lato",
  fontSize: "24px",
  fontWeight: 700,
  lineHeight: "32px",
  color: "#343c32",
  '@media (max-width: 600px)' : {
    fontSize: "20px",
    padding: "24px 16px 24px 16px",
    }
})

const ModalContentContainer = styled(Box)({
  flex: 1,
  padding: "40px",
  overflowY: "auto",
  maxHeight: '288px',
  '@media (max-width: 600px)' : {
    padding: "32px 16px 32px 16px",
    }
})

const ConditionsText = styled(Typography)({
  fontFamily: "Lato",
  fontSize: "16px",
  fontWeight: 400,
  lineHeight: "24px",
  marginBottom: '8px',
  color: "#343C32"
})

const ModalControlsContainer = styled(Box)({
  display: "flex",
  padding: "30px 16px 30px 0px",
  borderTop: "1px solid #D6DCD5",
  justifyContent: "end",
})

const CloseButton = styled(Button)({
  backgroundColor: "#7C8E76",
  padding: "10px 16px 10px 16px",
  width: "100px",
  borderRadius: "8px",
  fontFamily: "Lato",
  fontSize: "16px",
  fontWeight: 700,
  color: "#FFFFFF",
  textTransform: "capitalize",
  "&:hover": {
      cursor: "pointer",
      backgroundColor: "#7C8E76",
      color: "#FFFFFF",
    }
});
// Customizable Area End
