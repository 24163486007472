import React, { useEffect, useState } from "react";
import { Box, Grid, Stack, Typography } from "@mui/material";
import { styled } from "@mui/system";
import parse, { domToReact, Element, HTMLReactParserOptions } from "html-react-parser";

import OverviewSection from "./OverviewSection.web";
import { OrderedListItem } from "./ItemWithLetter.web";
import PlainTextItem from "./PlanTextItem.web";
import ItemWithImage from "./ItemWithImage.web";
import { CustomSectionContainer, SectionListLayout } from "./CustomContainer.web";

import { IContentData, IImage, ITermDetail } from "../../blocks/contentmanagement/src/ContentManagementController";

const webStyles = {
  orderedItemContent: {
    fontFamily: 'Lato',
    fontSize: '22px',
    lineHeight: '26.4px',
    color: '#343C32',
  }
}

const CustomLink = styled(Box)({
  borderRadius: '8px',
  border: '2px dashed #F87171',
  backgroundColor: '#F7E2E2',
  padding: '10px 20px',
  cursor: 'pointer'
})

const NormalText = styled(Typography)({
  fontFamily: 'Lato',
  fontSize: '16px',
  lineHeight: '19.2px',
  color: '#343C32',
  '@media (min-width: 900px)': {
    fontSize: '22px',
    lineHeight: '26.4px',
  }
})

export interface ISectionData  {
  id?: number;
  name: string;
  definition: string;
  images: IImage[];
  children? : { definition: string; images: IImage[] }[];
}

const SymptomARD = ({ data }: { data: IContentData }) => {
  const { attributes } = data;
  const { title, terms } = attributes;

  const parsingOptions: HTMLReactParserOptions = {
    trim: true,
    replace(domNode) {
      if (!(domNode instanceof Element)) return;
    
      const replacementByName: { [key: string]: JSX.Element } = {
        "ul": !domNode.parent 
          ? <>{domToReact(domNode.children, parsingOptions)}</> 
          : <Stack direction="column">{domToReact(domNode.children, parsingOptions)}</Stack>,
        "li": !domNode.children.some(child => child instanceof Element && child.name === 'div') 
          ? <PlainTextItem>{domToReact(domNode.children, parsingOptions)}</PlainTextItem>
          : renderOrderedListItem(domNode),
      };

      if (!replacementByName[domNode.name]) return;
  
      return replacementByName[domNode.name];
    }
  }
  const parsingOrderedListItemOption: HTMLReactParserOptions = {
    ...parsingOptions,
    replace(domNode) {
      if (!(domNode instanceof Element)) return;
  
      const replacementByName: { [key: string]: JSX.Element } = {
        "ul":<Stack direction="column" sx={{ gap: 2.5, px: { xs: 0, md: 2.5 } }}>{domToReact(domNode.children, parsingOrderedListItemOption)}</Stack>,
        "li": !domNode.children.some(child => child instanceof Element && child.name === 'a') 
          ? <NormalText>{domToReact(domNode.children, parsingOrderedListItemOption)}</NormalText>
          : <CustomLink sx={{ ...webStyles.orderedItemContent, fontSize: { xs: '16px', md: '22px' }, lineHeight: { xs: '19.2px', md: '26.4px' } }}>
              {domToReact(domNode.children, parsingOrderedListItemOption)}
            </CustomLink>,
      };
  
      if (!replacementByName[domNode.name]) return;
  
      return replacementByName[domNode.name];
    },
  }
  
  const [sectionList, setSectionList] = useState<ISectionData[]>([]);

  useEffect(() => {
    if (terms) {
      terms.sort((a, b) => a.id - b.id); 
      const groupedData: { [key: string]: ITermDetail[] } = {};
      terms.forEach(term => {
        const key = term.term;
        if (!groupedData[key]) {
          groupedData[key] = [];
        }
        groupedData[key].push(term);
      });
      const sections = Object.entries(groupedData).map(group => {
        if (group[1].length > 1) {
          return {
            name: group[0],
            definition: '',
            images: [],
            children: group[1].map(each => {
              return { definition: each.definition, images: each.images }
            })
          }
        }
        return {
          id: group[1][0].id,
          name: group[0],
          definition: group[1][0].definition,
          images: group[1][0].images,
        }
      })
      setSectionList(sections)
    }
  }, [terms]);

  const renderOrderedListItem = (node: Element) => {
    if (node.children.some(child => child instanceof Element && child.name === 'div')) {
      const orderNode = node.children.find(child => child instanceof Element && child.name === 'div');
      if (orderNode) {
        const order = orderNode instanceof Element && domToReact(orderNode.children);
        return (
          <OrderedListItem order={order || ''}>
            {domToReact(node.children.filter(child => child instanceof Element && child.name !== 'div'), parsingOrderedListItemOption)}
          </OrderedListItem>
        )
      }
    }
    return <></>
  }

  return (
    <SectionListLayout>
      <OverviewSection 
        images={title?.images.map(image => image.url) || []} 
        name={parse(title?.title)} 
        shortDesc={title?.desc && title?.desc !== '' && parse(title?.desc)}
      />
      {sectionList.map((section, index) => {
        if (section.children && section.children.length > 0) {
          return (
            <CustomSectionContainer
              key={index} 
              withHeading={section.name !== ''}
              heading={parse(section.name)}
              headingStyle={{ textAlign: 'center' }}
            >
              <Grid container spacing={2.5}>
                {section.children?.map((child, index) => (
                  <Grid item key={index} xs={12} md={6}>
                    <ItemWithImage image={child.images[0].url} text={parse(child.definition)} />
                  </Grid>
                ))}
              </Grid>
            </CustomSectionContainer>
          )
        }
        return (
          <CustomSectionContainer
            key={index} 
            withHeading={section.name !== ''}
            heading={parse(section.name)}
            containerStyle={{ py: 2.5 }}
          >
            {parse(section.definition, parsingOptions)}
          </CustomSectionContainer>
        );
      }
      )}
    </SectionListLayout>
  );
}

export default SymptomARD;
