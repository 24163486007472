import React, { useEffect, useState } from "react";
import { Box, Grid, Divider, Stack } from "@mui/material";
import { styled, SxProps } from '@mui/system';
import parse, { domToReact, Element, HTMLReactParserOptions } from "html-react-parser";

import OverviewSection from "./OverviewSection.web";
import PlainTextItem from "./PlanTextItem.web";
import ItemWithImage from "./ItemWithImage.web";
import { OverviewAnnotation } from "./MethodOverviewSection.web";
import { CustomSectionContainer, SectionListLayout, SubcatContainer } from "./CustomContainer.web";

import { IContentData, ISectionData, ITermDetail } from "../../blocks/contentmanagement/src/ContentManagementController";

const webStyles = {
  inlineFlexItem: {
    marginTop: 0,
    marginBottom: 0,
    borderRadius: '8px', 
    padding: '40px 20px', 
  },
  noticeContainer: {
    marginTop: { xs: 0, lg: '20px' },
    marginBottom: 0, 
    backgroundColor: '#F0E5FF', 
    border: '2px dashed #6200EA', 
    py: '20px'  
  }
}

const SectionTitleContainer = styled(Box)({
  fontWeight: '700',
  fontFamily: 'Lato',
  color: '#343C32',
  '& p': {
    margin: 0
  }
});
const LightSectionTitle = styled(SectionTitleContainer)({
  color: '#586554',
});
const BaseTextContainer = styled(SectionTitleContainer)({
  fontSize: '22px', 
  fontWeight: '400',
});
const BenefitCard = styled(Stack)({
  alignItems: 'center',
  backgroundColor: '#FFF',
  padding: '20px 0',
  border: "1px solid #D6DCD5", 
  borderRadius: 8,
  height: '100%'
});
const DescImg = styled('img')({
  objectFit: 'contain'
});

const UnderstandingOpioid = ({ data }: { data: IContentData }) => {
  const { attributes } = data;
  const { title, terms } = attributes;

  const getParsingOptions = (listItemStyles?: { style: SxProps; textStyle: SxProps }) => {
    const parsingOptions: HTMLReactParserOptions = {
      trim: true,
      replace(domNode) {
        if (!(domNode instanceof Element)) return;
        const replacementByName: { [key: string]: JSX.Element } = {
          "ul": <>{domToReact(domNode.children, parsingOptions)}</>,
          "li": <PlainTextItem {...listItemStyles }>{domToReact(domNode.children, parsingOptions)}</PlainTextItem>,
        };
  
        if (!replacementByName[domNode.name]) return;
    
        return replacementByName[domNode.name];
      }
    }

    return parsingOptions;
  }

  const [sectionList, setSectionList] = useState<ISectionData[]>([]);

  useEffect(() => {
    if (terms) {
      terms.sort((a, b) => a.id - b.id); 
      const groupedData: { [key: string]: ITermDetail[] } = {};
      terms.forEach(term => {
        const key = term.term;
        if (!groupedData[key]) {
          groupedData[key] = [];
        }
        groupedData[key].push(term);
      });
      const sections = Object.entries(groupedData).map(group => {
        if (group[1].length > 1) {
          return {
            name: group[0],
            definition: '',
            images: [],
            children: group[1].map(each => {
              return { definition: each.definition, images: each.images }
            })
          }
        }
        return {
          id: group[1][0].id,
          name: group[0],
          definition: group[1][0].definition,
          images: group[1][0].images,
        }
      })
      setSectionList(sections)
    }
  }, [terms]);

  return (
    <SectionListLayout>
      <OverviewSection 
        images={title?.images.map(image => image.url) || []}
        name={parse(title?.title)} 
        shortDesc={title?.desc && title?.desc !== '' && parse(title?.desc)}
        annotation={sectionList.length > 0 &&
          <Box sx={{ padding: { xs: "0 15px", lg:'0 130px' } }}>
            <LightSectionTitle mb={2.5} sx={{ fontSize: { xs: 20, lg: 26 } }}>{parse(sectionList[0]?.name)}</LightSectionTitle>
            <Grid container spacing={2.5} px={{ xs: 2, lg: 0 }}>
              {sectionList[0]?.children && sectionList[0]?.children.map((child, index) => (
                <Grid item key={index} xs={12} lg={4} sx={{ display: 'flex', flexDirection: 'column' }}>
                  <BenefitCard  direction="column" sx={{ gap: { xs: 1.5, md: 2.5 } }}>
                    {child.images.length > 0 
                      && (
                        <DescImg 
                          src={child.images[0].url} 
                          alt={child.images[0].filename} 
                          sx={{ width: { xs: '60px', md: '120px' }, height: 'auto' }}
                        />
                      )}
                    <BaseTextContainer textAlign="center" sx={{ fontSize: { xs: '20px', lg: '22px' } }}>{parse(child.definition)}</BaseTextContainer>
                  </BenefitCard>
                </Grid>
              ))}
            </Grid>
          </Box>
        }
        annotationStyle={{ marginTop: '20px' }}
      />

      <CustomSectionContainer variant="dark">
        {sectionList
          .slice(1)
          .filter(section => section.id && section.name !== '')
          .map((section, index) => (
            <Box key={index}>
              <OverviewAnnotation 
                title={parse(section.name)}
                image={section.images.length > 0 ? section.images[0]?.url : ''}
                content={parse(section.definition, getParsingOptions())}
              />
              {index === 0 && <Divider sx={{ marginTop: 5, backgroundColor: '#586554' }} />}
            </Box>
        ))}
      </CustomSectionContainer>

      {sectionList
        .slice(1)
        .filter(section => !section.id || section.name === '')
        .map((section, index) => {
          const currentList = sectionList.slice(1).filter(section => !section.id || section.name === '');
          if (section.children && currentList[index + 1] && currentList[index + 1]?.children) {
            return (
              <CustomSectionContainer 
                key={index}
                variant="light"
                containerStyle={{ paddingTop: 0, paddingBottom: 0 }} 
                contentStyle={{ flexDirection: 'row', flexWrap: 'wrap' }}
                headingStyle={{ fontSize: { xs: 20, lg: 26 } }} 
              >
                {[...Array(2).keys()].map((num) =>
                  <SubcatContainer
                    key={num}
                    withHeading
                    heading={parse(currentList[index + num].name)}
                    containerStyle={[
                      webStyles.inlineFlexItem,
                      num !== 0 && { border: '2px dashed #F87171' },
                      { flexBasis: { xs: '100%', lg: 0 }, backgroundColor: num === 0 ? '#F2F4F1' : '#FEF2F2' }
                    ]}
                    headingStyle={{ fontSize: { xs: 20, md: 26 } }}
                  >
                    {currentList[index + num].children && currentList[index + num].children?.map((child, idx) => (
                      <ItemWithImage 
                        key={idx} 
                        image={child?.images[0]?.url || ''} 
                        text={parse(child?.definition)} 
                        imageSize={120} 
                        style={{ p: { xs: 1.25 } }}
                      />))}
                  </SubcatContainer>)}
              </CustomSectionContainer>
            );
          }
          if (section.id && section.name === '') {
            return (
              <CustomSectionContainer
                key={index} 
                variant="light" 
                containerStyle={{ pt: 0, pb: { xs: '20px', lg: 0 } }}
                contentStyle={{ width: '100%' }}
              >
                <CustomSectionContainer containerStyle={webStyles.noticeContainer}>
                  {parse(section.definition, getParsingOptions({
                    style: { gap: { xs: '12px', lg: 0 } }, 
                    textStyle: { fontWeight: '700', fontSize: { xs: 16, lg: 22 } }
                  }))}
                </CustomSectionContainer>
              </CustomSectionContainer>
            );
          }
        })
      }
    </SectionListLayout>
  );
}

export default UnderstandingOpioid;
