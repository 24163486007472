export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const imgVisbility = require("../assets/visibility.svg");
export const imgVisbilityOff = require("../assets/visibilityOff.svg");
export const imgTrash = require("../assets/trash.svg");
export const hospiceLogoImage = require("../assets/hospice_logo.png");
export const headerTable = [
    {
        id: '1',
        text: "Date",
        width: "87px",
    },
    {
        id: '2',
        text: "Transaction",
        width: "142px",
        sortBy: "patient_name"
    },
    {
        id: '3',
        text: "Price",
        width: "132px",
        sortBy: "patient_status"
    },
    {
        id: '4',
        text: "Type",
         width: "13vw",
    },
    {
        id: '5',
        text: "Payment Type",
         width: "13vw",
    },
    {
        id: '6',
        text: "Status",
         width: "13vw",
    },
]