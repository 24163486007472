import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import  {  createRef } from 'react';
import {  FormikProps } from 'formik';
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";

export interface ICaregiver {
  id?: number | null,
  caregiver_id?: number | null,
  name: string,
  relation: string,
  phone: string,
  email: string,
  add_patient_id?: number
}
interface IPatient {
  patientName: string;
  statusOfPatient: "Active" | "Expired";
  caregivers: ICaregiver[];
}


// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  loading: boolean
  fetching: boolean
  caregivers: ICaregiver[]
  token: string
  currentPatientData: IPatient | null
  isOpenModalDelete: boolean
  isOpenModalConfirm: boolean
  caregiverSelected: ICaregiver | null
  successMessage: string
  isShowToast: boolean
  currentUserData: string
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CfpingeneratorController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiAddPatientCallId: string = "";
  apiEditPatientCallId: string = "";
  apiAddCaregiverCallId: string = "";
  apiGetCurrentParentCallId: string = "";
  apiDeleteCaregiverId: string = ""
  apiLogoutCaregiverId: string = ""
  formikRef = createRef<FormikProps<IPatient | { patientName: string; statusOfPatient: string; caregivers: ICaregiver[]; }>>();
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.formikRef = createRef();
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AuthTokenDataMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      loading: false,
      fetching: false,
      caregivers: [],
      token: "",
      currentPatientData: null,
      isOpenModalDelete: false,
      isOpenModalConfirm: false,
      caregiverSelected: null,
      successMessage: "",
      isShowToast: false,
      currentUserData: ""
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));
      let token = message.getData(getName(MessageEnum.SessionResponseToken));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value, token });
    }

    // Customizable Area Start
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token =  localStorage.getItem("token") ?? ""
    this.setState({ token: token }, () => {
        this.getCurrentPatient()
      });
    }
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      this.handleResponseMessage(message)
    }
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let message = new Message(getName(MessageEnum.AccoutLoginSuccess));
    message.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(message);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
  }
  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };
  showModalSuccess = (message: string) => {
    this.setState({
      isShowToast: true,
      successMessage: message
    })
    setTimeout(() => {
      this.setState({
        isShowToast: false
      })
    }, 3000);
  }
  handleResponseMessage = (message: Message) => {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    const errorReponse = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );
    if(errorReponse){
      this.setState({loading: false})
      return
    }
    if(responseJson.errors){
      this.handleErrorApiMessage(message)
      return
    }
    this.handlePatientApiMessage(message)
    this.handleCaregiverApiMessage(message)
  }
  handleCaregiverApiMessage = (message: Message) => {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (apiRequestCallId === this.apiDeleteCaregiverId) {
      const listCaregiver = this.formikRef.current?.values.caregivers
      const selectedCaregiver = this.state.caregiverSelected
      if(listCaregiver && selectedCaregiver){
        const newListCaregiver = listCaregiver.filter(caregiver => {
          return caregiver?.caregiver_id !== selectedCaregiver?.caregiver_id
        })
      this.formikRef.current?.setFieldValue('caregivers', newListCaregiver)
      this.setState({loading: false, caregiverSelected: null, isOpenModalDelete: false}, () => {
        this.showModalSuccess(`${selectedCaregiver.name} (Caregiver) deleted`)
      })
      }
    }
    if (apiRequestCallId === this.apiLogoutCaregiverId) {
      const selectedCaregiver = this.state.caregiverSelected
      this.setState({loading: false, caregiverSelected: null, isOpenModalConfirm: false}, () => {
        this.showModalSuccess(`${selectedCaregiver?.name} (Caregiver) logged out of all devices`)
      })
    }
    if (apiRequestCallId === this.apiAddCaregiverCallId) {
      this.setState({loading: false})
      if (responseJson) {
        this.showModalSuccess("Patient added")
        this.goToDashboard()
      }
    }
  }
  handleErrorApiMessage = (message: Message) => {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    this.setState({loading: false})
    if(responseJson.errors.phones || responseJson.errors.emails){
      const listCaregiver = this.formikRef.current?.values.caregivers!
      const listPhoneError = responseJson.errors.phones
      const listEmailError = responseJson.errors.emails
      listCaregiver.forEach((caregiver: ICaregiver, index: number) => {
        if(listPhoneError.includes(caregiver.phone)){
          this.formikRef.current?.setFieldError(`caregivers.${index}.phone`,configJSON.errorExistingCaregiver)
        }
        if(listEmailError.includes(caregiver.email)){
          this.formikRef.current?.setFieldError(`caregivers.${index}.email`,configJSON.errorExistingCaregiver)
        }
      })
      return
    }
    this.parseApiCatchErrorResponse(responseJson.errors);
  }
  handlePatientApiMessage = (message: Message) => {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (apiRequestCallId === this.apiAddPatientCallId) {
      if (responseJson && responseJson.data) {
        this.handleAddCaregivers(responseJson.data.attributes)
      }
    }
    if(apiRequestCallId === this.apiEditPatientCallId){
      const isEditing = !!this.props.navigation.getParam('patientId')
      sessionStorage.setItem("pingenerator_message", `Patient ${isEditing ? "edited" : "added"}`)
      this.setState({loading: false})
      this.goToDashboard()
    }
    if(apiRequestCallId === this.apiGetCurrentParentCallId){
      const patientResponse = responseJson.data.attributes
        this.setState({
          fetching: false,
          currentPatientData: {
            patientName: patientResponse.patient_name,
            statusOfPatient: patientResponse.is_active ? "Active" : "Expired",
            caregivers: patientResponse.caregivers.map((caregiver: any)=> {
              return {
                caregiver_id: caregiver.id,
                name: caregiver.caregiver_name,
                relation: caregiver.relation_to_patient,
                phone: caregiver.caregiver_phone_number,
                email: caregiver.caregiver_email,
                add_patient_id: caregiver.add_patient_id
              }
            })
          }
        })
      
    }
  }
  handleCallApiPatchPatient = (patientEdit: string, method: 'add' | 'update') => {
    const patientId = this.props.navigation.getParam('patientId')
    const path = patientId ? configJSON.addPatientApiEndPoint + `/${patientId}` :  configJSON.addPatientApiEndPoint
    const header = {
      "token": this.state.token,
      "Content-Type": configJSON.exampleApiContentType,
    };

    const httpBody = patientEdit

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiEditPatientCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      path
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      httpBody
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method === 'add' ? configJSON.exampleAPiMethod : configJSON.patchAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

  }
  goToDashboard = () => {
    this.props.navigation.navigate("Dashboard")
  }
  handleAddCaregivers = ({id} : {id: string}) => {
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      "token": this.state.token
    };
    const newCaregiver = this.state.caregivers.map(item => {
      return {
        caregiver_name: item.name,
        relation_to_patient: item.relation,
        caregiver_email: item.email,
        caregiver_phone_number: item.phone.replace(/-/g, "")
      }
    })
    const httpBody = {
      caregivers: newCaregiver
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiAddCaregiverCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.addCaregiverApiEndPoint + id + "/caregivers"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  getCurrentPatient = () => {
    const patientId = this.props.navigation.getParam('patientId')
    if(patientId){
      this.setState({
        fetching: true
      })
      const webHeader = {
        "token": this.state.token
      };
      const webRequestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.apiGetCurrentParentCallId = webRequestMessage.messageId;
      webRequestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.addPatientApiEndPoint + `/${patientId}`
      );
  
      webRequestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(webHeader)
      );
  
      webRequestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.validationApiMethodType
      );
      runEngine.sendMessage(webRequestMessage.id, webRequestMessage);
      return true
    }
  }
  handleSubmitForm = (patientName: string, statusOfPatient: string, caregivers: ICaregiver[]) => {
    const isEditing = !!this.props.navigation.getParam('patientId')
    if(isEditing){
      this.handleEditPatient(patientName, statusOfPatient, caregivers)
    }else{
      this.setState({loading: true})
      this.handleAddPatient(patientName, statusOfPatient, caregivers)
    }
  }
  handleAddPatient = (patientName: string, statusOfPatient: string, caregivers: ICaregiver[]) => {
    const patient = {
      patient_name: patientName,
      is_active: statusOfPatient=== "Active",
      caregivers_attributes:  caregivers.map((addedCaregiver: ICaregiver) => ({
        caregiver_name: addedCaregiver.name,
            caregiver_email: addedCaregiver.email,
            relation_to_patient: addedCaregiver.relation,
            caregiver_phone_number: addedCaregiver.phone
      }))
    }
    this.handleCallApiPatchPatient(JSON.stringify({patient}), 'add')
  }
  getCaregiverChanged = (oldCaregiver: ICaregiver, newCaregiver : ICaregiver) => {
    const editedCaregiver: any = {
      id: newCaregiver.caregiver_id
    }
    let changed = false
    const newCaregiverName = newCaregiver.name
    if(oldCaregiver.name !== newCaregiverName){
      editedCaregiver.caregiver_name = newCaregiverName
      changed = true
    }
    const newCaregiverEmail = newCaregiver.email
    if(oldCaregiver.email !== newCaregiverEmail){
      editedCaregiver.caregiver_email = newCaregiverEmail
      changed = true
    }
    const newCaregiverRelation =newCaregiver.relation
    if(oldCaregiver.relation !== newCaregiverRelation){
      editedCaregiver.relation_to_patient = newCaregiverRelation
      changed = true
    }
    const newCaregiverPhone = newCaregiver.phone
    if(oldCaregiver.phone !== newCaregiverPhone){
      editedCaregiver.caregiver_phone_number = newCaregiverPhone
      changed = true
    }
    return changed ? editedCaregiver : null
  }
  getListCaregiverChanged = (caregivers: ICaregiver[]) => {
    const originPatient =  {...this.state.currentPatientData}
    const listCaregiverEditing: any[] = []
    caregivers.forEach((editCaregiver: ICaregiver, index: number) => {
      if(editCaregiver.caregiver_id){
        const oldCaregiver = originPatient.caregivers?.find(findCaregiver => findCaregiver.caregiver_id === editCaregiver.caregiver_id)
        if(!oldCaregiver) return
        const changedCaregiver = this.getCaregiverChanged(oldCaregiver,editCaregiver)
        if(changedCaregiver !== null){
          listCaregiverEditing.push(changedCaregiver)
        }
      }else{
        listCaregiverEditing.push({
          caregiver_name: editCaregiver.name,
          caregiver_email: editCaregiver.email,
          relation_to_patient: editCaregiver.relation,
          caregiver_phone_number: editCaregiver.phone
        })
      }
    })
    return listCaregiverEditing
  }
  isEmpty = (obj: any) => {
    const listObject = Object.keys(obj)
    return listObject.length === 0;
  };
  
  handleEditPatient = (patientName: string, statusOfPatient: string, caregivers: ICaregiver[]) => {

    const patient: any = {}
    const originPatient =  {...this.state.currentPatientData}
    if(patientName !== originPatient.patientName){
      patient.patient_name = patientName
    }
    if(statusOfPatient !== originPatient.statusOfPatient){
      patient.is_active = statusOfPatient=== "Active"
    }
    const listCaregiverEditing: any[] = this.getListCaregiverChanged(caregivers)
    if(listCaregiverEditing.length > 0){
      patient.caregivers_attributes = [...listCaregiverEditing]
    }
    if(this.isEmpty(patient)){
      this.props.navigation.goBack()
      return
    }
    this.handleCallApiPatchPatient(JSON.stringify({patient}), 'update')
  }
  handleCloseModalDelete = () => {
    this.setState({
      isOpenModalDelete: false,
      caregiverSelected: null
    })
  }
  handleCloseModalLogout = () => {
    this.setState({
      isOpenModalConfirm: false,
      caregiverSelected: null
    })
  }
  handlePressedDeleteButton = (caregiver: ICaregiver, length: number, callBack?: () => void) => {
    if(length === 1) return
    if(caregiver?.caregiver_id){
      this.handleOpenModalDelete(caregiver)
    }else{
      callBack?.()
    }
  }
  handleOpenModalLogoutCaregiver = (caregiver: ICaregiver) => {
    this.setState({
      caregiverSelected: caregiver,
      isOpenModalConfirm: true
    })
  }
  handleOpenModalDelete = (caregiver: ICaregiver) => {
    this.setState({
      caregiverSelected: caregiver,
      isOpenModalDelete: true
    })
  }
  handleLogoutCaregiver = () => {
    this.setState({
      loading: true
    })
    const webHeader = {
      "token": this.state.token,
      "Content-Type": configJSON.exampleApiContentType,
    };
   
    const logoutCaregiver = this.state.caregiverSelected
    if(!logoutCaregiver){
      return false
    }
    const webRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiLogoutCaregiverId = webRequestMessage.messageId;
    webRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.logoutCaregiverEndpoint
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(webHeader)
    );
    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({
        id: logoutCaregiver.caregiver_id
      })
    );
    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteAPiMethod
    );
    runEngine.sendMessage(webRequestMessage.id, webRequestMessage);
    return true
  }
  handleDeleteAccount = () => {
    this.setState({
      loading: true
    })
    const webHeader = {
      "token": this.state.token
    };
   
    const deleteCaregiver = this.state.caregiverSelected
    if(!deleteCaregiver){
      return false
    }
    const webRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiDeleteCaregiverId = webRequestMessage.messageId;
    webRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.patientEndpoint + `/${deleteCaregiver.add_patient_id}/caregivers/${deleteCaregiver.caregiver_id}`
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(webHeader)
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteAPiMethod
    );
    runEngine.sendMessage(webRequestMessage.id, webRequestMessage);
    return true
  }
  getOpacity = (size: number) => {
    return size === 1 ? 0.5 : 1
  } 
  // Customizable Area End
}
