import React, { useEffect, useState } from 'react';
import { Box, Divider, Typography } from '@mui/material';
import { styled } from '@mui/material/styles'
import parse, { domToReact, Element, HTMLReactParserOptions } from 'html-react-parser';

import OverviewSection from './OverviewSection.web';
import PlainTextItem from './PlanTextItem.web';
import { OverviewAnnotation } from './MethodOverviewSection.web';
import { CustomSectionContainer, SectionListLayout, SubcatContainer } from './CustomContainer.web';
import{ checkTagExisted } from './ReactHtmlParser';

import { IContentData, ITermDetail, ISectionData } from '../../blocks/contentmanagement/src/ContentManagementController';

const webStyles = {
  heading: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    alignItems: 'center'
  }
}

const NormalText = styled(Typography)({
  fontWeight: '400',
  fontFamily: 'Lato',
  letterSpacing: '-0.5%',
  color: '#343C32',
  fontSize: '16px', 
  lineHeight: '19.2px',
  '@media (min-width: 900px)': { fontSize: '20px', lineHeight: '32px' }
});

export default function NauseaVomiting({ data }: { data: IContentData }) {
  const { attributes } = data;
  const { title, terms } = attributes;

  const parsingOptions: HTMLReactParserOptions = {
    trim: true,
    replace(domNode) {
      if (!(domNode instanceof Element)) return;
      const replacementByName: { [key: string]: JSX.Element } = {
        "ul": <>{domToReact(domNode.children, parsingOptions)}</>,
        "li": <PlainTextItem>{domToReact(domNode.children, parsingOptions)}</PlainTextItem>,
        "h6": <NormalText variant="h6" textAlign="center">{domToReact(domNode.children)}</NormalText>
      };

      if (!replacementByName[domNode.name]) return;
  
      return replacementByName[domNode.name];
    }
  }

  const [sectionList, setSectionList] = useState<ISectionData[]>([]);

  useEffect(() => {
    if (terms) {
      terms.sort((a, b) => a.id - b.id); 
      const groupedData: { [key: string]: ITermDetail[] } = {};
      terms.forEach(term => {
        const key = term.term;
        if (!groupedData[key]) {
          groupedData[key] = [];
        }
        groupedData[key].push(term);
      });
      const sections = Object.entries(groupedData).map(group => {
        if (group[1].length > 1) {
          return {
            name: group[0],
            definition: '',
            images: [],
            children: group[1].map(each => {
              return { definition: each.definition, images: each.images }
            })
          }
        }
        return {
          name: group[0],
          definition: group[1][0].definition,
          images: group[1][0].images,
        }
      })
      setSectionList(sections)
    }
  }, [terms]); 

  return (
    <SectionListLayout>
      <OverviewSection 
        images={title?.images.map(image => image.url) || []} 
        name={parse(title?.title)} 
        shortDesc={title?.desc && title?.desc !== '' && parse(title?.desc)}
        annotation={sectionList.length > 0 &&  
          <OverviewAnnotation 
            title={parse(sectionList[0].name)} 
            image={sectionList[0].images.length > 0 ? sectionList[0].images[0].url : ''}
            content={parse(sectionList[0].definition, parsingOptions)}
            layoutStyle={{ alignItems: 'center' }}
          />}
      />

      <CustomSectionContainer variant="dark">
        {sectionList
          .slice(1)
          .filter(section => !checkTagExisted(section.name, 'h6'))
          .map((section, index) => (
            <Box key={index}>
              <OverviewAnnotation 
                title={parse(section.name)}
                image={section.images.length > 0 ? section.images[0]?.url : ''}
                content={parse(section.definition, parsingOptions)}
              />
              {index === 0 && <Divider sx={{ marginTop: 5, backgroundColor: '#586554' }} />}
            </Box>
        ))}
      </CustomSectionContainer>

      <CustomSectionContainer contentStyle={{ flexDirection: 'row', flexWrap: 'wrap' }}>
        {sectionList
          .slice(1)
          .filter(section => checkTagExisted(section.name, 'h6'))
          .map((section, index) => 
            <SubcatContainer
              key={index}
              withHeading
              heading={parse(section.name, parsingOptions)}
              containerStyle={{ px: 2.5, width: { xs: '100%', md: 0 } }}
              headingStyle={webStyles.heading}
            >
              {parse(section.definition, parsingOptions)}
            </SubcatContainer>)}
      </CustomSectionContainer>
    </SectionListLayout>
  );
}