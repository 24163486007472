import React, { useEffect, useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import { styled } from '@mui/system';
import parse from "html-react-parser";

import ReactHtmlParser from "./ReactHtmlParser";
import PlainTextItem from "./PlanTextItem.web";
import { IContentData, ISectionData, ITermDetail } from "../../blocks/contentmanagement/src/ContentManagementController";

interface TypesOpioidMedProps {
  data: IContentData
}

export default function TypesOpioidMed({data }: TypesOpioidMedProps) {
  const { attributes } = data;
  const { title, terms } = attributes;

  const [sectionList, setSectionList] = useState<ISectionData[]>([]);

  useEffect(() => {
    if (terms) {
      terms.sort((a, b) => a.id - b.id); 
      const groupedData: { [key: string]: ITermDetail[] } = {};
      terms.forEach(term => {
        const key = term.term;
        if (!groupedData[key]) {
          groupedData[key] = [];
        }
        groupedData[key].push(term);
      });
      const sections = Object.entries(groupedData).map(group => {
        if (group[1].length > 1) {
          return {
            name: group[0],
            definition: '',
            images: [],
            children: group[1].map(each => {
              return { definition: each.definition, images: each.images }
            })
          }
        }
        return {
          name: group[0],
          definition: group[1][0].definition,
          images: group[1][0].images,
        }
      })
      setSectionList(sections)
    }
  }, [terms]);

  const renderList = (htmlString: string) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, 'text/html');
    const listItems = Array.from(doc.querySelectorAll('li'));
    if (listItems.length > 0) {
      return listItems.map((item, index) => (<PlainTextItem key={index}>{ReactHtmlParser(item.innerHTML)}</PlainTextItem>))
    } 
    return ReactHtmlParser(htmlString);
  }

  const getSectionArray = (originArray: ISectionData[]) => {
    const tempOriginArray = [...originArray]
    return tempOriginArray.splice(0, 4)
  }

  return (
   <Container>
      <LatoText sx={{fontWeight: "700", fontSize: { xs: '20px', md: '26px' }, textAlign: 'center', padding: '10px', '& p': { margin: 0 } }}>
        {parse(title.title)}
      </LatoText>
      <Grid container spacing={2.5}>
      {sectionList.length > 3 && getSectionArray(sectionList).map((section, index) => {
        return (
          <Grid item key={index} xs={12} md={6} sx={{ display: 'flex' }}>
            <ItemContainer sx={{ color: '#343C32', width: '100%', height: { xs: 'calc(100% - 20px)', md: 'calc(100% - 40px)' }, p: { xs: '10px 20px', md: 2.5 } }}>
                <LatoText sx={{ fontWeight: "700", fontSize: { xs: 20, md: 26 }, color: "#586554", '& p': { margin: 0 } }}>
                  {parse(section?.name)}
                </LatoText>
                {renderList(section.definition)}
            </ItemContainer>
      </Grid>)
      })}
   
      </Grid>
      {sectionList[4] && <ItemBottomContainer sx={{ color: '#343C32' }}>
       <DescImage src={sectionList[4].images[0].url} sx={{ width: { xs: '80px', md: '120px' } }} />
       <div style={{display: "flex", flexDirection: 'column', gap: 12}}>
       {renderList(sectionList[4].definition)}
        </div>
        </ItemBottomContainer>}
   </Container>
  );
}
const Container = styled(Box)({
  display: 'flex', flexDirection: 'column', gap: 20, paddingLeft: 130, paddingRight: 130,
  '@media (max-width: 1180px)' : {
    paddingLeft: 16, paddingRight: 16,
    }
});
const ItemBottomContainer = styled(Box)({
  display: 'flex',flex: 1,marginBottom: 30, flexDirection: 'row', background: "#FEF2F2", padding: 40, paddingLeft: 20, paddingRight: 20,columnGap: 20, borderRadius: 8, border: "2px dashed #F87171",
  '@media (max-width: 1180px)' : {
    flexDirection: 'column',
    rowGap: "35px"
    }
});
const ItemContainer = styled(Box)({
  display: 'flex', flexDirection: 'column', background: "#F2F4F1", padding: 10, paddingLeft: 20, paddingRight: 20, gap: 16, borderRadius: 8,
});
const LatoText = styled(Typography)({
  fontFamily: "Lato",
  color: "#343C32",
  fontWeight: "400"
});
const DescImage = styled('img')({
  objectFit: 'contain',
  height: 'auto'
})

