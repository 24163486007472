import React, { useEffect, useState } from 'react';
import { Box, Divider, Grid } from '@mui/material';
import parse, { domToReact, Element, HTMLReactParserOptions } from 'html-react-parser';

import OverviewSection from './OverviewSection.web';
import PlainTextItem from './PlanTextItem.web';
import ItemWithImage from './ItemWithImage.web';
import { OverviewAnnotation } from './MethodOverviewSection.web';
import { CustomSectionContainer, SectionListLayout, SubcatContainer } from './CustomContainer.web';
import { ISectionData } from './SymptomARD.web';

import { IContentData, ITermDetail } from "../../blocks/contentmanagement/src/ContentManagementController";

const webStyles = {
  subcatContainer: {
    marginTop: 0,
    marginBottom: 0,
    borderRadius: '8px', 
    padding: '40px 20px',
  }
}

function Psychotropic({ data }: { data: IContentData }) {
  const { attributes } = data;
  const { title, terms } = attributes;

  const options: HTMLReactParserOptions = {
    trim: true,
    replace(domNode) {
      if (!(domNode instanceof Element)) return;
      const replacementByName: { [key: string]: JSX.Element } = {
        "ul": <>{domToReact(domNode.children, options)}</>,
        "li": <PlainTextItem>{domToReact(domNode.children, options)}</PlainTextItem>,
      };

      if (!replacementByName[domNode.name]) return;
  
      return replacementByName[domNode.name];
    }
  }

  const [sectionList, setSectionList] = useState<ISectionData[]>([]);

  useEffect(() => {
    if (terms) {
      terms.sort((a, b) => a.id - b.id); 
      const groupedData: { [key: string]: ITermDetail[] } = {};
      terms.forEach(term => {
        const key = term.term;
        if (!groupedData[key]) {
          groupedData[key] = [];
        }
        groupedData[key].push(term);
      });
      const sections = Object.entries(groupedData).map(group => {
        if (group[1].length > 1) {
          return {
            name: group[0],
            definition: '',
            images: [],
            children: group[1].map(each => {
              return { definition: each.definition, images: each.images }
            })
          }
        }
        return {
          id: group[1][0].id,
          name: group[0],
          definition: group[1][0].definition,
          images: group[1][0].images,
        }
      })
      setSectionList(sections)
    }
  }, [terms]); 

  return (
    <SectionListLayout>
      <OverviewSection 
        images={title?.images.map(image => image.url) || []} 
        name={parse(title?.title)} 
        shortDesc={title?.desc && title?.desc !== '' && parse(title?.desc)}
        annotation={sectionList.length > 0 &&
          <OverviewAnnotation 
            title={parse(sectionList[0].name)} 
            image={sectionList[0].images.length > 0 ? sectionList[0].images[0].url : ''}
            content={parse(sectionList[0].definition, options)}
          />}
      />

      <CustomSectionContainer variant="dark">
        {sectionList
          .slice(1)
          .filter(section => section.id)
          .map((detail, index) => (
          <Box key={index}>
            <OverviewAnnotation 
              title={parse(detail.name)}
              image={detail.images.length > 0 ? detail.images[0]?.url : ''}
              content={parse(detail.definition, options)}
              sx={{ flexWrap: { xs: 'wrap', lg: 'nowrap' } }}
            />
            {index === 0 && <Divider sx={{ marginTop: 5, backgroundColor: '#586554' }} />}
          </Box>
        ))}
      </CustomSectionContainer>
      
      {sectionList
        .slice(1) 
        .map((section, index) => {
          const currentSectionList = sectionList.slice(1);
          if (section?.children && currentSectionList[index + 1] && currentSectionList[index + 1]?.children) {
            return (
              <CustomSectionContainer
                key={index} 
                containerStyle={{ paddingTop: 0, paddingBottom: { xs: 2.5, lg: 0 } }} 
                contentStyle={{ flexDirection: 'row', flexWrap: 'wrap' }} 
                variant="light"
              >
                {[...Array(2).keys()].map((num) => (
                  <SubcatContainer
                    key={num}
                    containerStyle={[
                      webStyles.subcatContainer,
                      {
                        flexBasis: { xs: '100%', md: 0 },
                        backgroundColor: num === 0 ? '#F2F4F1' : '#FEF2F2',
                        border: num !== 0 ? '2px dashed #F87171' : ''
                      }
                    ]}
                    withHeading
                    heading={parse(currentSectionList[index + num].name)}
                  >
                    <Grid container spacing={2.5}>
                      {currentSectionList[index + num]?.children && currentSectionList[index + num]?.children?.map((child, index) => (
                        <Grid key={index + num} item xs={12}>
                          <ItemWithImage image={child?.images[0]?.url || ''} text={parse(child?.definition)} />
                        </Grid>
                      ))}
                    </Grid>
                  </SubcatContainer>))}
            </CustomSectionContainer>
            )
          }
        })
      }  
    </SectionListLayout>
  );
}

export default Psychotropic;
 