import React from 'react';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Stack } from '@mui/material';

interface Props {
    open: boolean,
    handleCloseModal: () => void,
    headerTitle: string,
    firstLineDes: string,
    secondLineDes?: string,
}
export default function ModalThanks(props: Props) {
  return (
    <Modal
    open={props.open}
    onClose={props.handleCloseModal}
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description"
  >
    <Stack direction="column" sx={webStyles.modalContainer}>
      <Stack direction="row" sx={webStyles.modalHeader}>
        <Typography sx={webStyles.headerTitle}>{props.headerTitle}</Typography>
        <IconButton data-test-id="button-close-modal-thank" onClick={props.handleCloseModal}>
          <CloseIcon/>
        </IconButton>
      </Stack>
      <Stack direction="column" sx={{ border: '1px solid #D6DCD5', p: { xs: '40px 16px', md: 5 } }}>
        <Typography sx={webStyles.modalTextContent}>{props.firstLineDes}</Typography>
        <Typography sx={{ ...webStyles.modalTextContent, mt: 2 }}>{props.secondLineDes}</Typography>
      </Stack>
      <Stack direction="column" sx={webStyles.buttonModalContainer}>
        <Typography sx={{ ...webStyles.modalTextContent, fontWeight: '700', color: "#343C32"}}>Sent from</Typography>
        <Typography sx={{ ...webStyles.modalTextContent, color: "#586554" }}>Partner for Care</Typography>
      </Stack>
    </Stack>
  </Modal>
  );
}
const webStyles = {
    modalContainer: {
      background: "#fff",
      position: 'absolute' as 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 600,
      bgcolor: 'background.paper',
      borderRadius: 8,
      '@media (max-width: 899px)': {
        top: 'auto',
        bottom: '0',
        left: '0',
        transform: 'none',
        width: '100%',
        borderRadius: 0,
        borderTopRightRadius: '32px'
      }
    },
    buttonModalContainer: {
      alignItems: 'flex-start',
      paddingY: '30px',
      paddingLeft: '40px',
    },
    modalHeader: {
      alignItems: 'center', 
      justifyContent: 'space-between', 
      padding: '24px 16px 24px 40px',
    },
    headerTitle: {
      fontFamily: "Lato",
      fontSize: '24px',
      fontWeight: '700',
      color: "#586554"
    },
    modalTextContent: {
      fontFamily: "Lato",
      color: "#343C32",
      fontWeight: '500'
    },
    headerContainer: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: '8px',
      borderRadius: '4px',
      padding: '20px',
      background: '#FFFFFF'
    },
}