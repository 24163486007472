import React from 'react';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import Modal from '@mui/material/Modal';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { Stack } from '@mui/material';

const ToastContainer = styled('div')<{ width?: number; top?: number }>(
  ({ width, top }) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: 8,
    paddingRight: 16,
    width: width ?? 290,
    borderRadius: 8,
    position: 'absolute',
    top: top ?? 158,
    right: 36,
    boxShadow: '0px 6px 15px -3px #00000026',
    gap: '8px',
    background: '#FFFFFF',
    '@media (max-width: 600px)': {
      width: '80%',
      right: '5%',
      top: top ?? 80,
    },
  })
);

const MessageText = styled(Typography)({
  fontFamily: 'Inter',
  color: '#0F172A',
  wordBreak: 'break-word',
  overflowWrap: 'break-word',
});

export default function Toast({
  textMessage,
  isShow,
  width,
  mode,
  top,
  onClose,
}: {
  textMessage: string;
  isShow: boolean;
  width?: number;
  mode?: 'success' | 'error';
  top?: number;
  onClose?: () => void;
}) {
  const isSuccess = mode !== 'error';

  return (
    <Modal
            open={isShow}
            onBackdropClick={() => {onClose?.()}}
            hideBackdrop
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Stack direction="row" sx={{
                p: 1,
                pr: 2,
                width: { xs: 'fit-content', md: 'auto' },
                borderRadius: '8px',
                position: 'absolute',
                top: top ?? { xs: 40, md: 270 },
                right: { xs: 0, md: 30 },
                left: { xs: 0, md: 'auto' },
                boxShadow: '0px 6px 15px -3px #00000026',
                gap: 1,
                background: "#FFF",
                marginX: 'auto',
            }}>
                {isSuccess ? <CheckCircleIcon style={{ color: "#34D399" }} /> :<CancelIcon style={{ color: "#DC2626" }}  />}
                <Typography style={{ fontFamily: "Lato", color: "#343C32"}}>
                    {textMessage}
                </Typography>
            </Stack>
        </Modal>
  );
}
