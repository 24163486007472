// Customizable Area Start
import React from "react";
import HospiceHeader from "../../../components/src/HospiceHeader";
import {
    Box,
    Typography,
} from "@mui/material";
import { styled } from '@mui/material/styles'
import { Button } from '@builder/component-library';
import Subscriptionbilling2Controller, {
    Props,
} from "./Subscriptionbilling2Controller";
import { subscriptionExpiredImg } from './assets'

export default class Subscriptionbilling2 extends Subscriptionbilling2Controller {
    constructor(props: Props) {
        super(props);

    }
    renderHeader = () => {
        return <HospiceHeader
            navigation={this.props.navigation}
            data-test-id={"authorized-header"}
        />
    }


    render() {
        return (
            <Container>
                <LayoutContainer>
                    {this.renderHeader()}
                    <ContentContainer>
                        <img src={subscriptionExpiredImg} style={{ width: 260, height: 208 }} />
                        <DescriptionText  >
                            We've missed you! Resubscribe to continue enjoying all the benefits Partner for Care has to offer. Don’t miss out on helping provide Caregivers information they need to take care of their loved ones.
                        </DescriptionText>
                        <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>

                            <IntroButtonText >
                                Click below to get started!
                            </IntroButtonText>

                            <Button
                                text="Choose a Plan"
                                style={{ backgroundColor: "#7C8E76", width: 133, marginTop: '6px' }}
                                data-test-id={"button-submit-info"}
                                loading={this.state.loading}
                                onPress={this.goToSubscription}
                                textStyle={{ fontSize: 16, fontWeight: '700', color: '#fff', fontFamily: "Lato", textDecorationLine: 'underline' }}
                            />
                        </div>
                        <div style={{ display: 'flex', alignContent: 'center', gap: 3 }}>
                            <ContactUsText>
                                Need help?
                            </ContactUsText>
                            <ContactUsButton onClick={this.goToContactUs}>
                                Contact Us
                            </ContactUsButton>
                            <ContactUsText>
                                for support
                            </ContactUsText>
                        </div>
                    </ContentContainer>
                </LayoutContainer>
            </Container>
        );
    }
}


const DescriptionText = styled(Typography)({
    fontFamily: "Lato",
    fontWeight: "400",
    fontSize: 16,
    color: "#343C32",
    textAlign: 'center',
    width: '510px',
    "@media (max-width: 600px)": {
        width: "100%",

    },
});
const ContactUsText = styled(Typography)({
    fontFamily: "Lato",
    fontWeight: "400",
    fontSize: 16,
    color: "#343C32",
});
const ContactUsButton = styled(Typography)({
    fontFamily: "Lato",
    fontWeight: "700",
    textDecorationLine: 'underline',
    fontSize: 16,
    color: "#343C32",
    cursor: 'pointer'
});
const IntroButtonText = styled(Typography)({
    fontFamily: "Lato",
    fontWeight: "400",
    fontSize: 16,
    color: "#7C8E76",
    textAlign: 'center',
});

const LayoutContainer = styled(Box)({
    flex: 1,
    display: "flex",
    flexDirection: "column",
    background:"#F2F2F2",
    padding: "20px 20px 14px 20px",
    "@media (max-width: 600px)": {
        padding: "0px 0px 0px 0px",
    },
});
const Container = styled(Box)({
    width: "100%", height: "100%", display: "flex", flex: 1,
    overflowY: 'scroll',
    background:"#F2F2F2",
});
const ContentContainer = styled(Box)({
    width: '100%',
    display: 'flex', flex: 1, flexDirection: 'column', alignItems: 'center', paddingTop: 20, justifyContent: 'center', alignSelf: 'center',
    gap: 24,
    background:"#FFF",
    marginTop: "20px",
    borderRadius: "8px",
    "@media (max-width: 600px)": {
        padding: 20,
        width: "100%",
         marginTop: "0px",
    borderRadius: "0px"

    },
});
// Customizable Area End
