import React, { useEffect, useState } from "react";
import { Box, Grid, Divider, Stack, Typography, Link } from "@mui/material";
import { styled } from '@mui/system';
import parse, { domToReact, Element, HTMLReactParserOptions } from "html-react-parser";

import OverviewSection from "./OverviewSection.web";
import PlainTextItem from "./PlanTextItem.web";
import ItemWithImage from "./ItemWithImage.web";
import ItemWithAnnotation from "./ItemWithAnnotation.web";
import { CustomSectionContainer, SectionListLayout } from "./CustomContainer.web";
import { OverviewAnnotation } from "./MethodOverviewSection.web";

import { IContentData, ISectionData, ITermDetail } from "../../blocks/contentmanagement/src/ContentManagementController";

const webStyles = {
  mobileOrderWithTitle: {
    mb: '20px', 
    gap: '10px', 
    alignItems: 'center'
  }
}

const OmbreContainer = styled(Box)({
  padding: '20px',
  borderRadius: '8px',
  display: 'flex',
  justifyItems: "flex-start"
})
const LightSectionTitle = styled(Box)({
  fontSize: '26px', 
  fontWeight: '700',
  fontFamily: 'Lato',
  color: '#586554',
  '& p': {
    margin: 0
  }
});
const MainTextContainer = styled(LightSectionTitle)({
  fontSize: '20px', 
  lineHeight: '32px',
});
const OrderText = styled(Typography)({
  fontSize: '24px', 
  fontWeight: '700',
  fontFamily: 'Lato',
  lineHeight: '28.8px',
  color: '#586554',
});
const MainText = styled(OrderText)({
  fontSize: '20px', 
  lineHeight: '32px',
});
const CustomLink = styled(Link)({
  color: '#343C32', 
  textDecoration: 'none',
});


interface ISymtomWithRelativeCausesListData {
  headings: string[];
  rows: string[][];
}

const UrinaryProblems = ({ data }: { data: IContentData }) => {
  const { attributes } = data;
  const { title, terms } = attributes;

  const parsingOptions: HTMLReactParserOptions = {
    trim: true,
    replace(domNode) {
      if (!(domNode instanceof Element)) return;
      const replacementByName: { [key: string]: JSX.Element } = {
          "ul": <>{domToReact(domNode.children, parsingOptions)}</>,
          "li": <PlainTextItem>{domToReact(domNode.children, parsingOptions)}</PlainTextItem>,
          "a": <CustomLink href={domNode.attribs['href']} target="_blank">{domToReact(domNode.children)}</CustomLink>,
      };
      if (!replacementByName[domNode.name]) return;
      return replacementByName[domNode.name];
    },
  }

  const [sectionList, setSectionList] = useState<ISectionData[]>([]);
  const [symtomWithRelativeCausesList, setSymtomWithRelativeCausesList] = useState<ISymtomWithRelativeCausesListData>({ headings: [], rows: [] });

  useEffect(() => {
    if (terms) {
      terms.sort((a, b) => a.id - b.id); 
      const groupedData: { [key: string]: ITermDetail[] } = {};
      terms.forEach(term => {
        const key = term.term;
        if (!groupedData[key]) {
          groupedData[key] = [];
        }
        groupedData[key].push(term);
      });
      const sections = Object.entries(groupedData).map(group => {
        if (group[1].length > 1) {
          return {
            name: group[0],
            definition: '',
            images: [],
            children: group[1].map(each => {
              return { definition: each.definition, images: each.images }
            })
          }
        }
        return {
          id: group[1][0].id,
          name: group[0],
          definition: group[1][0].definition,
          images: group[1][0].images,
        }
      })
      setSectionList(sections)
    }
  }, [terms]);

  useEffect(() => {
    const filteredSections = sectionList.filter(section => section.children && section.children.some(child => child.images.length === 0));
    if (filteredSections.length === 2) {
      if (filteredSections.every(section => section?.children) && filteredSections[0].children?.length === filteredSections[1].children?.length) {
        const relativeCauses = filteredSections[1]?.children || [];
        const rows = filteredSections[0].children?.map((symptom, index) => [symptom.definition, relativeCauses[index]?.definition]);
        setSymtomWithRelativeCausesList({ headings: filteredSections.map(filteredSection => filteredSection.name), rows: rows || [] })
      }
    }
  }, [sectionList]);

  const renderItemsWithChildren = (htmlString: string) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, 'text/html');
    return (
      <>
        <ItemWithAnnotation
          withOrdering={doc.querySelector('p:has(strong)')?.previousElementSibling && doc.querySelector('p:has(strong)')?.previousElementSibling?.tagName === 'P' || false}
          order={doc.querySelector('p:has(strong)')?.previousElementSibling?.textContent || ''}
          orderStyles={{ backgroundColor: 'transparent', padding: '0', width: '62px', height: '62px' }}
          main={doc.querySelector('p:has(strong)')?.textContent || ''}
          annotationList={Array.from(doc.querySelectorAll('li')).map((li, index) => <PlainTextItem key={index}>{parse(li.innerHTML)}</PlainTextItem>)}
          containerStyles={{ display: { xs: 'none', lg: 'flex' }, flex: 1, alignItems: 'start' }} 
        />
        <Stack direction="column" sx={{ display: { xs: 'block', lg: 'none' } }}>
          {doc.querySelector('p:has(strong)')?.previousElementSibling && doc.querySelector('p:has(strong)')?.previousElementSibling?.tagName === 'P' 
            && <Stack direction="row" sx={webStyles.mobileOrderWithTitle}>
              <OrderText>{doc.querySelector('p:has(strong)')?.previousElementSibling?.textContent || ''}</OrderText>
              <MainText>{doc.querySelector('p:has(strong)')?.textContent || ''}</MainText>
          </Stack>}
          <Stack direction="column" sx={{ gap: 1.5 }}>
            {parse(doc.querySelector('ul')?.innerHTML || '', parsingOptions)}
          </Stack>
        </Stack>
      </>
    )
  }

  return (
    <SectionListLayout>
      <OverviewSection 
        images={title?.images.map(image => image.url) || []} 
        name={parse(title?.title)} 
        shortDesc={title?.desc && title?.desc !== '' && parse(title?.desc)}
        containerStyle={{ bgcolor: 'transparent' }}
      />
      {sectionList.map((section, index) => {
        if (section.id && section.images.length > 0 && sectionList[index + 1] && sectionList[index + 1]?.id && sectionList[index + 1]?.images.length > 0) {
          return (
            <CustomSectionContainer 
              key={index} 
              contentStyle={{ flexDirection: 'row', flexWrap: 'wrap', gap: { xs: '20px', md: '34px' } }}
            >
              {[...Array(2).keys()].map((num) => 
                <OverviewAnnotation
                  key={num}
                  title={parse(sectionList[index + num].name)}
                  image={sectionList[index + num].images.length > 0 ? sectionList[index + num].images[0]?.url : ''}
                  sx={{ flexBasis: { xs: '100%', md: 0 } }}
                  layoutStyle={{ alignItems: 'center' }}
                  contentStyle={{ gap: '12px' }}
                  content={parse(sectionList[index + num].definition, parsingOptions)}
                />)}
            </CustomSectionContainer>
          )
        }
        if (section.children && section.children.some(child => child.images.length === 0) && sectionList[index + 1] && sectionList[index + 1].children 
          && sectionList[index + 1].children?.some(child => child.images.length === 0)) {
            return (
              <>
                <CustomSectionContainer 
                  key={`mobile-${index}`}
                  withHeading
                  heading={parse(symtomWithRelativeCausesList.headings[0] || '')}
                  containerStyle={{ display: { xs: 'block', lg: 'none' }, py: { xs: 2.5, md: 5 } }}
                  contentStyle={{ width: '100%' }}
                >
                  {symtomWithRelativeCausesList.rows.map((row, index) => 
                    <OmbreContainer 
                      key={index} 
                      sx={[
                        { 
                          backgroundColor: (index + 1) % 3 === 0 ? '#A7B3A3' : (index + 1) % 2 === 0 ? '#C3CBC0' : '#D6DCD5',
                          flexDirection: 'column',
                          gap: '20px' 
                        }
                      ]}
                    >
                      {renderItemsWithChildren(row[0])}
                      <MainTextContainer sx={{ mb: '-10px', fontSize: { xs: 20, md: 26 } }}>
                        {parse(symtomWithRelativeCausesList.headings[1] || '')}
                      </MainTextContainer>
                      <Box sx={{ px: 2.5 }}>
                        {renderItemsWithChildren(row[1])}
                      </Box>
                    </OmbreContainer>
                  )}
                </CustomSectionContainer>
                <CustomSectionContainer key={`desktop-${index}`} containerStyle={{ display: { xs: 'none', lg: 'block' } }}>
                  <Grid container>
                    {symtomWithRelativeCausesList.headings.map((heading, index) => 
                      <Grid item xs={6} key={index}><LightSectionTitle>{parse(heading)}</LightSectionTitle></Grid>)}
                  </Grid>
                  {symtomWithRelativeCausesList.rows.map((row, index) => 
                    <OmbreContainer key={index} sx={{ backgroundColor: (index + 1) % 3 === 0 ? '#A7B3A3' : (index + 1) % 2 === 0 ? '#C3CBC0' : '#D6DCD5' }}>
                      {renderItemsWithChildren(row[0])}
                      <Divider orientation="vertical" variant="middle" flexItem sx={{ border: 'border: 1px solid #A7B3A3' }}/>
                      {renderItemsWithChildren(row[1])}
                    </OmbreContainer>
                  )}
                </CustomSectionContainer>
              </>
            )
        }
        if (section.children && section.children?.some(child => child.images.length > 0)) {
          return (
            <CustomSectionContainer
              key={index}
              withHeading
              heading={parse(section.name)}
              containerStyle={[index === sectionList.length - 1 && { backgroundColor:  '#FEF2F2', border: '2px dashed #F87171' }]} 
            >
              <Grid container spacing={2.5}>
                {section?.children && section?.children.map((child, index) => (
                  <Grid key={index} item xs={12} lg={6}>
                    <ItemWithImage image={child.images[0]?.url || ''} text={parse(child.definition)} />
                  </Grid>
                ))}
              </Grid>
            </CustomSectionContainer>
          )
        }
      })}
    </SectionListLayout>
  );
}

export default UrinaryProblems;
