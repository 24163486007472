import React, { useEffect, useState } from "react";
import { Grid, Stack } from "@mui/material";
import { styled } from '@mui/system';
import parse, { domToReact, Element, HTMLReactParserOptions } from "html-react-parser";

import OverviewSection from "./OverviewSection.web";
import ItemWithImage from "./ItemWithImage.web";
import PlainTextItem from "./PlanTextItem.web";
import { CustomSectionContainer, SectionListLayout } from "./CustomContainer.web";

import { IContentData, ISectionData, ITermDetail } from "../../blocks/contentmanagement/src/ContentManagementController";

const PlainListWithHeadingBox = styled(Stack)({
  backgroundColor: '#FFF', 
  padding: '20px',
  gap: '20px',
  '& p': {
    lineHeight: '32px',
    color: '#586554', 
    fontFamily: 'Lato',
    margin: 0
  },
  '& div > svg': { marginBottom: '-8px' }
});

const BelchingAndHiccups = ({ data }: { data: IContentData }) => {
  const { attributes } = data;
  const { title, terms } = attributes;

  const parsingOptions: HTMLReactParserOptions = {
    trim: true,
    replace(domNode) {
      if (!(domNode instanceof Element)) return;
      const replacementByName: { [key: string]: JSX.Element } = {
          "ul": <>{domToReact(domNode.children, parsingOptions)}</>,
          "li": <PlainTextItem style={{ mb: 1 }}>{domToReact(domNode.children, parsingOptions)}</PlainTextItem>,
      };
      if (!replacementByName[domNode.name]) return;
      return replacementByName[domNode.name];
    },
  }

  const [sectionList, setSectionList] = useState<ISectionData[]>([]);

  useEffect(() => {
    if (terms) {
      terms.sort((a, b) => a.id - b.id); 
      const groupedData: { [key: string]: ITermDetail[] } = {};
      terms.forEach(term => {
        const key = term.term;
        if (!groupedData[key]) {
          groupedData[key] = [];
        }
        groupedData[key].push(term);
      });
      const sections = Object.entries(groupedData).map(group => {
        if (group[1].length > 1) {
          return {
            name: group[0],
            definition: '',
            images: [],
            children: group[1].map(each => {
              return { definition: each.definition, images: each.images }
            })
          }
        }
        return {
          name: group[0],
          definition: group[1][0].definition,
          images: group[1][0].images,
        }
      })
      setSectionList(sections)
    }
  }, [terms]);

  return (
    <SectionListLayout>
      <OverviewSection 
        images={title?.images.map(image => image.url) || []} 
        name={parse(title?.title)} 
        shortDesc={title?.desc && title?.desc !== '' && parse(title?.desc)}
        containerStyle={{ backgroundColor: 'transparent', padding: { xs: '20px 0 0', md: '0 188px' } }} 
      />
        {sectionList.map((section, index) => {
          if (section.children) {
            return (
              <CustomSectionContainer
                key={index}
                withHeading
                heading={parse(section.name)}
                shrink
                containerStyle={{ backgroundColor: 'transparent', py: 0 }}
                innerContainerStyle={{ p: { xs: '20px 16px', md: '40px 130px' } }}
              >
                  <Grid container spacing={2.5}> 
                    {section?.children.map((child, index) => (
                      <Grid key={index} item xs={12} lg={6}>
                        {child.images.length > 0 
                          ? <ItemWithImage image={child.images[0].url} text={parse(child.definition)} /> 
                          : (
                            <PlainListWithHeadingBox sx={{ '& p:not(div p)': { fontSize: { xs: '20px', md: '26px' } } }}>
                              {parse(child.definition, parsingOptions)}
                            </PlainListWithHeadingBox>
                          )
                        }
                      </Grid>
                    ))}                                                                                                          
                  </Grid>
              </CustomSectionContainer>
            );
          }
          return (
            <CustomSectionContainer
              key={index}
              withHeading
              heading={parse(section.name)}
              shrink
              containerStyle={{ backgroundColor: 'transparent', py: 0 }}
              innerContainerStyle={[index === sectionList.length - 1 && { py: { xs: 2.5, md: 5 }, border: '2px dashed #F87171', bgcolor: '#FEF2F2' }]}
            >
              {parse(section.definition, parsingOptions)}
            </CustomSectionContainer>
          );
        })}
    </SectionListLayout>
  );
}

export default BelchingAndHiccups;
