import React, { useEffect, useState } from "react";
import { Box, Stack } from '@mui/material';
import { styled, SxProps } from '@mui/system';
import parse, { domToReact, Element, HTMLReactParserOptions } from 'html-react-parser';

import OverviewSection from "./OverviewSection.web";
import ItemWithImage from "./ItemWithImage.web";
import PlainTextItem from "./PlanTextItem.web";
import { CustomSectionContainer, SectionListLayout } from "./CustomContainer.web";
import { checkTagExisted } from "./ReactHtmlParser";

import { IContentData, ISectionData, ITermDetail } from "../../blocks/contentmanagement/src/ContentManagementController";

const webStyles = {
    alertContainer: {
        border: '2px dashed #F87171',
        backgroundColor: "#FEF2F2"
    }
} as const;

const HeadingText = styled(Box)({
    margin: '0 0 10px',
    fontWeight: '700',
    fontFamily: 'Lato',
    color: '#586554',
});

function Diarrhea({ data }: { data: IContentData }) {
    const { attributes } = data;
    const { title, terms } = attributes;

    const getParsingOption = (listStyles? : SxProps, listItemStyles?: SxProps, headingStyles?: SxProps) => {
        const parsingOptions: HTMLReactParserOptions = {
            trim: true,
            replace(domNode) {
                if (!(domNode instanceof Element)) return;
                const replacementByName: { [key: string]: JSX.Element } = {
                    "ul": <Stack direction="column" sx={{ gap: 1.5, ...listStyles }}>{domToReact(domNode.children, parsingOptions)}</Stack>,
                    "li": <PlainTextItem style={{ ...listItemStyles }}>{domToReact(domNode.children)}</PlainTextItem>,
                    "h5": <HeadingText sx={{ fontSize: { xs: '20px', md: '26px' }, ...headingStyles }}>{domToReact(domNode.children)}</HeadingText>,
                    "p": <PlainTextItem withoutIcon>{domToReact(domNode.children)}</PlainTextItem>,
                };
                if (!replacementByName[domNode.name]) return;
                return replacementByName[domNode.name];
            },
        }
        return parsingOptions
    }

    const [sectionList, setSectionList] = useState<ISectionData[]>([]);

    useEffect(() => {
        if (terms) {
            terms.sort((a, b) => a.id - b.id); 
            const groupedData: { [key: string]: ITermDetail[] } = {};
            terms.forEach(term => {
                const key = term.term;
                if (!groupedData[key]) {
                groupedData[key] = [];
                }
                groupedData[key].push(term);
            });
            const sections = Object.entries(groupedData).map(group => {
                if (group[1].length > 1) {
                    return {
                        name: group[0],
                        definition: '',
                        images: [],
                        children: group[1].map(each => {
                            return { definition: each.definition, images: each.images }
                        })
                    }
                }
                return {
                    id: group[1][0].id,
                    name: group[0],
                    definition: group[1][0].definition,
                    images: group[1][0].images,
                }
            })
            setSectionList(sections)
        }
    }, [terms]);

    return (
        <SectionListLayout>
            <OverviewSection 
                images={title?.images.map(image => image.url) || []} 
                name={parse(title?.title)} 
                shortDesc={title?.desc && title?.desc !== '' && parse(title?.desc)}
            />
            {sectionList.map((section, index) => {
                if (section.children && sectionList[index + 1] && sectionList[index + 1]?.children) {
                    return (
                        <CustomSectionContainer 
                            key={index} 
                            variant="light"
                            containerStyle={{ p: { xs: 0, md: '0 130px' } }} 
                            contentStyle={{ flexDirection: 'row', flexWrap: 'wrap' }}
                        >
                            {[...Array(2).keys()].map((num => (
                                <CustomSectionContainer 
                                    key={num} 
                                    withHeading 
                                    heading={parse(sectionList[index + num].name)}
                                    headingStyle={{ textAlign: 'center' }}
                                    containerStyle={{ flex: 1, flexBasis: { xs: '50%', lg: '0' }, p: { xs: '20px', md: '40px 20px' } }}
                                    contentStyle={{ width: '100%' }}
                                >  
                                    {sectionList[index + num].children && sectionList[index + num].children?.map((child, idx) => (
                                        <ItemWithImage 
                                            key={idx} 
                                            image={child.images[0]?.url || ''} 
                                            text={parse(child.definition, getParsingOption())}
                                            textStyle={{ height: { xs: 'fit-content' }, gap: 1.5 }} 
                                        />
                                    ))}
                                </CustomSectionContainer>
                                )))}
                        </CustomSectionContainer>
                    )
                }
                if (checkTagExisted(section.definition, 'h5') && sectionList[index + 1]) {
                    return (
                        <CustomSectionContainer 
                            key={index} 
                            variant="light"
                            containerStyle={{ p: { xs: 0, md: '0 130px' } }} 
                            contentStyle={{ flexDirection: 'row', flexWrap: 'wrap' }}
                        >
                            {[...Array(2).keys()].map((num => (
                                <CustomSectionContainer 
                                    key={num} 
                                    withHeading 
                                    heading={parse(sectionList[index + num].name)}
                                    containerStyle={[
                                        { flex: 1, flexBasis: { xs: '50%', md: '0' }, p: { xs: 5 } },
                                        num === 1 && webStyles.alertContainer
                                    ]}

                                >  
                                    {parse(sectionList[index + num].definition, getParsingOption({ gap: num !== 0 ? 2.5 : 1.5 }, 
                                        { p: num !== 0 ? { xs: 0, md: 2.5 } : 0 }, { mt: { xs: '-4px', md: 0 }, mb: 0 }))}
                                </CustomSectionContainer>
                                )))}
                        </CustomSectionContainer>
                    )
                }
                if (section.id && index !== sectionList.length - 1) {
                    return (
                        <CustomSectionContainer key={index} withHeading heading={parse(section.name)}>
                            {parse(section.definition, getParsingOption())}
                        </CustomSectionContainer>
                    )
                }
            })}
        </SectionListLayout>
    );
}

export default Diarrhea;
