// Customizable Area Start
import React from "react";
import { Box } from "@mui/material";
import { styled } from "@mui/system";
// Customizable Area End

// Customizable Area Start
import ContentManagementController, {
  Props,
} from "./ContentManagementController";

import MedicationLaxatives from "../../../components/src/MedicationLaxatives"
import NauseaVomiting from "../../../components/src/NauseaVomiting"
import StopMedication from "../../../components/src/StopMedication";
import AuthorizedHeader from "../../../components/src/AuthorizedHeader"
import OpioidMyths from "../../../components/src/OpioidMyths";
import TypesOpioidMed from "../../../components/src/TypesOpioidMeds"
import ShortnessOfBreath from "../../../components/src/ShortOfBreadth";
import AbbeyPainScale from "../../../components/src/AbbeyPainScale";
import Psychotropic from "../../../components/src/Psychotropic.web";
import Diarrhea from "../../../components/src/Diarrhea";
import SymptomARD from "../../../components/src/SymptomARD.web";
import RecipeForMMIC from "../../../components/src/RecipeForMMIC.web";
import UrinaryProblems from "../../../components/src/UrinaryProblems.web";
import Sleep from "../../../components/src/Sleep.web";
import BelchingAndHiccups from "../../../components/src/BelchingAndHiccups.web";
import Skincare from "../../../components/src/Skincare.web";
import DryMouthCare from "../../../components/src/DryMouthCare.web";
import HospiceExpectation from "../../../components/src/HospiceExpectation.web";
import DiscussionTopics from "../../../components/src/DiscussionTopics.web";
import NecessarySupplies from "../../../components/src/NecessarySupplies.web";
import UnderstandingOpioid from "../../../components/src/UnderstandingOpioid.web";
import MouthCare from "../../../components/src/MouthCare.web";
import Bathing from "../../../components/src/Bathing.web";
import AppetiteFood from "../../../components/src/Appetite.web";
import WhyNot from "../../../components/src/WhyNot.web";
import UnderstandingNonOpioid from "../../../components/src/Non_opioid_pain";
import EatingDrinking from "../../../components/src/EatingDrinking.web";
import Agitation from "../../../components/src/Agitation.web";
import DementiaMedication from "../../../components/src/DementiaMedication.web";
import Pain from "../../../components/src/MedicationPain";
import CareForCaregivers from "../../../components/src/CareForCaregivers.web";
import HomeEnvironment from "../../../components/src/HomeEnvironment.web";
import Constipation from "../../../components/src/Constipation.web";
import NauseaAndVomiting from "../../../components/src/NauseaAndVomiting.web";
import WhatToExpectWhenDying from "../../../components/src/WhatToExpectWhenDying.web";
import MovingPatient from "../../../components/src/MovingPatient.web";
import Oxygen from "../../../components/src/Oxygen.web";
import ChangingLinens from "../../../components/src/ChangingLinens.web";
import ChangingPositionInBedImportance from "../../../components/src/ChangingPositionInBedImportance.web";
import Infection from "../../../components/src/Infection.web";
import UrinaryMedsList from "../../../components/src/UrinaryMedsList.web";
import ContentBreadcrumbs from "../../../components/src/ContentBreadcrumb.web";
// Customizable Area End


export default class ContentManagement extends ContentManagementController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderHeader = () => {
    return (
      <AuthorizedHeader
        navigation={this.props.navigation}
        data-test-id={"authorized-header"}
      />
    )
  }

  renderBreadcrumbs = () => {
    return this.state.contentData.attributes.bread_crumbs && (
      <ContentBreadcrumbs 
        breadcrumbData={this.state.contentData.attributes.bread_crumbs} 
        navigation={this.props.navigation} 
      />
    )
  }

  renderContentManagement = () => {
    if (!this.state.contentData.attributes.title.layout_id) return;
    const layoutId = this.state.contentData.attributes.title.layout_id || '';

    const componentByLayoutId: { [key: string]: JSX.Element } = {
      "11": <UnderstandingOpioid data={this.state.contentData} />,
      "111": <TypesOpioidMed data={this.state.contentData} />,
      "112": <UnderstandingNonOpioid data={this.state.contentData} />,
      "12": <OpioidMyths data={this.state.contentData} />,
      "13": <NauseaVomiting data={this.state.contentData} />,
      "14": <StopMedication data={this.state.contentData} />,
      "15": <MedicationLaxatives data={this.state.contentData} />,
      "16": <Psychotropic data={this.state.contentData} />,

      "21": <Pain data={this.state.contentData} />,
      "211": <AbbeyPainScale data={this.state.contentData} />,
      "22": <SymptomARD data={this.state.contentData} />,
      "23": <ShortnessOfBreath data={this.state.contentData} />,
      "241": <Diarrhea data={this.state.contentData} />,
      "24": <Constipation data={this.state.contentData} />,
      "25": <NauseaAndVomiting data={this.state.contentData} />,
      "26": <AppetiteFood data={this.state.contentData} />,
      "261": <RecipeForMMIC data={this.state.contentData} />,
      "27": <UrinaryProblems data={this.state.contentData} />,
      "271": <UrinaryMedsList data={this.state.contentData} />,
      "28": <Sleep data={this.state.contentData} />,
      "29": <BelchingAndHiccups data={this.state.contentData} />,
      "210": <Skincare data={this.state.contentData} />,
      "2110": <MouthCare data={this.state.contentData} />,
      "2111": <DryMouthCare data={this.state.contentData} />,

      "41": <HospiceExpectation data={this.state.contentData} />,
      "42": <NecessarySupplies data={this.state.contentData} />,
      "43": <DiscussionTopics data={this.state.contentData} />,

      "51": <WhatToExpectWhenDying data={this.state.contentData} />,
      "52": <WhatToExpectWhenDying data={this.state.contentData} />,
      "53": <WhatToExpectWhenDying data={this.state.contentData} />,
      "54": <WhatToExpectWhenDying data={this.state.contentData} />,

      "61": <MovingPatient data={this.state.contentData} />,
      "62": <Oxygen data={this.state.contentData} />,
      "63": <ChangingPositionInBedImportance data={this.state.contentData} />,
      "64": <ChangingLinens data={this.state.contentData} />,
      "65": <Bathing data={this.state.contentData} />,
      "67": <HomeEnvironment data={this.state.contentData} navigation={this.props.navigation} />,

      "71": <WhyNot data={this.state.contentData} />,
      "721": <EatingDrinking data={this.state.contentData} />,
      "722": <Infection data={this.state.contentData} />,
      "723": <Agitation data={this.state.contentData} />,
      "724": <DementiaMedication data={this.state.contentData} />,
      "73": <CareForCaregivers data={this.state.contentData} />,
    };

    return componentByLayoutId[layoutId] || 'There are no screens with this layout id';
  }

  // Customizable Area End

  render() {

    return (
      // Customizable Area Start
      <Body sx={{ padding: { xs: '0 0 20px', lg: 2.5 } }}>
        {this.renderHeader()}
        {this.renderBreadcrumbs()}
        {this.renderContentManagement()}
      </Body>
      // Customizable Area End
    );
  }
}
// Customizable Area Start
const Body = styled(Box)({
  background: '#FAF9F6',
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
});
// Customizable Area End
