import React, { FC, Fragment, ReactNode, useEffect, useState } from 'react';
import { Box, Button, FormControlLabel, Grid, Typography, RadioGroup } from '@mui/material';
import Radio, { RadioProps } from '@mui/material/Radio';
import { styled } from '@mui/system';
import { FieldArray, Form, FormikProvider, useField, useFormik } from 'formik';
import parse from 'html-react-parser';
import * as yup from 'yup';

import ContentBreadcrumbs from './ContentBreadcrumb.web';
import { OrderedListItem } from './ItemWithLetter.web';
import { CustomSectionContainer, SectionListLayout } from './CustomContainer.web';

import { IContentData, ITermDetail } from '../../blocks/contentmanagement/src/ContentManagementController';
const pointer = require("../../blocks/contentmanagement/assets/pointer.svg");

const FlexCol = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: '10px'
})
const ResultContainer = styled(Box)({
  border: '2px dashed #7C8E76',
  borderRadius: '8px',
  padding: '20px',
  backgroundColor: '#F2F4F1',
  textAlign: 'right',
  '& p': {
    margin: 0,
    fontFamily: 'Lato',
    fontWeight: '700',
    fontSize: '20px',
    lineHeight: '24px', 
    color: '#343C32',
    '@media (min-width: 900px)': {
      fontSize: '24px',
      lineHeight: '28.8px', 
    },
    'span': {
      fontSize: '20px',
      lineHeight: '24px',  
      fontWeight: '800',
      '@media (min-width: 900px)': {
        fontSize: '26px',
        lineHeight: '31.2x',  
      },
    }
  }
})
const ResultSpan = styled('span')({
  marginLeft: '8px',
  color: '#FFB127'
})
const BaseText = styled(Box)({
  fontFamily: 'Lato',
  color: '#343C32',
  '& p': { margin: 0 },
  '& p:not(:last-child)': {
    marginBottom: '12px'
  }
})
const SmText = styled(BaseText)({
  fontSize: '16px',
  lineHeight: '19.2px',
  '@media (min-width: 900px)': {
    fontSize: '18px',
    lineHeight: '21.6px'
  }
})
const LgText = styled(BaseText)({
  fontSize: '20px',
  lineHeight: '24px',
  '@media (min-width: 900px)': {
    fontSize: '24px',
    lineHeight: '28.8px',
  }
})
const RadioLabelText = styled(Typography)({
  fontFamily: 'Lato',
  color: '#343C32',
  fontWeight: '400',
  fontSize: '22px',
  lineHeight: '26.4px',
  textTransform: 'capitalize'
})
const CustomButton = styled(Button)({
  minWidth: '151px',
  padding: '10px 16px',
  borderRadius: '8px',
  backgroundColor: '#7C8E76',
  color: 'white',
  fontFamily: 'Lato',
  fontSize: '16px',
  fontWeight: '700',
  textTransform: 'capitalize',
  '&.Mui-disabled': {
    minWidth: '134px',
    backgroundColor: '#D6DCD5',
    color: '#7C8E76'
  },
  '&:hover': {
    backgroundColor: '#586554',
  },
})
const BpIcon = styled('span')({
  borderRadius: '50%',
  width: 20,
  height: 20,
  boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
  backgroundColor: 'transparent',
  'input:disabled ~ &': {
    boxShadow: 'none',
    background: 'rgba(206,217,224,.5)',
  }
});
const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: '#7C8E76',
  boxShadow: 'none',
  '&::before': {
    display: 'block',
    width: 20,
    height: 20,
    backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
    content: '""',
  }
});
const Scale = styled("img")({
  margin: 'auto', 
  objectFit: 'contain', 
  inset: 0
});
const CustomIcon = styled("img")({
  objectFit: "contain",
});

function BpRadio(props: RadioProps) {
  return (
    <Radio
      disableRipple
      color="default"
      checkedIcon={<BpCheckedIcon />}
      icon={<BpIcon />}
      {...props}
    />
  );
}

const webStyles = {
  questionIndex: {
    backgroundColor: '#F2F4F1',
  },
  questionDescription: {
    fontWeight: '400',
    color: '#7C8E76'
  },
  radioLabelContainer: {
    width:"100%",
    // maxWidth:'182px',
    height:"100%",
    textAlign:"center",
    paddingY: "17px"
  }
};

type IQuestionProps = {
  index: number; 
  question: ReactNode; 
  desc: ReactNode;
}

const Question: FC<IQuestionProps> = ({ index, question, desc }) => {
  const [field] = useField(`questions.${index}`);

  return (
    <Grid container alignItems="center" rowSpacing={1} sx={{ borderBottom: '1px solid rgba(124, 142, 118, 0.2)', p: { xs: 0, md: '8px 0' } }}>
      <Grid item xs={12} md={4}>
        <OrderedListItem 
          order={`Q${index + 1}`} 
          styles={{ gap: '14px', py: { xs: 2, md: 0 }, flexWrap: 'wrap' }}
          orderStyles={[webStyles.questionIndex]}
          orderSize='large'
        >
          <FlexCol sx={{ flex: 1,flexBasis: { xs: '100%', md: 0 } }}>
            <LgText fontWeight="700">{question}</LgText>
            <SmText sx={webStyles.questionDescription}>{desc}</SmText>
          </FlexCol>
        </OrderedListItem>
      </Grid>
      <Grid item xs={12} md={8}>
        <RadioGroup
          row
          sx={{ flexWrap: 'wrap', justifyContent: 'space-around' }}
          {...field}
        >
          {scores.map((score, index) => (
            <Fragment key={index}>
              <Box  sx={{ display: { xs: 'block', md: 'none' }, flexBasis: { xs: '50%', md: 0 } }}>
                <FormControlLabel 
                  sx={{ mx: 0, textTransform: 'capitalize' }} 
                  label={score} 
                  value={score} 
                  checked={field.value === score}
                  control={<BpRadio sx={{ py: 2 }}/>} 
                />
              </Box>
              <Box sx={{ display: { xs: 'none', md: 'block' } }}>
                <FormControlLabel 
                  sx={{ mx: 0 }} 
                  label="" 
                  value={score} 
                  checked={field.value === score}
                  control={<BpRadio />} 
                />
              </Box>
            </Fragment>
          ))}
        </RadioGroup>
      </Grid>
    </Grid>   
  )
}

const scores = ['absent', 'mild', 'moderate', 'severe'];

export default function AbbeyPainScale({ data }: { data: IContentData }) {
  const { attributes } = data;
  const { terms } = attributes;

  const [questions, setQuestions] = useState<ITermDetail[]>([]);
  const [result, setResult] = useState<{ total?: number; severity?: string }>({});
  
  useEffect(() => setQuestions(terms.length > 2 ? terms.slice(1, terms.length - 1) : []), [terms])

  const validationSchema = yup.object({
    questions: yup.array().of(yup.string().required('Must answer all questions')) 
      .required('Must answer at least one question') // these constraints are shown if and only if inner constraints are satisfied
      .min(questions.length, 'Must answer all questions'),
  })

  const calculateTotalScore = ({ questions }: { questions: string[] }) => {
    const scores: number[] = questions.map(value => {
      switch (value) {
        case 'absent': return 0;
        case 'mild': return 1;
        case 'moderate': return 2;
        case 'severe': return 3;
        default: return 0;
      }
    });
    const total = scores.reduce((acc, score) => acc + score, 0);
    let severity = 'No Pain';
    if (total >= 14) severity = 'Severe';
    else if (total >= 8) severity = 'Moderate';
    else if (total >= 3) severity = 'Mild';
    return { total, severity };
  };

  const formik = useFormik({
    initialValues: { questions: Array(terms.slice(1, terms.length - 1).length).fill("") ?? [] },
    validationSchema: validationSchema,
    validateOnMount: true,
    onSubmit: (values) => {
      setResult(calculateTotalScore(values));
    },
    onReset: () => {
      setResult({});
    }
  });

  return (
    <Box sx={{ pb: { xs: 5, md: '172px' } }}>
      {terms.length > 0 && <SectionListLayout styles={{ mt: { xs: 5, md: 2.5 } }}>
        <CustomSectionContainer
          variant='light'
          withHeading
          heading={parse(terms[0].term)}
          headingStyle={{ color: '#343C32', textAlign: { xs: 'center', md: 'left' } }}
          containerStyle={{ px: { xs: 2.5, md: '130px' }, py: 0 }}
        >
          <LgText sx={{ 
            fontSize: { xs: '16px', md: '24px' }, 
            lineHeight: { xs: '19.2px', md: '28.8px' },
            textAlign: { xs: 'center', md: 'left' }, 
          }}>{parse(terms[0].definition)}</LgText>
        </CustomSectionContainer>
        {questions.length > 0 && (
          <CustomSectionContainer
            variant='light'
            containerStyle={{ px: { xs: 2.5, md: '130px' }, py: 0 }}
          >
            <Box sx={{ display: { xs: 'block', md: 'none' }}}>
              <FormikProvider value={formik}>
                <Form>
                  <FieldArray name="questions">
                    {() => (
                      <Box>
                        {questions.map((question, index) => (
                          <Question 
                            key={question.id}
                            index={index} 
                            question={parse(question.term)} 
            
                            desc={(parse(question.definition))}
                          />
                        ))}
                      </Box>
                    )}
                  </FieldArray>
                  {result['severity'] &&  
                    <ResultContainer sx={{ mt: 2.5 }}>
                      <p>Total pain score:<ResultSpan>{`${result.total} - ${result.severity}`}</ResultSpan></p>
                    </ResultContainer>
                  }
                  <FlexCol sx={{ mt: 2.5, alignItems: 'flex-end' }}>
                    {formik.submitCount <= 0 && <CustomButton 
                      type="submit" 
                      variant="contained" 
                      disabled={!formik.isValid}
                    >submit</CustomButton>}
                    {formik.submitCount > 0 && formik.isValid && <CustomButton 
                      type="button" 
                      variant="contained" 
                      onClick={() => formik.resetForm({ values: { questions: Array(questions.length).fill('') } })}
                    >Retake</CustomButton>}
                  </FlexCol>
                </Form>
              </FormikProvider>
              
            </Box>
            <Box sx={{ display: { xs: 'none', md: 'block' }}}>
              <Grid container alignItems="center" style={{ marginTop: '20px', borderBottom: '1px solid #586554' }}>
                <Grid item xs={4}></Grid>
                {scores.map((score, index) => (
                  <Grid item key={index} xs={2}>
                    <Box sx={webStyles.radioLabelContainer}>
                      <RadioLabelText>{score}</RadioLabelText>
                    </Box>
                  </Grid>
                ))} 
              </Grid>
              <FormikProvider value={formik}>
                <Form>
                  <FieldArray name="questions">
                    {() => (
                      <Grid container>
                        {questions.map((question, index) => (
                          <Grid item key={question.id} xs={12}>
                            <Question 
                              index={index} 
                              question={parse(question.term)} 
                              desc={(parse(question.definition))}
                            />
                          </Grid>
                        ))}
                      </Grid>
                    )}
                  </FieldArray>
                  {result['severity'] &&  
                    <ResultContainer sx={{ mt: 5 }}>
                      <p>Total pain score:<ResultSpan>{`${result.total} - ${result.severity}`}</ResultSpan></p>
                    </ResultContainer>
                  }
                  <FlexCol sx={{ mt: 5, alignItems: 'flex-end' }}>
                    {formik.submitCount <= 0 && <CustomButton 
                      type="submit" 
                      variant="contained" 
                      disabled={!formik.isValid}
                    >submit</CustomButton>}
                    {formik.submitCount > 0 && formik.isValid && <CustomButton 
                      type="button" 
                      variant="contained" 
                      onClick={() => formik.resetForm({ values: { questions: Array(questions.length).fill('') } })}
                    >Retake</CustomButton>}
                  </FlexCol>
                </Form>
              </FormikProvider>
            </Box>
          </CustomSectionContainer>
        )}
        {terms[terms.length - 1] && terms[terms.length - 1].images.length > 0 &&
          <CustomSectionContainer
            variant='light'
            withHeading
            heading={parse(terms[terms.length - 1].term)}
            headingStyle={{ color: '#343C32', fontSize: { xs: '8px', md: '24px' } }}
            containerStyle={{ px: { xs: 2, md: '130px' }, py: 0, mt: { xs: '-20px', md: 0 } }}
            contentStyle={{ width: '100%', alignItems: 'center' }}
          >
            <FlexCol sx={{ position: 'relative', width: { xs: '350px', lg: '1089.32px' } }}>
              <Scale 
                src={terms[terms.length - 1].images[0].url || ''} 
                alt='Pain Scale'
                sx={{ width: { xs: '350px', lg: '1089.32px' } }} 
              /> 
              {result['total'] !== undefined && result['severity'] && 
                <CustomIcon 
                  src={pointer} 
                  sx={{ 
                    position: 'absolute', 
                    left: { xs: result.total !== 0 ? `${-5 + result.total * 19.3}px` : '-5px', lg: result.total !== 0 ? `${-15 + result.total * 60}px` : '-15px' }, 
                    bottom: { xs: '-20px', lg: '-66px' },  
                    width: { xs: "12.48px", lg: '39px' }
                  }} 
                />}
            </FlexCol>
          </CustomSectionContainer>
        }
      </SectionListLayout>}
    </Box>
  );
}
