import React, { useEffect, useState } from "react";
import { Link } from "@mui/material";
import { styled } from "@mui/system";
import { Box, Divider, Grid } from '@mui/material';
import parse, { domToReact, Element, HTMLReactParserOptions } from "html-react-parser";

import { ISectionData } from "./SymptomARD.web";
import ContentBreadcrumbs from './ContentBreadcrumb.web';
import OverviewSection from './OverviewSection.web';
import PlainTextItem from './PlanTextItem.web';
import ItemWithImage from './ItemWithImage.web';
import { OverviewAnnotation } from './MethodOverviewSection.web';
import { CustomSectionContainer, SectionListLayout } from './CustomContainer.web';
import ReactHtmlParser from './ReactHtmlParser';

import { IContentData, ITermDetail } from "../../blocks/contentmanagement/src/ContentManagementController";

const CustomLink = styled(Link)({
  color: '#343C32',
  textDecorationColor: '#343C32'
})

const DementiaMedication = ({ data }: { data: IContentData }) => {
  const { attributes } = data;
  const { title, terms } = attributes;



  const [sectionList, setSectionList] = useState<ISectionData[]>([]);

  useEffect(() => {
    if (terms) {
      terms.sort((a, b) => a.id - b.id); 
      const groupedData: { [key: string]: ITermDetail[] } = {};
      terms.forEach((term: any, index: number) => {
        const key = `${index}`;
        if (!groupedData[key]) {
          groupedData[key] = [];
        }
        groupedData[key].push(term);
      });
      const sections = Object.entries(groupedData).map(group => {
        if (group[1].length > 1) {
          return {
            name: group[0],
            definition: '',
            images: [],
            children: group[1].map(each => {
              return { definition: each.definition, images: each.images }
            })
          }
        }
        return {
          id: group[1][0].id,
          name: group[1][0].term,
          definition: group[1][0].definition,
          images: group[1][0].images,
        }
      })
      setSectionList(sections)
    }
  }, [terms]);
  const renderSectionItems = (htmlString: string) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, 'text/html');
    const listItems = Array.from(doc.querySelectorAll('li')).map(li => li.innerHTML);
    return listItems.map((item, index) => item.includes("title-text") ?  <QuestionTextContainer sx={{ fontSize: { xs: 20, md: 26 }, mb: { xs: 2.5, md: 4 } }}>{ReactHtmlParser(item)}</QuestionTextContainer> : ( <PlainTextItem textStyle={{color: "#343C32" }}
       key={index}>{ReactHtmlParser(item)}</PlainTextItem> ))
  }
  return (
    <SectionListLayout>
      <OverviewSection 
        images={title ? title?.images.map(image => image.url) : []} 
        name={title && parse(title?.title)} 
        shortDesc={title && title?.desc && title?.desc !== '' && parse(title?.desc)}
        containerStyle={{ p: { xs: '20px 16px', md: '40px 189px' }, background: 'transparent' }}
      />
      {sectionList                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                               
        .map((section, index) => (
          <OverviewAnnotation 
          titleStyle={{marginLeft: { xs: '0px', md: '154px' }}}
            title={ReactHtmlParser(section.name)} 
            image={section.images.length > 0 ? section.images[0].url : ''}
            content={renderSectionItems(section.definition)}
            sx={{marginX: {sx: "0px", md:'130px'}, background: "#F2F4F1",padding: '20px', borderRadius: "8px"}}
          />
        ))}
    </SectionListLayout>
  );
}

export default DementiaMedication;
const QuestionTextContainer = styled(Box)({
  fontFamily: 'Lato',
  fontSize: '26px',
  fontWeight: '700',
  color: '#586554',
  '& p': {
    margin: 0
  }
});