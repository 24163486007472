import React from 'react';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles'
import { Box, IconButton } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

const imgFooter = require('./image_auth_footer.png');

interface AuthorizedFooterProps {
  footerFirstSentence: string,
  footerSecondSentence: string,
  navigation: any
}

export default function AuthorizedFooter(props: AuthorizedFooterProps) {
  const { footerFirstSentence, footerSecondSentence, navigation } = props;
const handleNavigateToGlossary = () => {
  navigation.navigate("ContentManagementGlossaries")
}
  return (
    <FooterContainer data-test-id={"authorized-footer"}>
      <FooterContentWrapper>
        <FooterContentBlock flex={1}>
          <FooterImage src={imgFooter} />
          <TextWrapper onClick={handleNavigateToGlossary}>
            <FirstSentenceText>{footerFirstSentence}</FirstSentenceText>
            <SecondSentenceText>{footerSecondSentence}</SecondSentenceText>
          </TextWrapper>
        </FooterContentBlock>
        <FooterContentBlock>
          <GoToDictionaryButton onClick={handleNavigateToGlossary}>
            <ArrowForwardIcon style={{width: "17px", height: "17px", color: "#7C8E76"}}/>
          </GoToDictionaryButton>
        </FooterContentBlock>
      </FooterContentWrapper>
    </FooterContainer>
  );
}

const FooterContainer = styled(Box)({
  background: '#7C8E76',
  width: '100%',
  alignSelf: 'start',
  padding: "10px 24px 10px 24px",
  display: 'flex',
  flexDirection: 'row',
  boxSizing: 'border-box',
  borderRadius: '11.34px',
  '@media (max-width: 900px)': {
    paddingLeft: 0,
    borderRadius: 0
  },
});

const FirstSentenceText = styled(Typography)({
  fontSize: 20,
  fontWeight: "500",
  fontFamily: "Lato",
  color: "#fff"
 })

const SecondSentenceText = styled(Typography)({
  fontSize: 20,
  fontWeight: "500",
  fontFamily: "Lato",
    color: "#fff",
  '@media (max-width: 900px)': {
    display: "none"
  }
})

const FooterImage = styled('img')({
  width: 87,
  height: 87
 })

const FooterContentWrapper = styled(Box)({
  flex: 1,
  display: "flex",
  justifyContent: "space-between",
})

const FooterContentBlock = styled(Box)({
  display: "flex",
  gap: "0.5rem",
  alignItems: "center"
})

const TextWrapper = styled(Box)({
  display: "flex",
  gap: "0.25rem",
  '& *': {
    color: "#FFFFFF",
    fontSize: "20px",
    fontWeight: 500,
    fontFamily: "Lato",
  },
  '&:hover': {
    textDecoration: "underline",
    cursor: "pointer"
  },
})

const GoToDictionaryButton = styled(IconButton)({
 width: 34, height: 34, borderRadius: 34, background: "#fff"
})