import React, { useEffect, useState } from 'react';
import { Box, Divider, Grid } from '@mui/material';
import parse, { domToReact, Element, HTMLReactParserOptions } from 'html-react-parser';

import OverviewSection from './OverviewSection.web';
import PlainTextItem from './PlanTextItem.web';
import ItemWithImage from './ItemWithImage.web';
import { OverviewAnnotation } from './MethodOverviewSection.web';
import { CustomSectionContainer, SectionListLayout, SubcatContainer } from './CustomContainer.web';

import { IContentData, ISectionData, ITermDetail } from "../../blocks/contentmanagement/src/ContentManagementController";

const webStyles = {
  itemWithImageListContainer: {
    marginY: 0,
    borderRadius: '8px', 
    padding: { xs: '20px', md: '40px 20px' }
  }
}

function UnderstandingNonOpioid({ data }: { data: IContentData }) {
  const { attributes } = data;
  const { title, terms } = attributes;

  const parsingOptions: HTMLReactParserOptions = {
    replace(domNode) {
      if (!(domNode instanceof Element)) return;
      const replacementByName: { [key: string]: JSX.Element } = {
        "ul": <>{domToReact(domNode.children, parsingOptions)}</>,
        "li": <PlainTextItem>{domToReact(domNode.children, parsingOptions)}</PlainTextItem>,
      };

      if (!replacementByName[domNode.name]) return;
  
      return replacementByName[domNode.name];
    },
  }

  const [sectionList, setSectionList] = useState<ISectionData[]>([]);

  useEffect(() => {
    if (terms) {
      terms.sort((a, b) => a.id - b.id); 
      const groupedData: { [key: string]: ITermDetail[] } = {};
      terms.forEach(term => {
        const key = term.term;
        if (!groupedData[key]) {
          groupedData[key] = [];
        }
        groupedData[key].push(term);
      });
      const sections = Object.entries(groupedData).map(group => {
        if (group[1].length > 1) {
          return {
            name: group[0],
            definition: '',
            images: [],
            children: group[1].map(each => {
              return { definition: each.definition, images: each.images }
            })
          }
        }
        return {
          id: group[1][0].id,
          name: group[0],
          definition: group[1][0].definition,
          images: group[1][0].images,
        }
      })
      setSectionList(sections)
    }
  }, [terms]);

  return (
    <SectionListLayout>
      <OverviewSection 
        images={title?.images.map(image => image.url) || []} 
        name={parse(title?.title)} 
        shortDesc={title?.desc && title?.desc !== '' && parse(title?.desc)}
        annotation={sectionList.length > 0 &&
          <OverviewAnnotation 
            title={parse(sectionList[0].name)} 
            image={sectionList[0].images.length > 0 ? sectionList[0].images[0].url : ''}
            content={parse(sectionList[0].definition, parsingOptions)}
            titleStyle={{ mb: { xs: 2.5 } }}
            contentStyle={{ gap: { xs: 2 } }}
          />}
      />

      <CustomSectionContainer variant="dark" contentStyle={{ gap: 5 }}>
        {sectionList
          .slice(1)
          .filter(section => section.id)
          .map((section, index) => (
            <Box key={index}>
              <OverviewAnnotation 
                title={parse(section.name)}
                image={section.images.length > 0 ? section.images[0]?.url : ''}
                content={parse(section.definition, parsingOptions)}
                titleStyle={{ color: 'white' }}
                contentStyle={{ gap: section.images.length > 0 ? 1.5 : 2 }}
              />
              {index === 0 && <Divider sx={{ mt: 5, backgroundColor: '#586554' }} />}
            </Box>
        ))}
      </CustomSectionContainer>
      
      <CustomSectionContainer 
        variant="light"
        containerStyle={{ paddingTop: 0, paddingBottom: { xs: 2.5, lg: 0 } }} 
        contentStyle={{ flexDirection: 'row', flexWrap: 'wrap' }} 
      >
        {sectionList
          .filter(section => section?.children && section?.children.length > 0)
          .map((section, index) => 
            <SubcatContainer
              key={index}
              containerStyle={[
                {
                  backgroundColor: index === 0 ? '#F2F4F1' : '#FEF2F2',
                  border: index !== 0 ? '2px dashed #F87171' : '',
                  flexBasis: { xs: '100%', lg: 0 },
                },
                webStyles.itemWithImageListContainer
              ]}
              withHeading
              heading={parse(section.name)}
              headingStyle={{ textAlign: 'center' }}
            >
                <Grid container spacing={2.5}>
                  {section?.children && section?.children.map((child, index) => (
                    <Grid key={index} item xs={12}>
                      <ItemWithImage image={child?.images[0]?.url || ''} text={parse(child?.definition)} />
                    </Grid>
                  ))}
              </Grid>
            </SubcatContainer>)}
      </CustomSectionContainer>
    </SectionListLayout>
  );
}

export default UnderstandingNonOpioid;