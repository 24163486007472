import React, { useEffect, useRef, useState } from 'react'
import { Box, Divider, Grid, IconButton, List, ListItem, ListItemText, Button as MaterialButton, Typography, } from "@mui/material";
import { styled } from "@mui/system";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';


import { Input } from '@builder/component-library';

const searchIcon = require("./searchIcon.svg")
const noResultFound = require("./noResult.svg")

export interface IGlossary {
  id: string
  type: string
  attributes: {
    term: string
    definition: string
  }
}

export type GlossariesProps = {
  data: IGlossary[];
  navigation: any
}

type GlossaryEntries = { [key: string]: any }

const HighlightedText = ({ text, searchTerm, isBold, isTitle }: { text: string; searchTerm: string, isBold: boolean, isTitle?: boolean }) => {
  if (!searchTerm.trim()) {
    return <HighlightedTypo style={{ fontFamily: "Lato", fontWeight: isBold ? "700" : '400', color: isTitle ? "#343C32" : "#586554" }}>{text}</HighlightedTypo>;
  }

  const regex = new RegExp(`(${searchTerm})`, 'gi');
  const parts = text.split(regex);

  return (
    <span>
      {parts.map((part, i) => (
        regex.test(part) ? (
          <HighlightedTypo key={i} style={{
            backgroundColor: '#CBE7C0',
            borderRadius: '4px',
            padding: '2px 4px',
            fontFamily: "Lato",
            fontWeight: isBold ? "700" : '400',
          }}>
            {part}
          </HighlightedTypo>
        ) : (
          <HighlightedTypo style={{
            fontFamily: "Lato",
            fontWeight: isBold ? "700" : '400',
            color: isTitle ? "#343C32" : "#586554"
          }} key={i}>{part}</HighlightedTypo>
        )
      ))}
    </span>
  );
};

function Glossaries({ data, navigation }: GlossariesProps) {
  const [glossaryEntries, setGlossaryEntries] = useState<GlossaryEntries>({});
  const [searchTerm, setSearchTerm] = useState('');
  const [activeSearchTerm, setActiveSearchTerm] = useState('');
  const [suggestions, setSuggestions] = useState<string[]>([]);

  const sectionRefs = useRef<{ [key: string]: HTMLDivElement | null }>({});

  const contentRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const groupedEntries: GlossaryEntries = {};
    data.forEach((entry: IGlossary) => {
      const firstLetter = entry.attributes.term.charAt(0).toUpperCase();
      if (!groupedEntries[firstLetter]) {
        groupedEntries[firstLetter] = [];
      }
      groupedEntries[firstLetter].push(entry);
    });
    setGlossaryEntries(groupedEntries);
  }, [data]);

  const goBack = () => {
    navigation.goBack()
  }
  const goToDashboard = () => {
    navigation.navigate("Catalogue")
  }

  const handleAlphabetClick = (letter: string) => {
    if (sectionRefs.current[letter] && contentRef.current) {
      // Get the top position of the section relative to its scrollable container
      const sectionTop = sectionRefs.current[letter]!.offsetTop;
      // const containerTop = contentRef.current.offsetTop;

      const scrollPosition = sectionTop + 52; // 52px = (Glossary of Hospice Terms).height=32px + padding(20px)

      // Smooth scroll to the section
      contentRef.current.scrollTo({
        top: scrollPosition,
        behavior: 'smooth'
      });
    }
  };



  const handleSearch = (event: any) => {
    const searchTerm = event.target.value;
    setSearchTerm(searchTerm);
    // Filter the glossary entries based on the search term
    const filteredEntries = Object.keys(glossaryEntries).reduce((acc: IGlossary[], letter) => {
      const entries = glossaryEntries[letter].filter((entry: IGlossary) =>
        entry.attributes.term.toLowerCase().includes(searchTerm.toLowerCase()) ||
        entry.attributes.definition.toLowerCase().includes(searchTerm.toLowerCase())
      );
      return [...acc, ...entries];
    }, []);
    // Generate suggestions
    const suggestedTerms = filteredEntries.map((entry) => entry.attributes.term);
    setSuggestions(suggestedTerms);
    setActiveSearchTerm(searchTerm);
  }

  const handleKeyPress = (event: any) => {
    if (event.key === 'Enter') {
      setActiveSearchTerm(searchTerm);
      setSuggestions([]); // Hide suggestions
    }
  };

  const handleSuggestionClick = (suggestion: string) => {
    setSearchTerm(suggestion);
    setActiveSearchTerm(suggestion);
    setSuggestions([]); // Hide suggestions
  };

  const renderBreadcrumbs = () => {
    return <Breadcrumbs>
      <IconButton
        style={{ padding: 0 }}
        data-test-id="button-back"
        onClick={goBack}>
        <ArrowBackIcon style={{ color: "#343C32" }} />
      </IconButton>
      <ButtonBread data-test-id="button-home" onClick={goToDashboard}>
        <BreadCrumbText
          style={{ color: "#96A591", textTransform: "capitalize" }}>Dashboard</BreadCrumbText>
      </ButtonBread>
      <KeyboardArrowRightIcon style={{ color: "#C3CBC0" }} />
      <BreadCrumbText style={{ fontWeight: '700', color: "#343C32" }}>Terms & Definitions</BreadCrumbText>
    </Breadcrumbs>
  }

  const renderSearchBar = () => {
    return (
      <SearchBarWrapper>
        <SearchBarContainer>
          <Input
            textStyle={{ fontFamily: "Lato", color: "#343C32" }}
            data-test-id="search-input"
            value={searchTerm}
            onChange={handleSearch}
            onKeyPress={handleKeyPress}
            containerStyle={{ borderRadius: 100, width: '100%' }}
            labelTextStyle={{ color: "#96A591" }}
            leftIcon={searchTerm ? null : <img src={searchIcon} style={{ width: 24, height: 24 }} />}
            placeholder={'Search for a term or definition'} />
        </SearchBarContainer>
        {searchTerm && suggestions.length > 0 && <SearchSuggestion suggestions={suggestions} onSuggestionClick={handleSuggestionClick} />}
      </SearchBarWrapper>
    )
  }

  const SearchSuggestion = ({ suggestions, onSuggestionClick }: { suggestions: string[], onSuggestionClick: (suggestion: string) => void }) => {
    return (
      <SearchSuggestionContainer>
        <SearchSuggestionHeader>
          <img src={searchIcon} style={{ width: 24, height: 24 }} />
          <Typography variant="h6" fontStyle="italic" style={{ fontFamily: "Lato", fontSize: "16px" }} color="#343C32">Suggestions:</Typography>
        </SearchSuggestionHeader>
        {suggestions.length > 0 ? (
          <>
            {suggestions.map((suggestion) => (
              <SearchSuggestionText key={suggestion} onClick={() => onSuggestionClick(suggestion)}>
                {suggestion}
              </SearchSuggestionText>
            ))}
          </>
        ) : (
          <Typography style={{ fontFamily: "Lato" }} variant="body1">No suggestions found.</Typography>
        )}
      </SearchSuggestionContainer>
    )
  }

  const renderAlphabetButtons = () => {
    const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('');
    return (
      <>
        <MobileAlphabetButtonContainer>
          {alphabet.map((letter) => (
            <MobileAlphabetButton
              key={letter}
              variant="text"
              onClick={() => {
                handleAlphabetClick(letter)
              }}
              disabled={!glossaryEntries[letter]}
            >
              {letter}
            </MobileAlphabetButton>
          ))}
        </MobileAlphabetButtonContainer>
        <AlphabetButtonContainer>
          {alphabet.map((letter) => (
            <AlphabetButton variant="outlined" fullWidth onClick={() => handleAlphabetClick(letter)} disabled={!glossaryEntries[letter]}
              sx={{
                opacity: glossaryEntries[letter] ? 1 : 0.5,
                cursor: glossaryEntries[letter] ? 'pointer' : 'default'
              }}>{letter}</AlphabetButton>
          ))}
        </AlphabetButtonContainer>
      </>
    )
  }

  const isRenderNoResultFound = () => {
    return searchTerm && !suggestions.length && !Object.values(glossaryEntries).flat().some((entry: IGlossary) =>
      entry?.attributes?.term?.toLowerCase()?.includes(activeSearchTerm.toLowerCase()) ||
      entry?.attributes?.definition?.toLowerCase()?.includes(activeSearchTerm.toLowerCase()))
  }

  const renderGlossariesContent = () => {
    return <GlossariesContentContainer ref={contentRef}>
      {
        isRenderNoResultFound() ?
          <Box sx={{ flex: 1, display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
            <img src={noResultFound} />
          </Box>
          : <>
            <Typography variant="h5" sx={{ fontWeight: "700", fontFamily: "Lato", color: "#0F172A", fontSize: { xs: '22px', lg: "24px" } }}>Glossary of Hospice Terms</Typography>
            <List style={{ padding: 0 }}>
              {Object.keys(glossaryEntries).map((letter) => (
                <GlossariesItems letter={letter} searchTerm={activeSearchTerm} />
              ))}
            </List>
          </>}
    </GlossariesContentContainer>
  }

  const GlossariesItems = ({ letter, searchTerm }: { letter: string, searchTerm: string }) => {
    const filteredEntries = glossaryEntries[letter].filter((entry: IGlossary) =>
      entry.attributes.term.toLowerCase().includes(searchTerm.toLowerCase()) ||
      entry.attributes.definition.toLowerCase().includes(searchTerm.toLowerCase())
    );
    if (!filteredEntries.length) {
      return null;
    }
    return <GlossariesItemContainer key={letter} ref={el => sectionRefs.current[letter] = el}>
      <GlossariesContentTypography>{letter}</GlossariesContentTypography>
      {filteredEntries.map((entry: any, index: any) => (
        <ListItem key={index} sx={{ padding: "0" }}>
          <ListItemText
            primary={<HighlightedText isBold={true} text={entry.attributes.term} searchTerm={activeSearchTerm} isTitle />}
            secondary={<HighlightedText isBold={false} text={entry.attributes.definition} searchTerm={activeSearchTerm} />}
          />
        </ListItem>
      ))}
    </GlossariesItemContainer>
  }

  return (
    <GlossariesContainer>
      <HeaderContainer>
        {renderBreadcrumbs()}
        {renderSearchBar()}
      </HeaderContainer>
      {renderAlphabetButtons()}
      <Divider sx={{ borderColor: "#bdc7ba", display: { xs: 'none', lg: "block" } }} />
      {renderGlossariesContent()}
    </GlossariesContainer>
  )
}

export default Glossaries


const GlossariesContainer = styled(Box)({
  flex: 1,
  display: "flex",
  flexDirection: "column",
  backgroundColor: "#FAF9F6",
  overflowY: "hidden"
});


const HeaderContainer = styled(Box)({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  backgroundColor: "#FAF9F6",
  height: "64px",
  "@media (max-width: 899px)": {
    flexDirection: "column",
    gap: "12px",
    padding: "10px 16px",
    height: "auto",
  }
});



const Breadcrumbs = styled('div')({
  display: 'flex',
  columnGap: "10px",
  alignItems: 'center',
  paddingTop: "4px",
  paddingBottom: "4px",
  "@media (max-width: 899px)": {
    width: "100%"
  }
})

const ButtonBread = styled(MaterialButton)({
  padding: '4px 8px',
  borderRadius: '6px',
  '&:hover': {
    backgroundColor: '#F1F5F9',
  },
});

const BreadCrumbText = styled(Typography)({
  fontSize: "22px",
  fontFamily: "Lato",
  color: "#0F172A",
  "@media (max-width: 900px)": {
    fontSize: "14px",
  },
})



const SearchBarWrapper = styled('div')({
  position: "relative",
  "@media (max-width: 899px)": {
    width: "100%",
  }
})

const SearchBarContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'row',
  height: '44px',
  width: "538px",
  "@media (max-width: 899px)": {
    width: "100%",
  }
})

const AlphabetButtonContainer = styled(Box)({
  height: "56px",
  width: "100%",
  display: 'grid',
  gridTemplateColumns: 'repeat(26, 1fr)',
  gap: "4px",
  marginBottom: '4px',
  "@media (max-width: 1232px)": {
    display: 'none'
  }
})

const AlphabetButton = styled(MaterialButton)({
  fontFamily: "Lato",
  borderRadius: '2px',
  minWidth: "unset",
  color: "#7C8E76",
  border: "0.5px solid #d6dcd5",
  fontWeight: "700",
  '&:hover': {
    backgroundColor: '#F1F5F9',
  },
});
const MobileAlphabetButtonContainer = styled(Box)({
  flexDirection: 'column',
  position: 'fixed',
  right: 0,
  top: '50%',
  transform: 'translateY(-50%)',
  maxHeight: "50vh",
  display: 'none',
  width: "21px",
  "@media (max-width: 1232px)": {
    display: 'flex',
  },
})

const MobileAlphabetButton = styled(MaterialButton)({
  fontSize: '12px',
  fontWeight: "700",
  color: "#000",
  borderRadius: 0,
  padding: 0,
  minWidth: 'unset',
  "@media (min-width: 900px) and (max-width: 1232px)": {
    fontSize: "14px"
  }
});
const GlossariesContentContainer = styled('div')({
  paddingTop: "20px",
  width: "1160px",
  margin: '0 auto',
  overflowY: "auto",
  flex: 1,
  "&::-webkit-scrollbar": {
    display: "none"  // Safari and Chrome
  },
  "-ms-overflow-style": "none",  // IE and Edge
  "scrollbarWidth": "none",  // Firefox
  "@media (max-width: 1232px)": {
    width: "unset",
    marginLeft: "16px",
    marginRight: "24px"
  }
});
const GlossariesContentTypography = styled(Typography)({
  width: "100%",
  display: "flex",
  fontFamily: "Lato",
  justifyContent: "center",
  alignItems: "center",
  background: "#F2F4F1",
  borderRadius: '8px',
  color: "#7c8e76",
  fontWeight: "700",
  fontSize: "28px",
  marginBottom: '10px',
  "@media (max-width: 899px)": {
    fontSize: "22px"
  }
});

const GlossariesItemContainer = styled('div')({
  marginTop: "16px"
});


const SearchSuggestionContainer = styled(Box)({
  marginTop: "4px",
  position: "absolute",
  borderRadius: "8px",
  border: "1px solid #CBD5E1",
  background: "#fff",
  zIndex: '101',
  padding: "10px 8px",
  display: "flex",
  flexDirection: "column",
  gap: "8px",
  right: 0,
  left: 0
})

const SearchSuggestionHeader = styled(Box)({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: "8px"
})



const SearchSuggestionText = styled(Typography)({
  borderBottom: "1px solid #D6DCD5",
  padding: "4px 12px",
  borderRadius: "4px",
  cursor: "pointer",
  fontFamily: "Lato",
  '&:hover': {
    color: "#fff",
    backgroundColor: '#7C8E76',
  },
})


const HighlightedTypo = styled('span')({
  fontSize: "18px",
  "@media (max-width: 899px)": {
    fontSize: "16px"
  }
})


