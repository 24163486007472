import React, { useEffect, useState } from "react";
import { Stack, Typography } from "@mui/material";
import { styled } from '@mui/system';
import parse, { domToReact, Element, HTMLReactParserOptions } from 'html-react-parser';

// import { BreadcrumbsV2 } from "./ContentBreadcrumb.web";
import OverviewSection from "./OverviewSection.web";
import PlainTextItem from "./PlanTextItem.web";
import ItemWithImage from "./ItemWithImage.web";
import { OverviewAnnotation } from "./MethodOverviewSection.web";
import { CustomSectionContainer, SectionListLayout, SubcatContainer } from "./CustomContainer.web";
import { ISectionData } from "./SymptomARD.web";
import { checkTagExisted } from "./ReactHtmlParser";

import { checkIcon, closeIcon } from "../../blocks/contentmanagement/src/assets";
import { IContentData, ITermDetail } from "../../blocks/contentmanagement/src/ContentManagementController";

const NormalText = styled(Typography)({
  fontWeight: '400',
  fontFamily: 'Lato',
  color: '#343C32',
});

const webStyles = {
  alertContainer: { backgroundColor: '#FEF2F2', border: '2px dashed #F87171' },
  doAndDontContainer: {
    marginTop: '-20px', 
    flexWrap: 'wrap', 
    gap: 2.5
  }
}

const MovingPatient = ({ data }: { data: IContentData }) => {
  const { attributes } = data;
  const { title, terms } = attributes;

  const options: HTMLReactParserOptions = {
    trim: true,
    replace(domNode) {
      if (!(domNode instanceof Element)) return;
      const replacementByName: { [key: string]: JSX.Element } = {
        "ul": (
          <Stack direction="column" sx={{ gap: '12px' }}>
            {domToReact(domNode.children, options)}
          </Stack>
        ),
        "li": <PlainTextItem>{domToReact(domNode.children, options)}</PlainTextItem>,
        "table": <></>,
        "p": <NormalText sx={{ fontSize: { xs: '16px', md: '22px' }, color: '#586554' }}>{domToReact(domNode.children, options)}</NormalText>
      };

      if (!replacementByName[domNode.name]) return;
  
      return replacementByName[domNode.name];
    }
  }

  const [sectionList, setSectionList] = useState<ISectionData[]>([]);
  
  useEffect(() => {
    if (terms) {
      terms.sort((a, b) => a.id - b.id); 
      const groupedData: { [key: string]: ITermDetail[] } = {};
      terms.forEach(term => {
        const key = term.term;
        if (!groupedData[key]) {
          groupedData[key] = [];
        }
        groupedData[key].push(term);
      });
      const sections = Object.entries(groupedData).map(group => {
        if (group[1].length > 1) {
          return {
            name: group[0],
            definition: '',
            images: [],
            children: group[1].map(each => {
              return { definition: each.definition, images: each.images }
            })
          }
        }
        return {
          id: group[1][0].id,
          name: group[0],
          definition: group[1][0].definition,
          images: group[1][0].images,
        }
      })
      setSectionList(sections)
    }
  }, [terms]);

  const extractTableData = (htmlString: string) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, 'text/html');

    // Extract table rows
    const rows = Array.from(doc.querySelectorAll('table tr'));
    const extractedData = rows.map(row => Array.from(row.querySelectorAll('td, th')).map(cell => (cell && cell?.innerHTML && cell?.innerHTML?.trim()) || ''));

    return(extractedData);
  }

  return (
    <>
      {/* <BreadcrumbsV2 stacks={['Dashboard', 'Basic Care', 'Transferring / moving patient']} onNavigate={onNavigate} /> */}
      <SectionListLayout>
        <OverviewSection 
          images={title?.images.map(image => image.url)} 
          name={parse(title?.title)} 
          shortDesc={title?.desc && title?.desc !== '' && parse(title?.desc)}
          containerStyle={{ backgroundColor: 'transparent' }} 
        />
        <CustomSectionContainer variant="light" containerStyle={{ px: { xs: 0, md: '130px' }, py: 0 }}>
          {sectionList.map((section, index) => {
            if (section.id && section.images.length > 0) {
              return (
                <CustomSectionContainer key={index} containerStyle={{ p: { xs: 2.5 } }}>
                  <OverviewAnnotation 
                    title={parse(section.name)} 
                    content={parse(section.definition, options)}
                    image={section.images[0]?.url || ''} 
                    titleStyle={{ mb: { xs: 2.5 } }}
                  />
                </CustomSectionContainer>
              )
            }
            if (checkTagExisted(section.definition, 'table')) {
              const data = extractTableData(section.definition);
              return (
                <CustomSectionContainer 
                  key={index} 
                  withHeading
                  heading={parse(section.name)}
                  variant="light" 
                  containerStyle={{ p: { xs: '40px 20px', md: '40px 0' } }}
                  contentStyle={{ alignItems: 'center' }}
                >
                 {parse(section.definition, options)}
                  <Stack direction="row" sx={webStyles.doAndDontContainer}>
                    {[...Array(data[0].length).keys()].map((num) => 
                      <SubcatContainer
                        key={num}
                        withHeading
                        heading={parse(data[0][num])}
                        headingStyle={{ textAlign: 'center' }}
                        containerStyle={{ my: 0, flexBasis: { xs: '100%', md: 0 } }}
                      >
                        {data.slice(1).map((entry, index) => 
                          <ItemWithImage 
                            key={index} 
                            image={num === 0 ? checkIcon : closeIcon} 
                            text={<NormalText sx={{ fontSize: { xs: '16px', md: '22px' }}}>{parse(entry[num], options)}</NormalText>} 
                            style={{ height: { xs: 'fit-content', md: '120px' } }}
                            imageSize={24}  
                          />)}
                      </SubcatContainer>)}
                  </Stack>
                </CustomSectionContainer>)}
            return (
              <CustomSectionContainer 
                key={index} 
                containerStyle={{ p: { xs: 2.5 }, ...webStyles.alertContainer }}
              >
                {parse(section.definition, options)}
              </CustomSectionContainer>
            )
          })}
        </CustomSectionContainer>
      </SectionListLayout>
    </>
  );
}

export default MovingPatient;
