import React, { useEffect, useState } from "react";
import { Grid, Link, Stack } from "@mui/material";
import { styled } from '@mui/system';
import parse, { domToReact, Element, HTMLReactParserOptions } from 'html-react-parser';

import ContentBreadcrumbs from "./ContentBreadcrumb.web";
import OverviewSection from "./OverviewSection.web";
import PlainTextItem from "./PlanTextItem.web";
import ItemWithImage from "./ItemWithImage.web";
import { CustomSectionContainer, SectionListLayout } from "./CustomContainer.web";
import { ISectionData } from "./SymptomARD.web";
import { extractTextNodeContent } from "./ReactHtmlParser";

import { IContentData, ITermDetail } from "../../blocks/contentmanagement/src/ContentManagementController";

const CustomLink = styled(Link)({
  color: '#343C32',
  textDecorationColor: '#343C32'
})

const Infection = ({ data }: { data: IContentData }) => {
  const { attributes } = data;
  const { title, terms } = attributes;

  const parsingOptions: HTMLReactParserOptions = {
    replace(domNode) {
      if (!(domNode instanceof Element)) return;
      const replacementByName: { [key: string]: JSX.Element } = {
        "ul": <Stack direction="column" sx={{ p: 2.5, gap: 1.5 }}>{domToReact(domNode.children, parsingOptions)}</Stack>,
        "li": <PlainTextItem>{domToReact(domNode.children, parsingOptions)}</PlainTextItem>,
        "a": <CustomLink href={domNode.attribs['href']} target="_blank">{domToReact(domNode.children, parsingOptions)}</CustomLink>
      };

      if (!replacementByName[domNode.name]) return;
  
      return replacementByName[domNode.name];
    }
  }

  const [sectionList, setSectionList] = useState<ISectionData[]>([]);

  useEffect(() => {
    if (terms) {
      terms.sort((a, b) => a.id - b.id); 
      const groupedData: { [key: string]: ITermDetail[] } = {};
      terms.forEach(term => {
        const key = term.term;
        if (!groupedData[key]) {
          groupedData[key] = [];
        }
        groupedData[key].push(term);
      });
      const sections = Object.entries(groupedData).map(group => {
        if (group[1].length > 1) {
          return {
            name: group[0],
            definition: '',
            images: [],
            children: group[1].map(each => {
              return { definition: each.definition, images: each.images }
            })
          }
        }
        return {
          id: group[1][0].id,
          name: group[0],
          definition: group[1][0].definition,
          images: group[1][0].images,
        }
      })
      setSectionList(sections)
    }
  }, [terms]);

  return (
    <SectionListLayout>
      <OverviewSection 
        images={title?.images.map(image => image.url) || []} 
        name={parse(title?.title)} 
        shortDesc={title?.desc && extractTextNodeContent(title?.desc) !== '' && parse(title?.desc)}
        containerStyle={{ p: { xs: '20px 16px', md: '40px 189px' } }} 
      />
      {sectionList.map((section, index) => {
        if (section.children && section.children.length > 0) {
          return (
            <CustomSectionContainer
              key={index}
              withHeading={extractTextNodeContent(section.name) !== ''}
              heading={extractTextNodeContent(section.name) !== '' && parse(section.name)}
              headingStyle={{ textAlign: 'center' }}
            >
              <Grid container spacing={2.5}>
                {section.children.map((child, index) => (
                  <Grid item key={index} xs={12}>
                    <ItemWithImage
                      key={index} 
                      image={child.images[0].url || ''} 
                      text={parse(child.definition)}
                      imageSize={120}
                      style={{ height: '100%' }}
                    />
                  </Grid>
                ))}
              </Grid>
            </CustomSectionContainer>
          )
        }
        return (
          <CustomSectionContainer
            key={index}
            withHeading={extractTextNodeContent(section.name) !== ''}
            heading={extractTextNodeContent(section.name) !== '' && parse(section.name)}
          >
            {parse(section.definition, parsingOptions)}
          </CustomSectionContainer>
        )
      })}
    </SectionListLayout>
  );
}

export default Infection;
