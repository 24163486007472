import React from 'react'
import Modal from '@mui/material/Modal';
import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import MaterialButton from '@mui/material/Button';
type FailedRenewalPaymentProps = {
    isOpen: boolean,
    handleClose: () => void
    navigation: any,
    handleOpenPaymentForm: () => void
}
function FailedRenewalPayment(props: FailedRenewalPaymentProps) {
    const onClickRetryPayment = () => {
        localStorage.setItem("create_payment_flow", "retry")
        props.handleOpenPaymentForm()
    }
    const navigateToContactUs = () => {
        localStorage.setItem("contactus_breadcrumb", "Login")
        props.navigation.navigate("Contactus")
    }
    return (
        <Modal
            open={props.isOpen}
            onClose={props.handleClose}
            aria-labelledby="information-modal-title"
            aria-describedby="information-modal-description"
        >
            <ModalContainer>
                <ModalHeader>
                Failed Renewal Payment
                </ModalHeader>
                <ModalContentContainer>
                    <ConditionsText>
                    We wanted to let you know that your recent payment for the renewal of your subscription was unsuccessful. Please update your payment details to avoid any disruption to your subscription.
                    </ConditionsText>
                    <ConditionsText style={{marginTop: "32px"}}>
                    If you need help, feel free to <span onClick={navigateToContactUs} style={{fontWeight: '700', color: "#586554", textDecoration: 'underline', cursor: 'pointer'}}>Contact Us</span>
                    </ConditionsText>
                </ModalContentContainer>
                <ModalControlsContainer>
                    <ButtonClose onClick={props.handleClose}>Close</ButtonClose>
                    <ButtonRenewal onClick={onClickRetryPayment}>Retry Payment</ButtonRenewal>
                </ModalControlsContainer>
            </ModalContainer>
        </Modal>
    )
}
const ModalContainer = styled(Box)({
    background: "#fff",
    display: "flex",
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    flex: 1,
    flexDirection: "column",
    width: "600px",
    borderRadius: "8px",
    borderBottomRightRadius: "32px",
    overflow: "hidden",
    '@media (max-width: 600px)': {
        width: "343px"
    }
})
const ModalHeader = styled(Typography)({
    padding: "24px 40px 24px 40px",
    borderBottom: "1px solid #D6DCD5",
    fontFamily: "Lato",
    fontSize: "24px",
    fontWeight: 700,
    lineHeight: "32px",
    color: "#343C32",
    '@media (max-width: 600px)': {
        fontSize: "20px",
        padding: "24px 16px 24px 16px",
    }
})

const ModalContentContainer = styled(Box)({
    flex: 1,
    padding: "40px",
    overflow: "auto",
    '@media (max-width: 600px)': {
        padding: "32px 16px 32px 16px",
    }
})
const ConditionsText = styled(Typography)({
    fontFamily: "Lato",
    fontSize: "18px",
    fontWeight: 400,
    lineHeight: "24px",
    marginBottom: '8px',
    color: "#343C32"
})

const ModalControlsContainer = styled(Box)({
    display: "flex",
    padding: "30px 16px 30px 0px",
    borderTop: "1px solid #D6DCD5",
    justifyContent: "end",
})

const ButtonRenewal = styled(MaterialButton)({
    backgroundColor: "#7C8E76",
    padding: "10px 16px 10px 16px",
    borderRadius: "8px",
    fontFamily: "Lato",
    fontSize: "16px",
    fontWeight: 700,
    color: "#FFFFFF",
    textTransform: "capitalize",
    "&:hover": {
        cursor: "pointer",
        backgroundColor: "#7C8E76",
        color: "#FFFFFF",
    }
});
const ButtonClose = styled(MaterialButton)({
    backgroundColor: "#fff",
    padding: "10px 20px 10px 20px",
    borderRadius: "8px",
    fontFamily: "Lato",
    fontSize: "16px",
    fontWeight: 700,
    color: "#586554",
    marginRight: "16px",
    border: "1px solid #D6DCD5",
    textTransform: "capitalize",
    "&:hover": {
        cursor: "pointer",
        backgroundColor: "#fff",
        color: "#586554",
    }
});
export default FailedRenewalPayment