import React from "react";
// Customizable Area Start
import { Box, Typography,IconButton } from "@mui/material";
import {
  createTheme,
  ThemeProvider,
  StyledEngineProvider,
  styled,
} from "@mui/material/styles";
import {
  categoryNavigateButtonIcon,
  categoryNavigateButtonIconHover,
} from "./assets";
import AuthorizedHeader from "../../../../packages/components/src/AuthorizedHeader";
import AuthorizedFooter from "../../../../packages/components/src/AuthorizedFooter";
import ContactHospice from "../../../../packages/components/src/ContactHospice";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
// Customizable Area End
// Customizable Area Start
import CatalogueController, { ICatalogueItem, Props, configJSON  } from "./CatalogueController";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});
// Customizable Area End

export default class Catalogue extends CatalogueController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderCatalogItem = (item: ICatalogueItem) => {
    return  <CatalogueItem onClick={() => {this.navigateToCategory(item.id)}} key={item.id.toString()} data-test-id="catalogue-item">
    <CatalogueItemImage src={item.attributes.image.url} />
    <CatalogueItemBottomPanel>
      <CatalogueItemText>{item.attributes.name}</CatalogueItemText>
      <GoToDictionaryButton>
<ArrowForwardIcon style={{width: "17px", color: "#FFFFFF", height: "17px",}}/>
</GoToDictionaryButton>
    </CatalogueItemBottomPanel>
  </CatalogueItem>
  }
  // Customizable Area End

  render() {
    return (
      //Merge Engine DefaultContainer
      // Customizable Area Start
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <PageContainer>
            <ContactHospice/>

            <LayoutContainer>
              <AuthorizedHeader
                  navigation={this.props.navigation}
                data-test-id={"authorized-header"}
                isHideHome
              />

              <MainContainer>
                <CatalogueRow>
                  {this.getCatalogueItems(0, 3).map((item: ICatalogueItem) => this.renderCatalogItem(item))}
                </CatalogueRow>
                <CatalogueRow>
                  {this.getCatalogueItems(3, null).map((item: ICatalogueItem) => this.renderCatalogItem(item))}
                </CatalogueRow>
              </MainContainer>

              <AuthorizedFooter
                footerFirstSentence={configJSON.footerFirstSentenceText}
                footerSecondSentence={configJSON.footerSecondSentenceText}
                navigation={this.props.navigation}
                data-test-id={"authorized-footer"}
              />
            </LayoutContainer>
          </PageContainer>
        </ThemeProvider>
      </StyledEngineProvider>
      // Customizable Area End
      //Merge Engine End DefaultContainer
    );
  }
}

// Customizable Area Start




const PageContainer = styled(Box)({
  flex: 1,
  display: "flex",
  flexDirection: "column",
  backgroundColor: "#FAF9F6",
});

const LayoutContainer = styled(Box)({
  flex: 1,
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  gap: "30px",
  boxSizing: "border-box",
  padding: "20px 20px 14px 20px",
  "@media (max-width: 900px)": {
    padding: "20px 0px 0px 0px",
  },
  alignItems: "center",
});

const MainContainer = styled("main")({
  flex: 1,
  display: "flex",
  flexDirection: "column",
  gap: "30px",
  width: "85%",
});

const CatalogueRow = styled(Box)({
  display: "flex",
  flex: 1,
  justifyContent: "space-between",
  gap: "30px",
  "@media (max-width: 600px)": {
    flexDirection: "column",
  },
  flexWrap: "wrap",
});

const CatalogueItem = styled("article")({
  flex: 1,
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  backgroundColor: "#F2F4F1",
  color: "#343C32",
  border: "1px solid rgba(124, 142, 118, 0.5)",
  borderRadius: "12px",
  padding: "0.75rem",
  ":hover": {
    backgroundColor: "#444E41",
    color: "#FFFFFF",
    cursor: "pointer",
  },
  "&:hover .buttonImage": {
    backgroundImage: `url(${categoryNavigateButtonIconHover})`,
  },
});

const CatalogueItemImage = styled("img")({
  flex: 1,
  width: "180px",
  height: "180px",
  objectFit: "contain",
});

const CatalogueItemBottomPanel = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
});

const CatalogueItemText = styled(Typography)({
  fontFamily: "Lato",
  fontSize: "22px",
  fontWeight: 700,
  letterSpacing: "-0.8px",
});

const GoToDictionaryButton = styled(IconButton)({
  width: 28, height: 28, borderRadius: 34, background: "#343C32"
 })
const CatalogueItemButtonImage = styled(Box)({
  backgroundImage: `url(${categoryNavigateButtonIcon})`,
  backgroundSize: "cover",
  width: "28px",
  height: "28px",
  "@media (max-width: 600px)": {
    width: "36px",
    height: "36px",
  },
});
// Customizable Area End
