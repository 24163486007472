// Customizable Area Start
import React from "react";

import {
  Box,
  Button as MaterialButton,
  IconButton,
  Typography,
  styled,
} from "@mui/material";


import UnauthorizedHeader from "../../../components/src/UnauthorizedHeader";


import ContactusController, { Props, configJSON } from "./ContactusController";
import AuthorizedHeader from "../../../components/src/AuthorizedHeader";
import AuthorizedFooter from "../../../components/src/AuthorizedFooter";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import {  imgBackground } from "./assets"
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
const config = require("../../../framework/src/config");
// Customizable Area End


export default class ContactInfo extends ContactusController {
  
  hospiceData: any
  stateList: any;
  constructor(props: Props) {
    super(props);
    const currentUserData = localStorage.getItem("caregiver_data") ?? ""
    const currentData = JSON.parse(currentUserData)
    const patientData = currentData?.attributes ?? ""
    const hospiceData = patientData?.hospice?.attributes ?? ""
    this.hospiceData = hospiceData
  }

  getHospiceLogo = () => {
    if(this.hospiceData && this.hospiceData !== ""){
      return this.hospiceData.hospice_logo ? `${config.baseURL}/${this.hospiceData.hospice_logo}` : require('../../../components/src//hospice_logo.png')
    }
    return require('../../../components/src/hospice_logo.png')
  }

  renderContactUsForm = () => {
    const {hospice_name,phone_number,state, city, address, postal_code, website } = this.hospiceData
    return (

      <SecondFormStepContainer>
        <AvatarMediaBox>
          <img
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'cover',
            }}
            src={this.getHospiceLogo()}
          />
        </AvatarMediaBox>


        <FormRow>
          <LabelText>Hospice Name</LabelText>
          <ValueText>{hospice_name}</ValueText>
        </FormRow>

        <FormRow>
          <LabelText>Contact Number</LabelText>
          <ValueText>{phone_number.toString().replace(/(\d{3})(?=\d{4})/g, '$1-')}</ValueText>
        </FormRow>

        <FormRow>
          <LabelText>Website</LabelText>
          <ValueText>{website}</ValueText>
        </FormRow>

        <FormRow>
          <LabelText>Address</LabelText>
          <ValueText>{address}</ValueText>
        </FormRow>

        <FormRow>
          <LabelText>City</LabelText>
          <ValueText>{city}</ValueText>
        </FormRow>

        <FormRow>
          <LabelText>State</LabelText>
          <ValueText>{state}</ValueText>
        </FormRow>

        <FormRow>
          <LabelText>Zip Code</LabelText>
          <ValueText>{postal_code}</ValueText>
        </FormRow>
      </SecondFormStepContainer>
    )
  }
  renderHeader = () => {
    const isAuthen = localStorage.getItem("token")
    if (isAuthen) {
      return <AuthorizedHeader
        navigation={this.props.navigation}
        data-test-id={"authorized-header"}
      />
    } else {
      return <UnauthorizedHeader />
    }
  }
  renderBreadcrumbs = () => {
    const containerStyle = { display: 'flex', columnGap: 10, alignItems: 'center', paddingLeft: 10, paddingTop: 4, paddingBottom: 4, borderBottom: '1px solid #F2F4F1' }
    return <div style={{ ...containerStyle, flexDirection: 'row' }}>
      <IconButton
        data-test-id="button-back"
        onClick={() => {
          this.props.navigation.goBack()
        }
        }>
        <ArrowBackIcon style={{ color: "#343C32" }} />
      </IconButton>
      <ButtonBread data-test-id="button-home" onClick={() => {
        this.props.navigation.navigate("Catalogue")
      }
      }>
        <BreadCrumbText
          style={{ color: "#475569", textTransform: "capitalize" }}>Dashboard</BreadCrumbText>
      </ButtonBread>
      <KeyboardArrowRightIcon style={{ color: "#C3CBC0" }} />
      <BreadCrumbText style={{ fontWeight: '700' }}>Hospice Contact Info</BreadCrumbText>
    </div>
  }
  render() {
    return (
      <Container>
        {this.renderHeader()}
        {this.renderBreadcrumbs()}
        <SignUpPageContainer>
          {this.renderContactUsForm()}
          <CardMediaBox>
            <img
              style={{
                width: '100%',
                borderBottomLeftRadius: 45, objectFit: 'cover',
                overflow: "hidden"
              }}
              src={imgBackground}
              alt="A person holds the hand of his elderly relative"
            />
          </CardMediaBox>
        </SignUpPageContainer>
        <AuthorizedFooter
          footerFirstSentence={configJSON.footerFirstSentenceText}
          footerSecondSentence={configJSON.footerSecondSentenceText}
          navigation={this.props.navigation}
          data-test-id={"authorized-footer"}
        />
      </Container>
    );
  }
}

// Customizable Area Start
const SignUpPageContainer = styled(Box)({
  display: "flex",
  flex: 1,
  flexDirection: 'row',
  backgroundColor:"#F2F4F1",
  borderRadius: 8,
  marginTop: '32px',
  marginBottom: '32px',
  "@media (max-width: 900px)": {
    flexDirection: "column-reverse",
    gap: '20px',
    marginTop: '0px',
    marginBottom: '0px',
    paddingLeft: '16px',
    paddingRight: "16px"
  },

});

const CardMediaBox = styled(Box)({
  display: "flex",
  flex: 1,
});

const FormRow = styled(Box)({
  display: "flex",
  flexDirection: "row",
  "@media (max-width: 900px)": {
    flexDirection: "column",
    gap: '10px'
  },
});
const SecondFormStepContainer = styled(Box)({
  boxSizing: 'border-box',
  flex: 1,
  padding: "20px",
  display: "flex",
  flexDirection: "column",
  gap: "32px",
  "@media (max-width: 900px)": {
    gap: '20px'
  },
});

const ButtonBread = styled(MaterialButton)({
  padding: '4px 8px',
  borderRadius: '6px',
  '&:hover': {
    backgroundColor: '#F1F5F9',
  },
});
const BreadCrumbText = styled(Typography)({
  fontSize: 16,
  fontFamily: "Lato",
  color: "#0F172A",
  "@media (max-width: 900px)": {
    fontSize: 14
  },
})
// Customizable Area End


const AvatarMediaBox = styled(Box)({
  width: "100px",
  height: "100px",
  borderRadius: "100px",
  borderWidth: 2,
  borderStyle: 'solid',
  borderColor: "#A7B3A3",
  overflow: "hidden"
});

const LabelText = styled(Typography)({
  fontSize: 18,
  fontWeight: '700',
  fontFamily: "Lato",
  color: "#0F172A",
  width: "168px"
})

const ValueText = styled(Typography)({
  fontSize: 18,
  fontWeight: '500',
  fontFamily: "Lato",
  color: "#586554",
  wordBreak: 'break-word', 
  overflowWrap: 'break-word', 
})

const Container = styled(Box)({
  display: 'flex', 
  flex: 1, 
  flexDirection: 'column',
  padding: '20px',
  background: "#FAF9F6",
  "@media (max-width: 900px)": {
    padding:'0px'
  },

})

