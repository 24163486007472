import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
const config = require("../../../framework/src/config");
import { catalogueItems } from "./assets";
export interface ICatalogueItem {
  id: string
  type: string
  attributes: {
    id: number
    name: string
    image: {
      id: number
      url: string
    }
  }
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  arrayHolder: any;
  token: string;
  // Customizable Area Start
  isUserMenuActive: boolean;
  hospiceName: string;
  hospiceNumber: string;
  patientName: string;
  caregiverName: string;
  loading: boolean,
  listCatalogue: ICatalogueItem[]
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class CatalogueController extends BlockComponent<Props, S, SS> {
  getProductApiCallId: any;
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      arrayHolder: [],
      token: "",
      isUserMenuActive: false,
      hospiceName: configJSON.defaultHospiceName,
      hospiceNumber: configJSON.defaultHospiceNumber,
      patientName: configJSON.defaultPatientName,
      caregiverName: configJSON.defaultCaregiverName,
      loading: true,
      listCatalogue:[]
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    this.getToken();
    // Customizable Area End
  }
  // Customizable Area Start
  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  getListRequest = (token: any) => {
    const header = {
      "Content-Type": configJSON.productApiContentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getProductApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.productAPiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  // Customizable Area End
  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog(message);
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token =  localStorage.getItem("token") ?? ""
        this.setState({ token:  token  }, () => {
        this.getListRequest(token)
      });
    }
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      this.handleResponseMessage(message)
    }
    // Customizable Area End
  }

  // Customizable Area Start
  handleResponseMessage = (message: Message) => {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    const errorReponse = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    if(errorReponse){
      this.setState({loading: false})
      const errorString = JSON.stringify(errorReponse.errors)
      if(errorString.includes('token')){
        this.props.navigation.navigate("EmailAccountLoginBlock")
        return
      }
      return
    }
    if(responseJson.errors){
      this.setState({loading: false})
      this.parseApiCatchErrorResponse(responseJson.errors);
      return
    }
    if(apiRequestCallId === this.getProductApiCallId){
      this.setState({loading: false, listCatalogue: responseJson.data})
    }
  }
  toggleUserMenu = () => {
    this.setState({ isUserMenuActive: !this.state.isUserMenuActive });
  };

  getCatalogueItems = (startWith: number, endWith: number | null) => {
    const listCatalogueTemp = [...this.state.listCatalogue]
    if(listCatalogueTemp.length === 0){
      return []
    }
    if (endWith) {
      return listCatalogueTemp.slice(startWith, endWith);
    }

    return listCatalogueTemp.slice(startWith, catalogueItems.length);
  };
  navigateToCategory = (catalogueId: string) => {
    this.props.navigation.navigate("Categories", {id: catalogueId})
  }
  // Customizable Area End
}
