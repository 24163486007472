import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import  {  createRef } from 'react';
import {  FormikErrors, FormikProps } from 'formik';
interface ResponseJson {
  meta: {
    token: string;
  };
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  password: string;
  email: string;
  enablePasswordField: boolean;
  checkedRememberMe: boolean;
  loading: boolean;
  isCaregiverLogin: boolean;
  phoneNumber: string;
  phoneNumberError: string | null;
  isOpenModalInformation: boolean;
  isOpenModalReachLimit: boolean;
  numberOfHospice: string
  errorMessage:string | null;
  successMessage:string | null;
  videoUrl: string;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class EmailAccountLoginController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiEmailLoginCallId: string = "";
  apiPhoneLoginCallId: string = "";
  apiCheckSubscriptionCallId: string = "";
  apiCheckCaregiverSubscriptionCallId: string = "";
  fetchingVideoApiCallId: string = "";
  validationApiCallId: string = "";
  emailReg: RegExp;
  labelTitle: string = "";
  formikRef = createRef<FormikProps< { email: string; password: string;  }>>();
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
    ];

    this.state = {
      email: "",
      password: "",
      enablePasswordField: true,
      checkedRememberMe: false,
      loading:false,
      isCaregiverLogin: false,
      phoneNumber: "",
      phoneNumberError: null,
      isOpenModalInformation: false,
      isOpenModalReachLimit: false,
      errorMessage: null,
      successMessage: null,
      numberOfHospice: "",
      videoUrl: ""
    };

    this.emailReg = new RegExp("");
    this.labelTitle = configJSON.labelTitle;
    this.formikRef = createRef();
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    this.callGetValidationApi();
    this.send(new Message(getName(MessageEnum.RequestUserCredentials)));
    // Customizable Area Start
    this.fetchVideoMedia();
    let messageShow =  sessionStorage.getItem("login_success_message")
    const rememberAccount = localStorage.getItem("rememberAccount")
    if(rememberAccount){
      const accountInfo = JSON.parse(rememberAccount)
      this.formikRef.current?.setFieldValue('email', accountInfo.email)
      this.formikRef.current?.setFieldValue('password', accountInfo.password)
      this.formikRef.current?.setFormikState(state => ({
        ...state,
        dirty: true,
      }));
      this.setState({
        checkedRememberMe: true
      })
    }
    if(messageShow){
      this.showModalSuccess(messageShow)
      sessionStorage.removeItem("login_success_message")
    }
    // Customizable Area End
  }

  // Customizable Area Start

  // Web Event Handling
  handleClickShowPassword = () => {
    this.setState({
      enablePasswordField: !this.state.enablePasswordField,
    });
  };


  setRememberMe = () => {
    this.setState({ checkedRememberMe: !this.state.checkedRememberMe });
  };
  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
      this.handleGetSavedInfo(message)
      if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
        this.handleResponseAPIMessage(message)
    }
    // Customizable Area End
  }
  // Customizable Area Start
  handleResponseAPIMessage = (message: Message) => {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    const errorReponse = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );
    if(errorReponse){
      this.setState({loading: false})
      this.parseApiCatchErrorResponse(errorReponse);
      return
    }
    if (apiRequestCallId === this.apiEmailLoginCallId) {
      this.setState({loading: false})
      if(responseJson.errors){
        responseJson.errors.map((error: FormikErrors<{ email: string; password: string; }>) => {
          const currentFormikRef = this.formikRef.current!;
          currentFormikRef.setErrors(error);
        })
        return
      }
      if(responseJson && responseJson.data.attributes.hospice_name === null){
        sessionStorage.setItem("register_step_token", responseJson.meta.token)
        this.props.navigation.navigate("EmailAccountRegistration")
        return
      }
      if (responseJson && responseJson.meta ) {
        this.saveLoggedInUserData(responseJson);
        this.sendLoginSuccessMessage();
        localStorage.setItem("current_role", 'hospice')
        this.handleCheckSubscription(responseJson.meta.token)
      }
    }
    if (apiRequestCallId === this.apiCheckSubscriptionCallId) {
      this.setState({loading: false})
      if (responseJson) {
       this.handleStatus(responseJson.status)
      }
    }
    if (apiRequestCallId === this.fetchingVideoApiCallId) {
      this.handleVideoData(responseJson);
    }
    this.handlePhoneLoginFlow(message);
  }
  handleVideoData = (responseJson: any) => {
    if (responseJson) {
      const data = responseJson.data;
      const attributes = (data && data.length > 0 && data[0]?.attributes) || {};
      this.setState({ videoUrl: attributes.video_file || '' })
    }
  }
  handleStatus = (status: string) => {
    if(status === "paid" || status === "trial"){
      localStorage.setItem("user_status", 'paid')
      this.openDashboardPage();
    }else if(status === "no subscriptions" || status === "no paid"){
      this.props.navigation.replace("Subscriptionbilling2")
    }
    else{
      localStorage.setItem("main_flow", "expired")
      this.props.navigation.replace("SubscriptionExpired")
    }
  }
  handlePhoneLoginFlow = (message: Message) => {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (apiRequestCallId === this.apiPhoneLoginCallId) {
      this.handleLoginCaregiverResponse(message)
  }
    if (apiRequestCallId === this.apiCheckCaregiverSubscriptionCallId) {
      this.setState({loading: false})
      if (responseJson) {
        if(responseJson.status === "paid" || responseJson.status === "trial"){
          localStorage.setItem("user_status", 'paid')
          this.openCatalogueScreen();
        }else{
          this.setState({
            phoneNumberError: configJSON.phoneNumberError
          })
        }
      }
  }
  }
  handleLoginCaregiverResponse = (message: Message) => {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    console.log('responseJson',responseJson)
    this.setState({loading: false})
    if (responseJson && responseJson.data) {
      localStorage.setItem("caregiver_data", JSON.stringify(responseJson.data))
      localStorage.setItem("token", responseJson.data.attributes.session_token)
      localStorage.setItem("current_role", 'caregiver')
      this.handleCheckCaregiverSubscription(responseJson.data.attributes.session_token)
    } else {
      const errorMessage = JSON.stringify(responseJson)
      if(errorMessage.includes('Cannot add more than three devices')){
        this.setState({isOpenModalReachLimit: true, numberOfHospice: responseJson.message.hospice_phone_number[0]})
        return
      }
      this.setState({
        phoneNumberError: configJSON.phoneNumberError
      })
    }
  }
  openCatalogueScreen = () => {
    this.props.navigation.replace("Catalogue")
  }
  handleGetSavedInfo = (message: Message) => {
    if (getName(MessageEnum.ReciveUserCredentials) === message.id) {
      const userName = message.getData(getName(MessageEnum.LoginUserName));

      const password = message.getData(getName(MessageEnum.LoginPassword));

      if (userName && password) {
        this.setState({
          email: userName,
          password: password,
          checkedRememberMe: true,
        });
      }
    }
  }
  sendLoginFailMessage() {
    const messageLogin: Message = new Message(getName(MessageEnum.LoginFaliureMessage));
    this.send(messageLogin);
  }

  sendLoginSuccessMessage() {
    const messageLogin: Message = new Message(getName(MessageEnum.LoginSuccessMessage));

    messageLogin.addData(getName(MessageEnum.LoginUserName), this.state.email);
    messageLogin.addData(getName(MessageEnum.LoginPassword), this.state.password);
    messageLogin.addData(
      getName(MessageEnum.LoginIsRememberMe),
      this.state.checkedRememberMe
    );

    this.send(messageLogin);
  }

  saveLoggedInUserData = (responseJson: ResponseJson) => {
    if (responseJson && responseJson.meta && responseJson.meta.token) {
      const messageLogin: Message = new Message(getName(MessageEnum.SessionSaveMessage));
      localStorage.setItem("user_data", JSON.stringify(responseJson))
      localStorage.setItem("token", responseJson.meta.token)
      localStorage.setItem("current_role", 'hospice')
      messageLogin.addData(
        getName(MessageEnum.AuthTokenEmailMessage),
        JSON.stringify(responseJson)
      );
      messageLogin.addData(
        getName(MessageEnum.AuthTokenDataMessage),
        responseJson.meta.token
      );

      this.send(messageLogin);
    }
  }

  openDashboardPage() {
    // Merge Engine - Navigation - btnEmailLogIn - Start
    this.props.navigation.replace("Dashboard")
    // Merge Engine - Navigation - btnEmailLogIn - End
  }

  goToSignUp = () => {
    const messageNavigate: Message = new Message(getName(MessageEnum.NavigateEmailSignUpMessage));
    messageNavigate.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(messageNavigate);
  }

  goToForgotPassword = () => {
    // Merge Engine - Navigation - btnForgotPassword - Start
    const messageNavigate: Message = new Message(
      getName(MessageEnum.NavigationForgotPasswordMessage)
    );
    messageNavigate.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    messageNavigate.addData(getName(MessageEnum.NavigationForgotPasswordPageInfo), "email");
    this.send(messageNavigate);
    // Merge Engine - Navigation - btnForgotPassword - End
  }

  handleLogin = (email: string, password: string) => {
    this.setState({loading: true})
    const header = {
      "Content-Type": configJSON.loginApiContentType,
    };
    const userData = {email, password}
    if(this.state.checkedRememberMe){
      localStorage.setItem("rememberAccount", JSON.stringify(userData))
    }else{
      localStorage.removeItem("rememberAccount")
    }
    const postBody = {
      type: "email_account",
      attributes:userData,
    };

    const httpBody = {
      data: postBody,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiEmailLoginCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.newLoginAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.loginAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  goToPrivacyPolicy = () => {
    const messagePP: Message = new Message(
      getName(MessageEnum.NavigationTermAndConditionMessage)
    );
    messagePP.addData(getName(MessageEnum.TermsAndConditionsTypeMessage), "Privacy Policy");
    messagePP.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(messagePP);
  }

  goToTermsAndCondition = () => {
    const messageTAC: Message = new Message(
      getName(MessageEnum.NavigationTermAndConditionMessage)
    );
    messageTAC.addData(getName(MessageEnum.TermsAndConditionsTypeMessage), "Terms and Conditions");
    messageTAC.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(messageTAC);
  }
  callGetValidationApi() {
    this.setState({email: ""})
  }
  changeToCaregiverLogin = () => {
    this.setState({isCaregiverLogin: true})
  }
  changeToHospiceLogin = () => {
    this.setState({isCaregiverLogin: false})
  }
  handleCaregiverLogin = () => {
    if(!(/^\d{10}$/.test(this.state.phoneNumber))){
      this.setState({
        phoneNumberError: "Invalid number"
      })
      return
    }
    this.setState({loading: true})
    const header = {
      "Content-Type": configJSON.loginApiContentType,
    };

    const httpBody = {
      phone_number: this.state.phoneNumber,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiPhoneLoginCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.newCaregiverLoginAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.loginAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  handleCheckSubscription = (token: string) => {
    this.setState({loading: true})
    const header = {
      "Content-Type": configJSON.loginApiContentType,
      token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiCheckSubscriptionCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.checkSubscriptionAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  handleCheckCaregiverSubscription = (token: string) => {
    this.setState({loading: true})
    const header = {
      "Content-Type": configJSON.loginApiContentType,
      token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiCheckCaregiverSubscriptionCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.checkCaregiverSubscriptionAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  fetchVideoMedia = () => {
    const header = { "Content-Type": configJSON.exampleApiContentType };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.fetchingVideoApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    this.fetchingVideoApiCallId = requestMessage.messageId;
  
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  
  handleOpenInformationModal = () => {
    this.setState({
      isOpenModalInformation: true
    })
  }
  handleCloseInformationModal = () => {
    this.setState({
      isOpenModalInformation: false
    })
  }
  handleCloseReachLimitModal = () => {
    this.setState({
      isOpenModalReachLimit: false
    })
  }
  showModalError = (message: string) => {
    this.setState({
      errorMessage: message
    })
    setTimeout(() => {
      this.setState({
        errorMessage: null
      })
    }, 3000);
  }
  showModalSuccess = (message: string) => {
    this.setState({
      successMessage: message
    })
    setTimeout(() => {
      this.setState({
        successMessage: null
      })
    }, 3000);
  }
  // Customizable Area End
}
