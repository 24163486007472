import React, { ReactNode, useEffect, useState } from "react";
import { Box, Button, Collapse, InputBase, Stack } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { styled } from "@mui/system";
import parse, { domToReact, Element, HTMLReactParserOptions } from "html-react-parser";

import OverviewSection from "./OverviewSection.web";
import PlainTextItem from "./PlanTextItem.web";
import { OverviewAnnotation } from "./MethodOverviewSection.web";
import { CustomSectionContainer, SectionListLayout } from "./CustomContainer.web";

import { IContentData, ITermDetail } from "../../blocks/contentmanagement/src/ContentManagementController";

const webStyles = {
  customInput: {
    '& input': { 
      p: 0,
      fontFamily: 'Lato',
      color: '#343C32',
      lineHeight: '28px',
      '::placeholder': {
        fontFamily: 'Lato',
        color: '#96A591'
      } 
    }
  },
  verticalBorderedBox: {
    borderTop: '1px solid rgba(124, 142, 118, 0.2)',
    borderBottom: '1px solid rgba(124, 142, 118, 0.2)',
  },
}

const parsingOptions: HTMLReactParserOptions = {
  trim: true,
  replace(domNode) {
      if (!(domNode instanceof Element)) return;
      const replacementByName: { [key: string]: JSX.Element } = {
        "ul": <Stack direction="column" sx={{ gap: 1.5 }}>{domToReact(domNode.children, parsingOptions)}</Stack>,
        "li": <PlainTextItem>{domToReact(domNode.children)}</PlainTextItem>,
      };
      if (!replacementByName[domNode.name]) return;''
      return replacementByName[domNode.name];
  },
}

const SearchBox = styled(Box)({
  border: '1px solid #C3CBC0',
  borderRadius: '100px',
  padding: '10px 8px',
  backgroundColor: '#FAF9F6',
  display: 'flex',
  gap: 8,
  justifyContent: 'center',
  alignItems: 'center'
})
const AccordionSummary = styled(Button)({
  width: '100%',
  borderRadius: 0,
  display: 'block',
  fontFamily: 'Lato',
  fontSize: '20px',
  fontWeight: '700',
  lineHeight: '24px',
  textAlign: 'left',
  textTransform: 'none',
  color: '#586554',
  '& p': { margin: 0 }
})

const CustomAnnotation = ({ heading, content }: { heading: ReactNode; content: ReactNode }) => (
  <OverviewAnnotation 
    title={heading} 
    content={content} 
    sx={{ flex: 'none' }}
    titleStyle={{ mb: 2.5 }}
  />
)

const Accordion = ({ heading, content }: { heading: ReactNode; content: ReactNode }) => {
  const [isCollapse, setIsCollapse] = useState(true);

  return (
    <Box sx={[!isCollapse && { pb: '10px' }, webStyles.verticalBorderedBox]}>
      <Box sx={{ p: !isCollapse ? '10px 20px' : '20px' }}onClick={() => setIsCollapse(!isCollapse)} component={AccordionSummary}>
        <Stack direction="row" sx={{ gap: '10px', alignItems: 'center' }}>
          {isCollapse && <ExpandMoreIcon />}
          {heading}
        </Stack>
      </Box>
      <Collapse in={!isCollapse} timeout="auto" unmountOnExit>
        <Box sx={{ p: '0 20px' }}>{content}</Box>
      </Collapse>
    </Box>
  )
}

const UrinaryMedsList = ({ data }: { data: IContentData }) => {
  const { attributes } = data;
  const { title, terms } = attributes;

  const [keyword, setKeyword] = useState('');
  const [filteredList, setFilteredList] = useState(terms);

  useEffect(() => {
    const filteredMeds = filterItems(terms, keyword);
    setFilteredList(filteredMeds);
  }, [keyword]);

  const filterItems = (items: ITermDetail[], search: string, { filterOnListHeading }: { filterOnListHeading: boolean } = { filterOnListHeading: true } ) => {
    return items
      .filter(item => {
        const listHasMatchingItem = item.definition.toLowerCase().includes(search.toLowerCase());
        return filterOnListHeading
          ? item.term.toLowerCase().includes(search.toLowerCase()) || listHasMatchingItem
          : listHasMatchingItem
      })
  }

  return (
    <SectionListLayout styles={{ gap: { xs: 0, md: 2.5 } }}>
      <OverviewSection 
        images={title?.images.map(image => image.url) || []} 
        name={title?.title && parse(title?.title)} 
        shortDesc={title?.desc && title?.desc !== '' && parse(title?.desc)}
        containerStyle={{ bgcolor: 'transparent', px: { xs: 0, md: '150px' } }}
      />
      <CustomSectionContainer 
        variant="light" 
        containerStyle={{ p: { xs: 0, md: '0 130px' } }}
        contentStyle={{ width: '100%' }}
      >
        <SearchBox sx={{ mb: '14px' }}>
          <SearchIcon sx={{ color: '#96A591' }} />
          <InputBase
            sx={{ flex: 1, ...webStyles.customInput }}
            placeholder="Search for medication"
            inputProps={{ 'aria-label': 'search for medication' }}
            type="search"
            onChange={event => setKeyword(event.target.value)}
          />
        </SearchBox>
        <Box sx={{ display: { xs: 'none', md: 'block' } }}>
          <Stack direction="row" sx={{ gap: '34px', alignItems: 'space-between' }}>
            <Stack direction="column" sx={{ flex: 1, gap: 5 }}>
              {filteredList.slice(0, Math.round(filteredList.length / 2)).map((term) => (
                <CustomAnnotation 
                  key={term.id} 
                  heading={parse(term.term)} 
                  content={parse(term.definition, parsingOptions)} 
                />
              ))}
            </Stack>
            <Stack direction="column" sx={{ flex: 1, gap: 5 }}>
              {filteredList.slice(Math.round(filteredList.length / 2)).map((term) => (
                <CustomAnnotation 
                  key={term.id} 
                  heading={parse(term.term)} 
                  content={parse(term.definition, parsingOptions)} 
                />
              ))}
            </Stack>
          </Stack>
        </Box>
        <Box sx={{ display: { xs: 'block', md: 'none' } }}>
          <Stack direction="column">
            {filteredList.map((term) => (
              <Accordion 
                key={term.id} 
                heading={parse(term.term)} 
                content={parse(term.definition, parsingOptions)} 
              />
            ))}
          </Stack>
        </Box>
      </CustomSectionContainer>
    </SectionListLayout>
  );
}

export default UrinaryMedsList;
