import React, { Children, isValidElement, useEffect, useState } from "react";
import { Box, Link, Typography } from "@mui/material";
import { styled } from "@mui/system";
import parse, { domToReact, Element, HTMLReactParserOptions } from 'html-react-parser';

import OverviewSection from "./OverviewSection.web";
import PlainTextItem from "./PlanTextItem.web";
import { OrderedListItem } from "./ItemWithLetter.web";
import { CustomSectionContainer, SectionListLayout } from "./CustomContainer.web";

import { IContentData, ISectionData, ITermDetail } from "../../blocks/contentmanagement/src/ContentManagementController";

const webStyles = {
  secrtionWithImageContainer: {
    px: { xs: 0 }, 
    pt: 2.5, 
    pb: 0
  },
  plainTextWithLinkContainer: {
    flex: 1, 
    display: 'flex', 
    alignItems: 'center', 
    justifyContent: 'center', 
    fontWeight: '700'
  },
  transparentOrderedListItemContainer: {
    flexWrap: 'wrap',
    alignItems: 'flex-start', 
    gap: '22px',
  },
} as const;

const FlexCol = styled(Box)({
  display: 'flex',
  flexDirection: 'column'
})
const ListItemContainer = styled(Box)({
  p: '0 20px 20px'
})
const SubHeading = styled(Typography)({
  fontWeight: '700',
  fontFamily: 'Lato',
  color: '#7C8E76',
  textAlign: 'center',
})
const CustomLink = styled(Link)({
  fontFamily: 'Lato',
  fontWeight: '700',
  color: 'inherit',
  textDecorationColor: 'inherit'
})
const NormalText = styled(Box)({
  '& p': {
    m: 0
  }
})
const DescImg = styled('img')({
  width: '100%',
  height: 'auto',
  objectFit: 'cover',
  objectPosition: 'center'
})

const Pain = ({ data }: { data: IContentData }) => {
  const { attributes } = data;
  const { title, terms } = attributes;

  const parsingOptions: HTMLReactParserOptions = {
      trim: true,   
      replace(domNode) {
        if (domNode instanceof Element && domNode.name === 'ol') {
          return (
            <>
              {domNode.children
                .filter(li => li instanceof Element && li.name === 'li')
                .map((each, index) => each instanceof Element && each.name === 'li' && (React.Children.toArray(domToReact(each.children)).some(child => {
                  return React.isValidElement(child) && child.type === 'a';
              }) ? (
                  <OrderedListItem 
                    key={index} 
                    order={(index + 1).toString()}
                    withBg
                    bgColor={index === 0 ? '#AFE551' : (index === 1 ? '#F9E100' : '#EE2F25')}
                    styles={{ alignItems: 'flex-start', flexWrap: 'wrap' }}
                  >
                    <ListItemContainer sx={{ flex: { xs: 'auto', md: 1 }, flexBasis: { xs: '100%', md: 0 } }}>
                      {renderOrderedListItem(each, true)}
                    </ListItemContainer>
                  </OrderedListItem>
                ) : (
                  <OrderedListItem key={index} order={(index + 1).toString()} styles={webStyles.transparentOrderedListItemContainer}>
                    <ListItemContainer sx={{ flex: { xs: 'auto', md: 1 }, flexBasis: { xs: '100%', md: 0 } }}>
                      {renderOrderedListItem(each)}
                    </ListItemContainer>
                  </OrderedListItem>
                )))}
            </>
          )
        }
        if (domNode instanceof Element && domNode.name === 'li') {
          return (
            <PlainTextItem>{domToReact(domNode.children, parsingOptions)}</PlainTextItem>
          )
        }
        if (domNode instanceof Element && domNode.name === 'ul') {
          return (
            <>{domToReact(domNode.children, parsingOptions)}</>
          )
        }
        if (domNode instanceof Element && domNode.name === 'a') {
          return (
            <CustomLink href={domNode.attribs['href']} target="_blank">{domToReact(domNode.children)}</CustomLink>
          )
        }
      },
  }

  const [sectionList, setSectionList] = useState<ISectionData[]>([]);

  useEffect(() => {
      if (terms) {
      terms.sort((a, b) => a.id - b.id); 
      const groupedData: { [key: string]: ITermDetail[] } = {};
      terms.forEach(term => {
          const key = term.term;
          if (!groupedData[key]) {
          groupedData[key] = [];
          }
          groupedData[key].push(term);
      });
      const sections = Object.entries(groupedData).map(group => {
          if (group[1].length > 1) {
            return {
              name: group[0],
              definition: '',
              images: [],
              children: group[1].map(each => {
              return { definition: each.definition, images: each.images }
              })
            }
          }
          return {
            id: group[1][0].id,
            name: group[0],
            definition: group[1][0].definition,
            images: group[1][0].images,
          }
      })
      setSectionList(sections)
      }
  }, [terms]);

  const renderOrderedListItem = (node: Element, shrink?: boolean) => {
    if (node.children.some(child => child instanceof Element && child.name === 'ul')) {
      return (
        <FlexCol sx={{ gap: !shrink ? 2.5 : 1.5 }}>
          {node.children.map((childNode, idx) => {
            if (childNode instanceof Element) {
              if (childNode.name == 'div') {
                return (
                  <>
                    <PlainTextItem key={idx} style={{ display: { xs: 'none', md: 'flex' } }}>{domToReact(childNode.children, parsingOptions)}</PlainTextItem>
                    <PlainTextItem key={idx} style={{ display: { xs: 'flex', md: 'none' } }} withoutIcon>
                      {domToReact(childNode.children, parsingOptions)}
                    </PlainTextItem>
                  </>
                ) 
              }
              if (childNode.name == 'a') {
                if (childNode.attribs['href'] !== '#') {
                  return (
                    <PlainTextItem 
                      key={idx} 
                      textStyle={webStyles.plainTextWithLinkContainer}
                    >
                      <NormalText sx={{ flex: 1, fontSize: { xs: '20px', md: '26px' } }}>{domToReact(childNode.children)}</NormalText>
                      <CustomLink sx={{ display: { xs: 'none', md: 'block' } }} href={childNode.attribs['href']} target="_blank">Click Here</CustomLink>
                    </PlainTextItem>
                  ) 
                }
                return (
                  <PlainTextItem key={idx} textStyle={{ fontWeight: '700', fontSize: { xs: '20px', md: '26px' } }}>{domToReact(childNode.children)}</PlainTextItem>
                ) 
              }
              return (
                <FlexCol key={idx} sx={{ px: { xs: 0, md: '60px' }, gap: !shrink ? 2.5 : 1.5 }}>{domToReact(childNode.children, parsingOptions)}</FlexCol>
              )
            }
          })}
          {node.children.map((childNode, idx) => childNode instanceof Element && childNode.name == 'a' && childNode.attribs['href'] !== '#'
            && (<CustomLink 
              key={idx} 
              sx={{ display: { xs: 'block', md: 'none' }, fontSize: { xs: '20px' } }} 
              href={childNode.attribs['href']} 
              target="_blank">Click Here
            </CustomLink>))}
        </FlexCol>
      )
    }
    return (
      <>
        <PlainTextItem style={{ display: { xs: 'none', md: 'flex' } }}>{domToReact(node.children)}</PlainTextItem>
        <PlainTextItem style={{ display: { xs: 'flex', md: 'none' } }} withoutIcon>
          {domToReact(node.children)}
        </PlainTextItem>
      </>
    )
  }

  const checkTagExisted = (htmlString: string, tagName: string) => {
    const parsedElements = parse(htmlString);
    return Children.toArray(parsedElements).some(child => {
        return isValidElement(child) && child.type === tagName;
    });
  }

  return (
    <>
      {/* <ContentBreadcrumbs stacks='Dashboard/Symptoms/Pain' onNavigate={onNavigate} /> */}
      <SectionListLayout>
          <OverviewSection 
            images={title?.images.map(image => image.url) || []} 
            name={parse(title?.title)} 
            shortDesc={title?.desc && title?.desc !== '' && parse(title?.desc)}
            containerStyle={{ px: { xs: 1.25, md: '140px' }, py: { xs: 3 } }} 
          />
          <CustomSectionContainer variant="light" containerStyle={{ px: { xs: 2, md: '130px' }, py: 0 }}>
            {sectionList.map((section, index) => {
              if (section.id && section.images.length === 0 && checkTagExisted(section.definition, 'ol')) {
                return (
                  <CustomSectionContainer 
                    key={index} 
                    variant="light"
                    withHeading={section.name !== ''}
                    heading={parse(section.name)}
                    containerStyle={{ px: { xs: 0 }, py: 0 }}
                    contentStyle={{ width: '100%' }}
                  >
                    {parse(section.definition, parsingOptions)}
                  </CustomSectionContainer>
                )
              }
              if (section.id && section.images.length > 0) {
                return (
                  <CustomSectionContainer 
                    key={index} 
                    variant="light" 
                    withHeading
                    heading={parse(section.name)}
                    containerStyle={webStyles.secrtionWithImageContainer} 
                    contentStyle={{ gap: '26px' }}
                  >
                    {parse(section.definition, {
                      replace(domNode) {
                        if (domNode instanceof Element && domNode.name === 'p') {
                          return (
                            <SubHeading sx={{ fontSize: { xs: '16px', md: '26px' }, lineHeight: { xs: '26px', md: '32px' } }}>
                              {domToReact(domNode.children)}
                            </SubHeading>
                          )
                        }
                      },
                    })}
                    <DescImg src={section.images[0].url || ''} alt="Pain Scale Image" />
                  </CustomSectionContainer>
                )
              }
              return (
                <CustomSectionContainer 
                    key={index} 
                    withHeading
                    heading={parse(section.name)}
                    containerStyle={{ px: { xs: 2.5, md: 5 }, py: 2.5 }} 
                    contentStyle={[{ mt: 1.5, gap: 1.5 }]} 
                >
                  {parse(section.definition, parsingOptions)}
                </CustomSectionContainer>
              )
            })}
          </CustomSectionContainer>
      </SectionListLayout>
    </>
  );
}

export default Pain;
