// Customizable Area Start
import React, { useEffect, useState } from 'react';
import parse, { domToReact, Element, HTMLReactParserOptions } from 'html-react-parser';

import OverviewSection from './OverviewSection.web';
import PlainTextItem from './PlanTextItem.web';
import { OrderedListItem } from './ItemWithLetter.web';
import { OverviewAnnotation } from './MethodOverviewSection.web';
import { CustomSectionContainer, SectionListLayout } from './CustomContainer.web';
import { ISectionData } from './SymptomARD.web';

import { IContentData, ITermDetail } from "../../blocks/contentmanagement/src/ContentManagementController";

export default function StopMedication({ data }: { data: IContentData }) {
  const { attributes } = data;
  const { title, terms } = attributes;

  const options: HTMLReactParserOptions = {
    trim: true,
    replace(domNode) {
      if (!(domNode instanceof Element)) return;
      const replacementByName: { [key: string]: JSX.Element } = {
        "ul": <>{domToReact(domNode.children, options)}</>,
        "li": <PlainTextItem>{domToReact(domNode.children, options)}</PlainTextItem>,
      };

      if (!replacementByName[domNode.name]) return;
  
      return replacementByName[domNode.name];
    }
  }

  const [sectionList, setSectionList] = useState<ISectionData[]>([]);

  useEffect(() => {
    if (terms) {
      terms.sort((a, b) => a.id - b.id); 
      const groupedData: { [key: string]: ITermDetail[] } = {};
      terms.forEach(term => {
        const key = term.term;
        if (!groupedData[key]) {
          groupedData[key] = [];
        }
        groupedData[key].push(term);
      });
      const sections = Object.entries(groupedData).map(group => {
        if (group[1].length > 1) {
          return {
            name: group[0],
            definition: '',
            images: [],
            children: group[1].map(each => {
              return { definition: each.definition, images: each.images }
            })
          }
        }
        return {
          id: group[1][0].id,
          name: group[0],
          definition: group[1][0].definition,
          images: group[1][0].images,
        }
      })
      setSectionList(sections)
    }
  }, [terms]); 

  return (
    <SectionListLayout styles={{ gap: 1.25, backgroundColor: '#F2F4F1', borderRadius: '8px' }}>
      <OverviewSection 
        images={title?.images.map(image => image.url) || []} 
        name={parse(title?.title)} 
        shortDesc={title?.desc && title?.desc !== '' && parse(title?.desc)}
        containerStyle={{ py: { xs: 2.5, md: 5 } }}
      />
      <CustomSectionContainer containerStyle={{ pt: 0 }} contentStyle={{ gap: { xs: 2.5, md: 5 } }}>
        {sectionList.map((section, index) => (
          <OrderedListItem 
            key={index} 
            order={(index + 1).toString()} 
            styles={{ alignItems: 'flex-start' }}
          >
            <OverviewAnnotation 
              title={parse(section.name)} 
              content={parse(section.definition, options)} 
              sx={{ paddingX: { xs: 0, md: 2.5 } }}
              layoutStyle={{ mt: '-12px' }}
            />
          </OrderedListItem>
        ))}
      </CustomSectionContainer>
    </SectionListLayout>
  )
}

// Customizable Area End


