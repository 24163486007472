export const logoImg = require("../assets/logo.png");
export const bannerImg = require("../assets/banner.jpg");
export const mobileBannerImg = require("../assets/mobile-banner.jpg");
export const adPosterImg = require("../assets/video-poster.jpg");
export const illustrationImg = require("../assets/illustration.png");
export const illustration1Img = require("../assets/illustration.svg");
export const ownerAvatarImg = require("../assets/owner-avatar.jpg");

export const mailIcon = require("../assets/mail-icon.svg");
export const mailWhiteIcon = require("../assets/white-mail-icon.svg");
export const phoneIcon = require("../assets/phone-icon.svg");
export const locationIcon = require("../assets/location-icon.svg");
export const homeIcon = require("../assets/home-icon.svg");
export const caregiverIcon = require("../assets/caregiver-icon.svg");
export const primaryPhoneIcon = require("../assets/phone-primary-icon.svg");
export const loginIcon = require("../assets/login-icon.svg");
export const burgerIcon = require("../assets/burger-icon.svg");